<template>
  <div id="app">
    <main-nav
      v-if="$route.meta.mainHeader !== false"
      :class="{
        'main-nav--alt': !showSticky && useStickyNav
      }"
    />
    <router-view :key="$route.fullPath"/>
    <main-footer v-if="$route.meta.mainFooter !== false" />
    <main-footer-2 v-if="$route.meta.mainFooter2 !== false" />
    <main-footer-promo v-if="$route.meta.mainFooterPromo !== false" />
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import MainNav from "./components/MainNav.vue"
import EventBus from "@/helpers/EventBus"
export default {
  components: {
    MainFooter: () => import("./components/MainFooter.vue"),
    MainFooter2: () => import("./components/MainFooter2.vue"),
    MainFooterPromo: () => import("./components/MainFooterPromo.vue"),
    MainNav
  },
  data() {
    return {
      showSticky: false,
      imoveisjson: []
    }
  },
  computed: {
    useStickyNav() {
      return !this.$route.meta.navSolid
    }
  },
  methods: {
    handleStickyMenu(value) {
      this.showSticky = value
    }
  },
  created() {
    if (this.useStickyNav) {
      EventBus.$on("header-off-screen", value => this.handleStickyMenu(value))
    }
  },
  destroyed() {
    if (this.useStickyNav) {
      EventBus.$off("header-off-screen", value => this.handleStickyMenu(value))
    }
  }
}
</script>

<style lang="postcss">
body {
  button,
  input,
  textarea,
  select {
    &:focus {
      @apply bg-gray-100;
      outline: 0;
    }
  }
}

.title {
  @apply text-3xl text-center mb-12;

  @media (max-width: 768px) {
    @apply text-xl;
  }
}

#app {
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;

  .group {
    > div + *,
    > button + * {
      @apply ml-4;
    }
  }
  .v-group {
    > div + *,
    > button + * {
      @apply mt-4;
    }
  }
  ::placeholder {
    @apply text-gray-500;
  }

  b,
  strong {
    font-weight: 500;
  }

  .c-link {
    @apply text-primary-500;

    &:hover {
      @apply text-primary-400 underline;
    }

    &--secondary {
      @apply text-gray-600;

      &:hover {
        @apply text-gray-800 underline;
      }
    }
  }

  .section {
    @apply py-5;
  }

  p.subtitle {
    @apply text-center;
  }

  .rounded-icon {
    @apply rounded-full text-primary-500 bg-primary-100 w-12 h-12 inline-flex items-center justify-center;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 150ms;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-slide-enter-active,
  .fade-slide-leave-active {
    transition: opacity 150ms, transform 150ms;
  }

  .fade-slide-leave-to {
    opacity: 0;
    transform: translateX(100px);
  }

  .fade-slide-enter {
    opacity: 0;
    transform: translateX(-100px);
  }

  .VueCarousel {
    &-navigation {
      &-button {
        @apply rounded-full shadow bg-primary-500 text-gray-100 w-10 h-10 flex items-center justify-center;
        padding: 0 !important;
        transition: opacity 100ms ease, color 100ms ease, background 100ms ease;
        outline: 0;

        @media (max-width: 480px) {
          @apply block;
        }

        &:not(.VueCarousel-navigation--disabled):hover {
          @apply text-white bg-primary-400;
        }

        svg {
          width: 10px;
          display: inline-block;
        }
      }

       &--disabled {
        opacity: 0;
      }

      &-prev {
        left: 4rem;
      }

      &-next {
        right: 4rem;
      }
    }
  }

  .no-scrollbar {
    @media only screen and (min-device-width: 768px) {
      &::-webkit-scrollbar {
        display: none;
    }
   }
  }
}
</style>
