<template>
  <div class="property-grid" :key="componentKey">
    <span class="text-center" v-if="permuta === true" style="position: absolute; z-index: 10;">
        <img :src="require(`@/assets/img/Selo-permuta-site.png`)" alt="" width="250">
    </span>
    <!-- <Gallery :images="images"></Gallery> -->
    <photo-collage-wrapper
      :key="ativa"
      gapSize="3px"
      @itemClick="itemClickHandler"
      v-bind="collage"
    >
    <!-- // @click="showImage = index"  -->
    </photo-collage-wrapper>
    <CoolLightBox
      :items="galeria"
      :index="showImage"
      :loop="true"
      @close="showImage = null">
    </CoolLightBox>

    <!-- {{ this.imagens }} -->
    <div class="hidden contador-galeria c-button primary md:block pulse" style="cursor:pointer !important;" @click="showImage = 0">
      <!-- <label>{{ index + 1 }} / {{ slides.length }}</label> -->
      <label><font-awesome-icon icon="search" /> Ampliar</label>
    </div>
    <c-button
        v-if="propertyVideos"
        icon="video"
        class="px-0 c-button md:w-auto md:px-6"
        @click="localvideo('videos')"
        style="position: absolute !important;left: 1%;top:13%;padding: 10px 10px; z-index: 1000;"
      >
      <span class="ml-2">Confira os Vídeos</span>
    </c-button>
  </div>
</template>

<script>
// import Gallery from "vue-cover-gallery"
// import "bootstrap"
// // import "bootstrap/dist/css/bootstrap.min.css"
// import { timingSafeEqual } from "crypto"
import { PhotoCollageWrapper } from "vue-photo-collage"
import CButton from "../components/UI/CButton.vue"
// import { ref } from "vue"
// const componentKey = ref(0)
// const forceRerender = () => {
//   componentKey.value += 1
// }
// const componentKey = ref(0)
// const forceRerender = () => {
//   componentKey.value += 1
// }

export default {
  components: {
    PhotoCollageWrapper,
    CButton
  },
  props: {
    imagens: {
      type: Array,
      default() {
        return []
      }
    },
    etapa: {
      type: Number,
      default: 0
    },
    propertyVideos: {
      type: Boolean,
      default: false
    },
    permuta: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showImage: false,
      galeria: [],
      componentKey: 0,
      ativa: this.etapa,
      collage: {
        width: "100%",
        height: ["330px", "220px"],
        layout: [3, 5],
        photos: this.imagens,
        showNumOfRemainingPhotos: true
      }
    }
  },
  created() {
    // this.collage.photos = this.computedClass
    // this.collage.photos = this.imagens
    for (var k in this.imagens) {
      // console.log(this.imagens[k].link + ", ")
      // this.propertyPhotos.push(this.imagens[k].link)
      this.galeria.push({
        title: "Fotos " + this.imagens[k].title,
        description: "Photo by @CafeNegociosImobiliarios",
        src: this.imagens[k].source
      })
      // if (k === "0") {
      //   this.primeiraimagem = this.imagens[k].link_thumb
      // }
      // this.$forceUpdate()
      if (this.isMobile) {
        this.collage.layout = [1, 2]
      }
    }
  },
  methods: {
    itemClickHandler (data, i) {
      // console.log("data: ")
      // console.log(data)
      // console.log("i: " + i)
      this.showImage = data.id
    },
    localvideo(ativa) {
      this.$parent.handlePropertyView(ativa)
      // console.log("OI")
    }
  }
}
</script>

<!-- <style>
  @import 'bootstrap/dist/css/bootstrap.min.css';
</style> -->
<style lang="postcss" scoped>
.property {
  &__slide {
    @apply flex justify-center items-center;
    height: 500px;
    /* max-width: fit-content; */
    width: 100%;
    & + & {
      @apply ml-1;
    }
    background-color: lightgray !important;
    border-left:1px solid white ;
    border-right:1px solid white ;
    /* background-color: #000 !important; */
      /* border-left: 25px solid #fff; */
    /* .contemimagem {
      background-color: #000;
      width: 100%;

    } */
    box-sizing: border-box;
    img {
      @apply object-cover;
      /* width: fit-content; */
      /* max-height: 100%; */
      width: 100%;
      height: 500px;
      /* height: 340px;
      max-height: 340px;
      min-height: 340px; */
      object-position: center;
      /* object-fit: contain; */
      /* padding: 1%; */
      /* height: 340px;
      min-height: 340px;
      height: 140; */
      /* width: auto;
      height: auto; */
    }
    iframe {
      @apply object-cover;
      width: 100% !important;
      position: absolute;
      max-height: 100%;
      left: 0px;
      background-color: #000;
      height: 500px;
    }
  }
  .property__slide + .property__slide {
    margin-left: 0 !important;
  }
  .toolbar-btn__rotate {
    display: none;
  }
  .btn__prev, .btn__next, .btn__close {
    background-color:#4062d1 !important;
    border-color: #4062d1;
    color: #fff;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    white-space: nowrap;
    border-radius: 20px;
  }
}
.contador-galeria {
  z-index: 10 !important;
  /* background-color: white !important; #4062d1 !important; */
  /* color: gray !important; */
  font-size: 12px;
  position: absolute !important;
  margin-top: -43px;
  /* margin-left: 0px; */
  left: 46%;
  object-position: center;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 12px;
  width: auto;
  /* text-align: center; */
  /* text-weigth: bolder; */
  cursor: ne-resize !important;
  width: 100px;
    /* text-shadow: 2px 0 0 #000, -2px 0 0 #000fff, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000; */
}
.contador-galeria label {
  cursor: ne-resize !important;
}
.indice-galeria {
  z-index: 10 !important;
  position: absolute !important;
  padding: 5px 12px;
  /* background-color: #4776bf; */
  /* color: white; */
  margin-top: 285px;
  margin-left: 15px;
  width: 100px;
}
/* .c-button + * {
    margin-left: 0rem;
} */
.c-button-2 {
  text-align: center;
    border-radius: 9999px;
    background-color: #fff;
    border-width: 2px;
    border-style: solid;
    border-color: #e2e8f0;
    color: #718096;
    font-size: 0.875rem;
    font-weight: 500;
    white-space: nowrap;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    -webkit-transition-property: background-color, color, border-color;
    transition-property: background-color, color, border-color;
    -webkit-transition-duration: 125ms;
    transition-duration: 125ms;
}
.c-button-2.primary {
  background-color: #1F362E;
    border-color: #1F362E;
    color: #fff;
}
.cool-lightbox .cool-lightbox__slide img {
    /* width: 100% !important;
    height: 100% !important; */
    max-width: 100% !important;
    max-height: 100% !important;
}
.cool-lightbox.cool-lightbox--is-zooming .cool-lightbox__slide img {
    width: 100% !important;
    height: auto !important;
    max-width: none !important;
    max-height: none !important;
    cursor: all-scroll !important;
}
.pulse {
    position: absolute;
    /* top: 35%;
    left: 40%;
    width: 100px;
    transform: translate(-505, -50%);
    height: 100px; */
    background: #1F362E;
    border: 2px solid #1F362E;
    /* border-radius: 50%; */
    box-sizing: border-box;
    /* box-shadow: 0 0 0 36px #45237a, 0 0 0 40px #56ff00; */
}
.pulse:before, .pulse:after {
    content: '';
    position: absolute;
    left: -20px;
    top: -10px;
    right: -20px;
    bottom: -10px;
    border: 1px solid goldenrod;
    border-radius: 9999px;
    animation: animate 8s linear infinite;
    animation-delay: 4s;
}
.pulse:after {
    animation-delay: 8s;
}
@keyframes animate {
    0% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(0.7);
    }
}
</style>
