<template>
  <section class="c-section mx-auto" :class="{ container: container }">
    <slot />
  </section>
</template>

<script>
export default {
  props: {
    container: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="postcss">
.c-section {
  @apply py-24 px-4;
  & + & {
    @apply pt-0;
  }

  .divider {
    @apply border-b-2 border-solid border-gray-200 pb-4;

    & + .divider {
      @apply pt-4;
    }
  }
}
</style>
