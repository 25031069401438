<template>
  <div class="c-input" :class="{ invalid: invalid }">
    <label class="c-input__label" v-if="label" @click.prevent>
      {{ label }}
      <c-info
        v-if="tooltip"
        class="mr-2"
        :content="tooltip"
      />
    </label>

    <textarea
      v-if="type === 'textarea'"
      v-model="currentValue"
      class="c-input__field c-input__textarea"
      rows="4"
      :placeholder="placeholder"
    ></textarea>

    <div class="c-input__range-wrapper flex" v-else-if="type === 'range'">
      <span class="c-input__range-scale text-primary-500">
        {{ value }} {{ rangeSuffix }}
      </span>
      <input
        type="range"
        class="c-input__field"
        v-model="currentValue"
        :min="$attrs.min"
        :step="$attrs.step"
      />

      <span class="c-input__range-scale">
        {{ $attrs.max }} {{ rangeSuffix }}
      </span>
    </div>

    <div class="c-input__field-wrapper " v-else>
      <div class="c-input__field-icon" v-if="icon">
        <font-awesome-icon :icon="icon" />
      </div>
      <!-- type file -->
      <input
        v-if="type === 'file'"
        class="c-input__field"
        @change="handleFileInput($event)"
        :data-file="
          typeof currentValue === 'object'
            ? currentValue.name
            : 'Selecionar arquivo'
        "
        :class="{ 'with-icon': icon, 'with-file': currentValue !== '' }"
        :placeholder="placeholder"
        :type="type"
        :accept="isFile ? 'image/*,.pdf' : undefined"
      />
      <!-- type tel, number, text -->
      <input
        v-else-if="mask"
        v-model="currentValue"
        class="c-input__field"
        :class="{ 'with-icon': icon }"
        type="tel"
        :placeholder="placeholders[mask]"
        :min="type === 'number' ? 0 : false"
        :max="max"
        v-mask="masks[mask]"
      />
      <money
        v-else-if="money"
        v-model.lazy="currentValue"
        v-bind="moneyConfigs"
        class="c-input__field"
        :class="{ 'with-icon': icon }"
        :placeholder="placeholder"
      ></money>
      <!-- no mask -->
      <date-picker
        v-else-if="type === 'date'"
        class="c-input__field c-input__date"
        v-model="currentValue"
        :placeholder="placeholder"
        :minDate="new Date()"
        :input-props="{
          placeholder: 'Selecione'
        }"
      ></date-picker>
      <input
        v-else
        v-model="currentValue"
        class="c-input__field"
        :class="{ 'with-icon': icon }"
        :placeholder="placeholder"
        :type="type"
        :min="type === 'number' ? 0 : false"
      />
      <slot></slot>
    </div>
    <small class="text-xs text-red-500" v-if="invalid">
      Este campo é obrigatório.
    </small>
  </div>
</template>

<script>
import CInfo from "./CInfo.vue"
import { mask } from "vue-the-mask"
import { Money } from "v-money"
import { DatePicker } from "v-calendar"

export default {
  directives: {
    mask
  },
  components: {
    DatePicker,
    Money,
    CInfo
  },
  props: {
    type: {
      type: String,
      default: "text"
    },
    icon: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number, File],
      default: ""
    },
    mask: {
      type: String,
      default: ""
    },
    sufixo: {
      type: String,
      default: ""
    },
    invalid: {
      type: Boolean,
      default: false
    },
    money: {
      type: Boolean,
      default: false
    },
    rangeSuffix: {
      type: String,
      default: ""
    },
    max: {
      type: Number,
      default: 100000000
    },
    tooltip: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      moneyConfigs: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        // suffix: this.sufixo,
        precision: 2,
        masked: false
      }
    }
  },
  computed: {
    currentValue: {
      get() {
        if (this.value) {
          return this.value
        }

        return ""
      },
      set(value) {
        this.$emit("input", value)
      }
    },
    isFile() {
      return this.type === "file"
    },
    masks() {
      return {
        cpf: "###.###.###-##",
        date: "##/##/####",
        tel: "(##) ##### ####",
        phone: "(##) #### ####",
        cep: "#####-###"
      }
    },
    placeholders() {
      return {
        cpf: "000.000.000-00",
        date: "DD/MM/AAAA",
        tel: "(00) 00000 0000",
        phone: "(00) 0000 0000",
        cep: "00000-000"
      }
    }
  },
  methods: {
    handleFileInput(event) {
      this.currentValue = event.target.files[0]
    }
  }
}
</script>

<style lang="postcss">
.c-input {
  @apply mb-2;

  @media screen and (max-width: 768px) {
    @apply text-xs;
  }

  &.text-xs {
    ^&__label {
      @apply text-xs;
    }
  }

  &__label {
    @apply text-sm text-gray-800 font-medium block mb-1;
  }
  &__range {
    &-wrapper {
      @apply flex justify-center items-center;
    }
    &-scale {
      @apply whitespace-no-wrap flex-grow text-xs font-medium text-gray-600;

      &:first-of-type {
        @apply pr-2;
      }
      &:last-of-type {
        @apply pl-2;
      }
    }
  }

  &__field {
    @apply px-5 py-3 w-full text-sm;

    &.c-input__date {
      @apply p-0;

      input {
        @apply shadow-none border-0 px-5 py-3 leading-normal;
      }
    }

    &[type="file"] {
      @apply flex relative;
      cursor: pointer;
      line-height: 1;

      &::after {
        content: attr(data-file);
        @apply absolute top-0 right-0 h-full px-4 py-4 bg-gray-300 text-primary-500 text-sm font-medium w-full text-center;
      }

      &.with-file::after {
        @apply bg-green-500 text-white;
      }
    }

    &[type="range"] {
      @apply px-0 py-4 w-full flex-grow-0;
      -webkit-appearance: none;
      appearance: none;

      &::-webkit-slider-thumb {
        @apply bg-white rounded-full p-3;
        -webkit-appearance: none;
        width: 20px;
        posititon: relative;
        margin-top: -10px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
        cursor: pointer;
      }

      &::-webkit-slider-runnable-track {
        @apply w-full bg-primary-500 rounded-full h-1;
      }

      &:focus {
        background: none;
        ^&::-webkit-slider-thumb {
          @apply bg-primary-100;
        }
      }
    }

    &-wrapper {
      @apply bg-white border-2 border-solid border-gray-300 rounded-lg text-gray-800 flex items-center overflow-hidden;
    }

    &-icon {
      @apply text-sm px-4 text-gray-500;

      & + input {
        @apply px-2;
      }
    }

    &::-webkit-file-upload-button {
      visibility: hidden;
      width: 0;
      height: 24px;
      padding: 0;
    }
  }
  &__file-button {
    @apply py-4;
  }
  &__textarea {
    @apply border-2 border-solid border-gray-300 rounded-lg text-gray-800;
  }
  &.invalid {
    ^&__label {
      @apply text-red-500;
    }
    ^&__field-wrapper {
      @apply border-red-100;
    }
  }

  .c-button {
    height: 42px;
    min-width: 50px;
    @apply p-0;
  }

  @media (max-width: 768px) {
    &__field {
      @apply text-xs;
    }
  }
}
</style>
