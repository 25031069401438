<template>
  <label
    class="c-checkbox"
    :class="[
      {
        radio: type === 'radio',
        checked:
          typeof checked === 'object'
            ? checked.includes(label)
            : checked === value || value === label
      },
      ...checkboxClass
    ]"
  >
    <input
      class="c-checkbox__input"
      :type="type"
      :name="name"
      v-model="currentValue"
      :value="label"
    />
    <span class="c-checkbox__box">
      <font-awesome-icon
        :icon="type === 'radio' ? 'circle' : 'check'"
        class="text-xs"
      />
    </span>
    <span v-if="destaque" class="c-checkbox__label c-checkbox__destaque">{{ aparecelabel }}</span>
    <span v-else class="c-checkbox__label">{{ aparecelabel }}</span>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "checkbox"
    },
    value: {
      type: undefined,
      default: true
    },
    checkboxClass: {
      type: String,
      default: ""
    },
    destaque: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checked: false,
      valuetostring: "",
      labeltostring: "",
      valuetoarr: ""
    }
  },
  computed: {
    currentValue: {
      get() {
        return this.value
      },
      set(val) {
        this.checked = val
        this.$emit("input", this.checked)
      }
    },
    aparecelabel: function() {
      if (this.label === "0+") {
        return "Tanto faz"
      } if (this.label === "5") {
        return "5 ou mais"
      } else {
        return this.label
      }
    }
  }
  // created() {
  //   this.valuetostring = "" + this.value
  //   this.labeltostring = "" + this.label
  //   this.labeltostring = this.labeltostring.replace("+", "").replace(" ", "")
  //   this.valuetostring = this.valuetostring.replace("+", "").replace(" ", "")
  //   // if (this.label === "5+") {
  //   //   console.log("testtabdi:")
  //   //   console.log("value: " + this.labeltostring)
  //   //   console.log("label: " + this.valuetostring)
  //   //   console.log("checked: " + this.checked)
  //   // }
  //   this.valuetoarr = this.valuetostring.split(",").map(s => s.trim())
  // }
  // created() {
  //   if (this.label === "0+")
  // },
}
</script>

<style lang="postcss">
.c-checkbox {
  cursor: pointer;

  &__box {
    @apply border-2 border-solid border-gray-300 rounded inline-block mr-2 text-center;
    width: 20px;
    height: 20px;
    line-height: 14px;

    svg {
      visibility: hidden;
    }
  }

  &__input {
    display: none;
    &:checked + ^&__box svg {
      @apply text-primary-500;
      visibility: visible;
    }
  }

  &.radio {
    ^&__box {
      @apply rounded-full;
      line-height: 13px;

      svg {
        font-size: 0.65em;
      }
    }
  }

  &.c-button {
    ^&__box {
      display: none;
    }

    &.checked {
      @apply bg-primary-500 text-white;
    }
  }

  @media (max-width: 768px) {
    &__label {
      @apply text-xs;
    }
  }
}
.c-checkbox__destaque {
  font-weight: 500;
}
</style>
