<template>
  <div class="rounded-lg overflow-hidden">
    <iframe
      width="100%"
      height="480"
      :src="src"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: "https://www.youtube.com/embed/Keow7X52cU4"
    }
  }
}
</script>

<style></style>
