export default {
  estadoCivil: [
    {
      id: "Solteiro",
      label: "Solteiro"
    },
    {
      id: "Casado",
      label: "Casado"
    },
    {
      id: "Divorciado",
      label: "Divorciado"
    },
    {
      id: "Viúvo",
      label: "Viúvo"
    },
    {
      id: "Separado",
      label: "Separado"
    },
    {
      id: "União Estável",
      label: "União Estável"
    }
  ],
  documentoTipo: ["Identidade", "CNH", "Carteira profissional"],
  tipoDeImoveis: [
    {
      id: "Casa",
      label: "Casa"
    },
    // {
    //   id: "Sobrado",
    //   label: "Sobrado"
    // },
    {
      id: "Apartamento",
      label: "Apartamento"
    },
    {
      id: "Kitnet/Loft/Studio",
      label: "Kitnet/Loft/Studio"
    },
    {
      id: "Cobertura",
      label: "Cobertura"
    },
    {
      id: "Duplex",
      label: "Duplex"
    },
    {
      id: "Triplex",
      label: "Triplex"
    },
    // {
    //   id: "Loft",
    //   label: "Loft"
    // },
    {
      id: "Terreno",
      label: "Terreno"
    },
    {
      id: "Pavilhão",
      label: "Pavilhão"
    },
    {
      id: "Prédio",
      label: "Prédio"
    },
    {
      id: "Chácara/Sítio",
      label: "Chácara/Sítio"
    },
    {
      id: "Campo/Fazenda",
      label: "Campo/Fazenda"
    },
    // {
    //   label: "Comerciais",
    //   disabled: true
    // },
    // {
    //   libs: [
    //     {
    //       id: "Sala Comercial",
    //       label: "Sala Comercial"
    //     },
    //     {
    //       id: "Casa Comercial",
    //       label: "Casa Comercial"
    //     },
    //     {
    //       id: "Loja",
    //       label: "Loja"
    //     }
    //   ]
    // }
    {
      id: "Sala Comercial",
      label: "Sala Comercial"
    },
    {
      id: "Casa Comercial",
      label: "Casa Comercial"
    },
    {
      id: "Loja",
      label: "Loja"
    }
    // {
    //   id: "Studio",
    //   label: "Studio"
    // }
    // {
    //   id: "Fazenda",
    //   label: "Fazenda"
    // },
    // {
    //   id: "Sítio",
    //   label: "Sítio"
    // }
  ],
  tipoDeImoveisArr: [
    {
      label: "Residenciais",
      opcoes: [
        "Casa",
        "Apartamento",
        "Kitnet/Loft/Studio",
        "Cobertura",
        "Duplex",
        "Triplex"
      ]
    },
    {
      label: "Comerciais",
      opcoes: [
        "Terreno",
        "Pavilhão",
        "Prédio",
        "Sala Comercial",
        "Casa Comercial",
        "Loja"
      ]
    },
    {
      label: "Rurais",
      opcoes: [
        "Chácara/Sítio",
        "Campo/Fazenda"
      ]
    }
  ],
  condicao: [
    {
      id: "Alugado",
      label: "Alugado"
    },
    {
      id: "Desocupado",
      label: "Desocupado"
    },
    {
      id: "Em construção",
      label: "Em construção"
    }
    // {
    //   id: "Próprio",
    //   label: "Próprio"
    // },
    // {
    //   id: "Financiado",
    //   label: "Financiado"
    // },
    // {
    //   id: "Hotel",
    //   label: "Hotel"
    // },
    // {
    //   id: "De familiar",
    //   label: "De familiar"
    // },
    // {
    //   id: "De amigos",
    //   label: "De amigos"
    // }
  ],
  vinculoEmpregaticio: [
    {
      id: "CLT",
      label: "CLT"
    },
    {
      id: "Aposentado/Pensionista",
      label: "Aposentado/Pensionista"
    },
    {
      id: "Autônomo",
      label: "Autônomo"
    },
    {
      id: "Empresário",
      label: "Empresário"
    },
    {
      id: "Estudante",
      label: "Estudante"
    },
    {
      id: "Funcionário público",
      label: "Funcionário público"
    },
    {
      id: "Profissional liberal",
      label: "Profissional liberal"
    },
    {
      id: "Renda proveniente de aluguéis",
      label: "Renda proveniente de aluguéis"
    },
    {
      id: "Desempregado",
      label: "Desempregado"
    }
  ],
  dormitorios: [
    {
      id: 1,
      label: "1 ou mais"
    },
    {
      id: 2,
      label: "2 ou mais"
    },
    {
      id: 3,
      label: "3 ou mais"
    }
  ],
  dormitorios2: [
    {
      id: 1,
      label: "1"
    },
    {
      id: 2,
      label: "2"
    },
    {
      id: 3,
      label: "3"
    },
    {
      id: 4,
      label: "4"
    },
    {
      id: 5,
      label: "5+"
    }
  ],
  dormitorios3: [
    "1",
    "2",
    "3",
    "4",
    "5"
  ],
  ordenacao: [
    {
      id: "mais_recentes",
      label: "Mais recentes"
    },
    {
      id: "maior_preco",
      label: "Maior preço"
    },
    {
      id: "menor_preco",
      label: "Menor preço"
    }
  ],
  ordenacao_cond: [
    {
      id: "mais_recentes",
      label: "Mais recentes"
    },
    {
      id: "maior_preco",
      label: "Maior preço"
    },
    {
      id: "menor_preco",
      label: "Menor preço"
    },
    {
      id: "mais_dormitorios",
      label: "Mais dormitórios"
    },
    {
      id: "mais_garagens",
      label: "Mais garagens"
    },
    {
      id: "maior_areautil",
      label: "Maior área útil"
    }
  ],
  comodidades: [
    "Aquecimento a gás",
    "Churrasqueira",
    "Closet",
    "Dep.de empregada",
    "Elevador",
    "Gás central",
    "Lareira",
    "Piscina",
    "Portaria",
    "Sacada",
    "Salão de festas",
    "Terraço"
  ],
  orientacaoSolar: [
    "Norte",
    "Sul",
    "Oeste",
    "Leste"
  ]
}
