<template>
  <div class="home">
    <main-header
      :bgImages="['home.jpg','slide-1.jpg']"
    >
      <div class="px-6 py-16 home__hero">
        <c-section-title mb="12">
          <span class="text-3xl md:text-4xl">
            Qual imóvel você quer visitar hoje?
          </span>
        </c-section-title>
        <!-- <form class="createform" v-on:submit.prevent="insert"> -->
        <!-- <form
          id="app"
          @submit="insert"
          method="get"
          action="venda"
        > -->
          <div class="w-full md:w-1/2">
            <div class="flex justify-between py-2 mb-2 text-sm font-medium md:justify-start md:text-base">
              <button
                class="mr-4 border-white border-solid"
                :class="{ active: property_search_mode === 1 }"
                @click="togglePropertySearchMode(1)"
              >
                Comprar
              </button>
              <button
                class="mr-4 border-white border-solid"
                :class="{ active: property_search_mode === 2 }"
                @click="togglePropertySearchMode(2)"
              >
                Alugar
              </button>
              <button
                class="border-white border-solid"
                :class="{ active: property_search_mode === 0 }"
                @click="togglePropertySearchMode(0)"
              >
                Buscar por código
              </button>
            </div>
            <template v-if="property_search_mode === 1">
              <div class="flex flex-wrap md:flex-no-wrap">
                <c-select
                  v-model="property_search.cidade"
                  placeholder="Busque por cidade"
                  :options="cidadesFromAPI"
                  icon="map-marker-alt"
                  class="w-full mr-0 text-sm md:w-2/3 md:mr-2"
                  label=""
                  @input="filtrarBairros"
                />
                <!-- <c-select
                  v-model="property_search.dormitorios"
                  placeholder="Quartos"
                  :options="formOptions.dormitorios"
                  icon="bed"
                  class="w-full text-sm md:w-1/3"
                  label=""
                /> -->
                <!-- <c-select
                  v-model="property_search.dormitorios"
                  placeholder="Quartos"
                  :options="formOptions.dormitorios2"
                  icon="bed"
                  class="w-full text-sm md:w-1/3"
                  label=""
                  multiple
                /> -->
              </div>
              <c-select
                  v-model="property_search.bairros"
                  :options="filtrarosbairros"
                  placeholder="Busque um ou mais bairros"
                  :close-on-select="false"
                  :limit="5"
                  class="w-full mr-0 text-sm md:w-2/3 md:mr-2"
                  icon="search"
                  multiple
                />
                <c-select
                  :options="formOptions.tipoDeImoveis"
                  v-model="property_search.tipo_de_imovel"
                  multiple
                  class="w-full mr-0 text-sm md:w-2/3 md:mr-2"
                  icon="search"
                  placeholder="Escolha os tipos de imóveis"
                  @input="tiposDefinidos"
                />
            </template>
            <template v-else-if="property_search_mode === 2">
              <!-- <p class="mb-4 text-lg text-yellow-500">
                Mudamos, para melhor! Alugue seu imóvel na <a class="font-bold underline" href="https://alugadigital.com">AlugaDigital.com</a>, com atendimento especializado para você ;)
              </p>
              <a
                class="block w-full mt-1 c-button primary md:w-56"
                href="https://alugadigital.com"
              >
                Ir para
                <span class="underline">AlugaDigital.com</span>
              </a> -->
              <div class="flex flex-wrap md:flex-no-wrap">
                <c-select
                  v-model="property_search.cidade"
                  placeholder="Busque por cidade"
                  :options="cidadesFromAPI"
                  icon="map-marker-alt"
                  class="w-full mr-0 text-sm md:w-2/3 md:mr-2"
                  label=""
                />
                <!-- <c-select
                  v-model="property_search.dormitorios"
                  placeholder="Quartos"
                  :options="formOptions.dormitorios"
                  icon="bed"
                  class="w-full text-sm md:w-1/3"
                  label=""
                /> -->
                <!-- <c-select
                  v-model="property_search.dormitorios"
                  placeholder="Quartos"
                  :options="formOptions.dormitorios2"
                  icon="bed"
                  class="w-full text-sm md:w-1/3"
                  label=""
                  multiple
                /> -->
              </div>
              <c-select
                v-model="property_search.bairros"
                placeholder="Busque um ou mais bairros"
                :close-on-select="false"
                :options="filtrarosbairros"
                :limit="5"
                class="w-full text-sm md:w-2/3"
                icon="search"
                multiple
              />
              <c-select
                :options="formOptions.tipoDeImoveis"
                v-model="property_search.tipo_de_imovel"
                multiple
                class="w-full text-sm md:w-2/3"
                icon="search"
                placeholder="Escolha os tipos de imóveis"
              />
            </template>
            <template v-else>
              <c-input class="w-full text-sm md:w-2/3" icon="search" placeholder="Digite o código do imóvel" v-model="codigo" />
            </template>
            <!-- Use Isso para ANULAR O Botão faz Pesquisa nas Locações: "v-if="property_search_mode !== 2"" -->
            <c-button
              class="w-full mt-1 primary md:w-48"
              @click="fazPesquisa"
            >
              Pesquisar
            </c-button>
          </div>
      </div>
    </main-header>
    <!-- <c-section class="tex-center">
      <center>
        <a href="/venda?tags=black" class="tex-center">
          <img :src="campanha" style="border-radius: 5px" class="img-campanha" />
        </a>
      </center>
    </c-section> -->
    <!--
    <c-section class="home__benefits">
      <div class="w-full px-4 md:w-full">
        <div class="w-full px-4 md:w-full">
        <a href="/campanha" class="link-campanha">
          <img src="/img/feirao-mcmv-horizontal.png" style="max-height: 350px;" class="lg:w-full" />
        </a>
        </div>
      </div>
    </c-section>
    -->
    <c-section class="home__carousel">
      <div class="flex items-center">
        <div
          class="w-full p-0 m-0 md:pr-20 md:mr-16 md:w-auto"
        >
          <c-section-title mb="0">
            Imóveis para comprar em
            <template #sub>
              <c-select @input="filtrarCidade" :options="cidadesFromAPI" v-model="filterhome.cidade" />
            </template>
          </c-section-title>
        </div>
      </div>
      <!-- <hr>
      <ul>
        <li v-for="(item, i) in filtracidade" :key="i"> - {{ item.codigo }}</li>
      </ul>
      <hr> -->
      <c-carousel  :perPageCustom="[1, 2, 3]">
        <template v-for="item in filtracidade" :slot="`slot-${item.codigo}`" >
            <property-card
              :key="item.codigo"
              v-bind:pagina="'home'"
              v-bind:codigo="item.codigo"
              v-bind:contrato="item.contrato"
              v-bind:tipo="item.tipo"
              v-bind:subtipo="item.subtipo"
              v-bind:imagem="item.thumb"
              v-bind:bairro="item.endereco_bairro"
              v-bind:cidade="item.endereco_cidade"
              v-bind:estado="item.endereco_estado"
              v-bind:endereco="item.endereco_logradouro"
              v-bind:empreendimento="item.condominio_nome"
              v-bind:dormitorios="item.dormitorios"
              v-bind:vagas="item.garagens"
              v-bind:area="item.area_privativa"
              v-bind:valor_venda="item.valor_venda"
              v-bind:valor_locacao="item.valor_locacao"
              v-bind:tags="item.tags"
              v-bind:exclusivo="item.exclusividade"
              v-bind:status="item.situacao_condominio"
            />
            <!-- {{ item.imagens[0].link_thumb }} -->
        </template>
      </c-carousel>
      <router-link
          :to="'/venda' + urlcomplementos"
          class="block w-64 m-auto c-button primary"
        >
          Ver mais ofertas
        </router-link>
    </c-section>
    <c-section class="home__benefits">
      <c-section-title>
        Benefícios da Café para
        <template #strong>você</template>
      </c-section-title>
      <div class="flex flex-wrap -mx-4">
        <!--  bg="imovel.jpg" -->
        <div class="w-full px-4 mb-4 md:w-2/3">
          <benefit-card
            icon="heart"
            bg="cashback-social.jpeg"
            class="text-center rounded md:text-left"
            :to="'/cashback-social' + urlcomplementos"
          >
            <!-- <img src="/img/1.jpg" style="float: left;width:100px" class="lg:w-2/1" /> -->
            Cashback social
            <template #content>
              Comprar seu imóvel na Café ajuda na construção de um mundo melhor.
              Saiba mais!
            </template>
          </benefit-card>
        </div>
        <div class="w-full px-4 mb-4 md:w-1/3 md:pl-0 tooltip" data-direction="top" style="z-index: 0 !important">
          <a
            class="tooltip__initiator"
          >
            <benefit-card
              icon="thumbs-up"
              bg="indique.jpeg"
              class="items-center justify-center text-center bg-gray-100 rounded"
              :to="urlcomplementos"
            >
              Indique e ganhe
              <template #content>
                Indique imóveis para vender e ganhe junto conosco.
              </template>
            </benefit-card>
          </a>
          <div class="tooltip__item">
            Em breve!
          </div>
        </div>
        <div class="w-full px-4 mb-4 md:w-1/3 md:pr-0">
          <benefit-card
            icon="door-open"
            bg="imovel-ideal.jpeg"
            class="items-center justify-center text-center bg-gray-100 rounded"
            onclick="document.getElementById('home__notifications').scrollIntoView({ block: 'end',  behavior: 'smooth' })"
            :to="urlcomplementos"
          >
            Imóvel ideal para você
            <template #content>
              Não encontrou o imóvel que deseja? Nosso time de vendas vai atrás.
            </template>
          </benefit-card>
        </div>
        <div class="w-full px-4 mb-4 md:w-2/3">
          <benefit-card
            icon="hands-helping"
            bg="suporte.jpeg"
            class="text-center bg-gray-100 rounded md:text-left"
            :to="'/sobre-nos' + urlcomplementos"
          >
            Suporte especializado
            <template #content>
              Oferecemos os melhores recursos para que sua jornada de compra
              seja agradável e transparente do início ao fim.
            </template>
          </benefit-card>
        </div>
      </div>
    </c-section>
    <c-section class="home__reasons">
      <c-section-title>
        Motivos para escolher a
        <template #strong>
          Café Inteligência Imobiliária
        </template>
      </c-section-title>
      <div class="-mx-4 card__wrapper">
        <div class="flex w-full pb-4 overflow-hidden overflow-x-scroll no-scrollbar">
          <div class="flex-none px-4 mb-4 w-72 md:w-1/3">
            <figure-card img="wizard">
              <template #title>Resultados digitais</template>
              <template #content>
                Somos especialistas na geração de resultados digitais. Investimos
                pesado nos principais marketplaces do mercado imobiliário e outros
                meios digitais de divulgação e prospecção de clientes.
              </template>
            </figure-card>
          </div>
          <div class="flex-none px-4 mb-4 w-72 md:w-1/3">
            <figure-card img="online_friends">
              <template #title>Qualidade de informação</template>
              <template #content>
                Priorizamos entregar aos nossos clientes um conteúdo relevante e
                de alto valor informacional (fotos, vídeos, descrições, etc.)
                permitindo uma análise e visitação mais assertiva.
              </template>
            </figure-card>
          </div>
          <div class="flex-none px-4 mb-4 w-72 md:w-1/3">
            <figure-card img="hire">
              <template #title>Relações transparentes</template>
              <template #content>
                Em um mercado tão competitivo, conduzimos nossas ações com total
                transparência e imparcialidade, zelando pela segurança nos
                negócios imobiliários.
              </template>
            </figure-card>
          </div>
        </div>
      </div>
    </c-section>
    <div class="home__notifications" style="
      padding-bottom: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;"
    >
      <div class="container m-auto" id="home__notifications">
        <c-section-title>
          Ainda não encontrou o imóvel que você deseja?
          <template #sub>
            Nos conte um pouco sobre suas preferências e deixe nossos corretores
            encontrarem para você.
            <br />
            <br />
            <br />
            <c-button class="w-full primary md:w-auto" @click="mostraformulario = !mostraformulario">
              Quero encontrar um Imóvel
            </c-button>
          </template>
        </c-section-title>
        <div class="flex notifications__form-wrapper" v-show="mostraformulario">
          <form
            action=""
            class="flex flex-wrap w-full notifications__form md:w-1/2"
            ref="notifications__form"
            v-on:submit.prevent="enviarContato"
          >
            <div class="flex flex-wrap w-full">
              <c-input
                label="Nome"
                class="w-full md:w-1/2 md:pr-4"
                placeholder="Nome completo"
                v-model="notification_form.nome"
                :invalid="$v.notification_form.nome.$invalid && isTouched"
              />
              <c-input
                label="E-mail"
                class="w-full md:w-1/2"
                type="email"
                placeholder="seunome@email.com"
                v-model="notification_form.email"
                :invalid="$v.notification_form.email.$invalid && isTouched"
              />
            </div>
            <div class="flex flex-wrap w-full">
              <c-input
                label="Telefone"
                type="tel"
                mask="tel"
                class="w-full md:w-1/2 md:pr-4"
                v-model="notification_form.telefone"
                placeholder="Telefone"
              />
              <!-- <c-select
                label="Tipo de imóvel"
                class="w-full md:w-1/2"
                :options="formOptions.tipoDeImoveis"
                v-model="notification_form.tipo_de_imovel"
                multiple
              /> -->
              <c-checkbox-group-encadeado
                label="Tipo de Imóvel"
                type="checkbox"
                :model="[notification_form, 'tipo_de_imovel']"
                :options="formOptions.tipoDeImoveisArr"
                class="flex flex-wrap"
                checkboxClass="w-full ml-0"
                name="tipo_de_imovel"
              ></c-checkbox-group-encadeado>
            </div>
            <!-- @click.native="alteratiposimoveis()" -->
            <div class="flex flex-wrap w-full" id="comod-home">
              <!-- <c-input
                class="w-full md:w-1/2 md:pr-4"
                label="Dormitórios"
                type="number"
                v-model="notification_form.dormitorios"
                placeholder="Número de dormitórios"
              /> -->
              <c-checkbox-group
                label="Dormitórios"
                name="dormitorios"
                type="radio"
                :model="[notification_form, 'dormitorios']"
                :options="['1+', '2+', '3+', '4+']"
                class="flex flex-wrap justify-center w-full md:w-1/2 md:pr-4"
                checkboxClass="c-button"
              ></c-checkbox-group>
              <!-- <c-input
                class="w-full md:w-1/2"
                label="Vagas de garagem"
                type="number"
                v-model="notification_form.vagas_garagem"
                placeholder="Número de vagas"
              /> -->
              <c-checkbox-group
                label="Garagem"
                name="garagem"
                type="radio"
                :model="[notification_form, 'vagas_garagem']"
                :options="['Tanto \n faz','1+', '2+', '3+']"
                class="flex flex-wrap justify-center w-full md:w-1/2"
                checkboxClass="c-button"
              ></c-checkbox-group>
            </div>
            <c-input
              type="textarea"
              label="O que mais é importante sabermos?"
              class="w-full mb-4"
              v-model="notification_form.comentarios"
              placeholder="O que você procura num imóvel? Quais regiões/bairros de interesse?"
            />
            <c-button class="w-full primary md:w-auto">
              Quero receber opções
            </c-button>
          </form>
          <!-- <div class="interest-modal">
            <div class="interest-modal__wrapper">
              <button
                class="interest-modal__close"
                title="Fechar"
                @click="$emit('close')"
              >
                <font-awesome-icon icon="times" />
              </button>
              <p class="my-4 text-center">
                Tem interesse ou dúvidas sobre este imóvel? Informe seus dados e
                entraremos em contato.
              </p>
            </div>
          </div> -->
          <!-- <c-modal
            v-if="true"
            class="text-sm"
            @click="$emit('close')"
          >
            <template #body>
              <h3 class="mb-2 text-xl font-medium">Nome do Cliente</h3>
              <div class="text-left">
                <p>
                  <strong>Nome</strong>
                  : Nome do Cliente,
                </p>
                <p>
                  <strong>Data de nascimento</strong>
                  : 20/11/1980
                </p>
                ...resto do cadastro
              </div>
            </template>
          </c-modal> -->
        </div>
      </div>
    </div>
    <!-- Aqui os bairros mais procurados -->
    <c-section class="flex flex-wrap home__suggestions">
      <property-suggestions class="w-full" />
    </c-section>
  </div>
</template>
<script>
import CInput from "../components/UI/CInput.vue"
import CCarousel from "../components/UI/CCarousel.vue"
import PropertySuggestions from "../components/PropertySuggestions.vue"
import FigureCard from "../components/FigureCard.vue"
import CSectionTitle from "../components/UI/CSectionTitle.vue"
import BenefitCard from "../components/BenefitCard.vue"
import PropertyCard from "../components/PropertyCard.vue"
import CSection from "../components/UI/CSection.vue"
import CButton from "../components/UI/CButton.vue"
import CSelect from "../components/UI/CSelect.vue"
import CCheckboxGroup from "../components/UI/CCheckboxGroup.vue"
import CCheckboxGroupEncadeado from "../components/UI/CCheckboxGroupEncadeado.vue"
import MainHeader from "../components/MainHeader.vue"
import FormOptionsMixin from "../mixins/FormOptionsMixin"
import EstadoCidadeMixin from "../mixins/EstadoCidadeMixin"
// import CModal from "../components/UI/CModal.vue"
import axios from "axios"
import { SnotifyPosition, SnotifyStyle } from "vue-snotify"
// import { required, email, requiredIf } from "vuelidate/lib/validators"
import { required, email } from "vuelidate/lib/validators"

export default {
  mixins: [EstadoCidadeMixin, FormOptionsMixin],
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Café Inteligência Imobiliária - Imobiliária em Santa Maria RS",
    meta: [
      {
        name: "description",
        content: "Na Café Inteligência Imobiliária, em Santa Maria-RS, você compra e vende seu imóvel com rapidez e segurança. Assessoria competente e integral para você realizar bons negócios!"
      }
    ]
  },
  computed: {
    urlcomplementos() {
      var retorno = ""
      if (this.$route.query.utm_source) {
        retorno += "?utm_source=" + this.$route.query.utm_source
        if (this.$route.query.utm_medium) {
          retorno += "&utm_medium=" + this.$route.query.utm_medium
        }
        if (this.$route.query.utm_campaign) {
          retorno += "&utm_campaign=" + this.$route.query.utm_campaign
        }
        if (this.$route.query.utm_id) {
          retorno += "&utm_id=" + this.$route.query.utm_id
        }
        if (this.$route.query.utm_term) {
          retorno += "&utm_term=" + this.$route.query.utm_term
        }
        if (this.$route.query.utm_content) {
          retorno += "&utm_content=" + this.$route.query.utm_content
        }
        return retorno
      } else {
        return ""
      }
    }
  },
  components: {
    MainHeader,
    CSelect,
    CButton,
    CSection,
    PropertyCard,
    BenefitCard,
    CSectionTitle,
    FigureCard,
    CInput,
    PropertySuggestions,
    CCarousel,
    CCheckboxGroup,
    CCheckboxGroupEncadeado
    // CModal
    // SnotifyPosition,
    // SnotifyStyle
  },
  data() {
    return {
      isTouched: false,
      cidadesFromAPI: [],
      bairrosFromAPI: [],
      campanha: require("@/assets/img/banner-feirao.png"),
      property_search_mode: 1,
      property_search: {
        cidade: "Santa Maria",
        dormitorios: null,
        bairros: [],
        tipo_de_imovel: []
      },
      filterhome: {
        cidade: "Santa Maria"
      },
      notification_form: {
        nome: "",
        email: "",
        telefone: "",
        tipo_de_imovel: [],
        dormitorios: "",
        vagas_garagem: "",
        comentarios: ""
      },
      codigo: null,
      imoveis_destaque: [],
      filtrados: {
        cidade: null
      },
      tags: [],
      filtracidade: [],
      filtrarosbairros: [],
      mostraformulario: false
    }
  },
  created () {
    // this.notification_form.tipo_de_imovel = ["Casa", "Apartamento", "Kitnet/Loft/Studio", "Cobertura", "Duplex", "Triplex"]
    axios.get("jsoncidades.json")
      .then((response) => {
        this.cidadesFromAPI = response.data.map(cidade => {
          /* id = valor que é atribuído ao select */
          /* label = valor que é exibido para o usuário */
          return {
            id: cidade.nome,
            label: cidade.nome,
            // label: [this.replaceSpecialChars(cidade.nome), cidade.nome],
            ident: cidade.id
          }
        })
      })

    axios.get("jsonbairros.json")
      .then((response) => {
        this.bairrosFromAPI = response.data.map(bairro => {
          /* id = valor que é atribuído ao select */
          /* label = valor que é exibido para o usuário */
          return {
            id: [this.replaceSpecialChars(bairro.nome), bairro.nome],
            // Achar a forma melhor de passar array de opções oculto para a component ui CSelect.vua
            // label: [this.replaceSpecialChars(bairro.nome), bairro.nome],
            label: bairro.nome,
            cidade: bairro.cidade
          }
        })
        this.filtrarosbairros = this.bairrosFromAPI.filter(d => d.cidade === this.property_search.cidade)
      })
    axios.get("jsons/cafeimoveis.json")
      .then((response) => {
        this.imoveis_destaque = response.data
        //  && d.destaque === "Destaque"
        this.filtracidade = this.imoveis_destaque.filter(d => d.endereco_cidade === "Santa Maria" && d.tags.split(",").includes("destaque")).slice(0, 60)
        // this.filtracidade = this.imoveis_destaque.filter(d => d.endereco_cidade === "Santa Maria" && d.destaque === "Destaque").slice(0, 60)
        this.filtracidade = this.shuffle(this.filtracidade)
      })
  },
  mounted() {
    window.addEventListener("keyup", event => {
      if (this.property_search_mode === 0) {
        if (event.keyCode === 13 && this.codigo !== null) {
          var codigox = this.codigo
          window.location.href = "imovel/" + codigox
        }
      }
    })
  },
  methods: {
    alteratiposimoveis (ativar) {
      // console.log(this.property_search.tipo_de_imovel)
      if (ativar === "Residenciais") {
        this.notification_form.tipo_de_imovel = ["Casa", "Apartamento", "Kitnet/Loft/Studio", "Cobertura", "Duplex", "Triplex"]
      }
      if (ativar === "Comerciais") {
        this.notification_form.tipo_de_imovel = ["Terreno", "Pavilhão", "Prédio", "Sala Comercial", "Casa Comercial", "Loja"]
      }
      if (ativar === "Rurais") {
        this.notification_form.tipo_de_imovel = ["Chácara/Sítio", "Campo/Fazenda"]
      }
    },
    seTemtipo (vetor) {
      var igualdade = false
      Object.keys(vetor).forEach(function(item) {
        // console.log(vetor[item].label)
        if (vetor[item].label === "Apartamento") {
          // console.log("executou igualdade")
          // this.property_search.tipo_de_imovel = this.requestarrays("Apartamento, Cobertura, Duplex, Triplex, Kitnet/Loft/Studio")
          igualdade = true
        } else {
          igualdade = false
        }
      })
      return igualdade
    },
    tiposDefinidos () {
      var vetorimoveis = this.property_search.tipo_de_imovel
      // console.log(this.property_search.tipo_de_imovel)
      var apartamentosadds = []
      if (this.seTemtipo(vetorimoveis)) {
        // console.log("agora vai")
        // console.log(this.property_search.tipo_de_imovel)
        // console.log(this.requestarrays("Apartamento"))
        apartamentosadds = this.requestarrays("Apartamento,Cobertura,Duplex,Triplex,Kitnet/Loft/Studio")
        // console.log("aqui os adicionados")
        // console.log(this.property_search.tipo_de_imovel)
        this.property_search.tipo_de_imovel = apartamentosadds
      }
      // this.property_search.tipo_de_imovel = this.property_search.tipo_de_imovel.concat(apartamentosadds)
    },
    replaceSpecialChars(str) {
      str = str.replace(/[ÀÁÂÃÄÅ]/, "A")
      str = str.replace(/[àáâãäå]/, "a")
      str = str.replace(/[ÈÉÊË]/, "E")
      str = str.replace(/[Ç]/, "C")
      str = str.replace(/[ç]/, "c")
      return str.replace(/[^a-z0-9]/gi, " ")
    },
    togglePropertySearchMode(mode) {
      this.property_search_mode = mode
      // console.log(this.property_search_mode)
    },
    fazPesquisa() {
      // console.log(this.property_search.cidade)
      if (this.property_search_mode === 1) {
        var cidadex = this.property_search.cidade
        var quartos = (this.property_search.dormitorios ? this.property_search.dormitorios : 0)
        var bairrox = this.property_search.bairros
        var bairrosx = ""
        Object.keys(bairrox).forEach(function(item) {
          // console.log(item + " = " + bairrox[item].label)
          bairrosx += "" + bairrox[item].label + ","
        })
        var dormitoriosx = ""
        Object.keys(quartos).forEach(function(item) {
          // console.log(item + " = " + bairrox[item].label)
          dormitoriosx += "" + quartos[item].label + ","
        })
        var tipox = this.property_search.tipo_de_imovel
        var tiposx = ""
        Object.keys(tipox).forEach(function(item) {
          // console.log(item + " = " + bairrox[item].label)
          tiposx += "" + tipox[item].label + ","
        })
        window.location.href = "venda?" + "cidade=" + cidadex + "&dormitorios=" + dormitoriosx + "&bairros=" + bairrosx + "&tipo_de_imovel=" + tiposx
      }
      if (this.property_search_mode === 2) {
        var cidadey = this.property_search.cidade
        var quartosy = (this.property_search.dormitorios ? this.property_search.dormitorios : 0)
        var bairroy = this.property_search.bairros
        var bairrosy = ""
        Object.keys(bairroy).forEach(function(item) {
          // console.log(item + " = " + bairrox[item].label)
          bairrosy += "" + bairroy[item].label + ","
        })
        var dormitoriosy = ""
        Object.keys(quartosy).forEach(function(item) {
          // console.log(item + " = " + bairrox[item].label)
          dormitoriosy += "" + quartosy[item].label + ","
        })
        var tipoy = this.property_search.tipo_de_imovel
        var tiposy = ""
        Object.keys(tipoy).forEach(function(item) {
          // console.log(item + " = " + bairrox[item].label)
          tiposy += "" + tipoy[item].label + ","
        })
        window.location.href = "aluguel?" + "cidade=" + cidadey + "&dormitorios=" + dormitoriosy + "&bairros=" + bairrosy + "&tipo_de_imovel=" + tiposy
      }
      if (this.property_search_mode === 0) {
        var codigox = this.codigo
        window.location.href = "imovel/" + codigox
      }
    },
    filtrarCidade() {
      this.filtracidade = this.imoveis_destaque.filter(d => d.endereco_cidade === this.filterhome.cidade && d.tags.split(",").includes("destaque")).slice(0, 24)
    },
    filtrarBairros() {
      this.filtrarosbairros = this.bairrosFromAPI.filter(d => d.cidade === this.property_search.cidade)
    },
    currentDateTime() {
      const current = new Date()
      const date = current.getFullYear() + "-" + (current.getMonth() + 1) + "-" + current.getDate()
      const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds()
      const dateTime = date + " " + time
      return dateTime
    },
    mandaprosleds(form) {
      let formData = new FormData()
      formData.append("fonte", window.location.href)
      formData.append("formulario", "Form. " + form)
      formData.append("utm_medium", this.$route.query.utm_medium)
      formData.append("utm_source", this.$route.query.utm_source)
      formData.append("utm_campaign", this.$route.query.utm_campaign)
      formData.append("utm_id", this.$route.query.utm_id)
      formData.append("utm_term", this.$route.query.utm_term)
      formData.append("utm_content", this.$route.query.utm_content)
      formData.append("data", this.currentDateTime())
      formData.append("obs", "NC")
      axios.post("https://www.cafeimobiliaria.com.br/sistema/api/contatos/create", formData)
    },
    enviarContato(e) {
      let currentObj = this
      this.isTouched = true
      if (!this.$v.$invalid) {
        axios.post(
          "/api/enviarcontato.php", JSON.stringify({
            fonte: "home",
            nome: this.notification_form.nome,
            email: this.notification_form.email,
            telefone: this.notification_form.telefone,
            tipo_de_imovel: this.notification_form.tipo_de_imovel,
            dormitorios: this.notification_form.dormitorios,
            vagas_garagem: this.notification_form.vagas_garagem,
            comentarios: this.notification_form.comentarios
          }))
          .then(function (response) {
            currentObj.output = response.data
          })
          .catch(function (error) {
            currentObj.output = error
          })
        this.isTouched = false
        this.notification_form.nome = ""
        this.notification_form.email = ""
        this.notification_form.telefone = ""
        this.notification_form.tipo_de_imovel = []
        this.notification_form.dormitorios = ""
        this.notification_form.vagas_garagem = ""
        this.notification_form.comentarios = ""
        this.$snotify.info("Mensagem enviada com Sucesso!", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true
        })
        if (this.$route.query.utm_medium) {
          this.mandaprosleds("Home")
        }
        // this.$modal.show("dialog", {
        //   title: "Sua mensagem foi enviada com sucesso",
        //   text: "Em breve entraremos em contato",
        //   buttons: [
        //     {
        //       title: "Fechar",
        //       handler: () => {
        //         this.$modal.hide("dialog")
        //       }
        //     }
        //   ]
        // })
      }
    },
    embreve() {
      this.$snotify.clear()
      // console.log("em breve")
      this.$snotify.info("Em breve!", {
        preventDuplicates: false,
        timeout: 3000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        position: SnotifyPosition.rightCenter,
        type: SnotifyStyle.info
      })
    },
    shuffle(array) {
      var currentIndex = array.length
      var temporaryValue
      var randomIndex
      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
      }
      return array
    },
    requestarrays(requestitens) {
      var itensrecebidos = requestitens.split(",")
      var todosositens = []
      var todosositensrecebidos = itensrecebidos
      Object.keys(todosositensrecebidos).forEach(function(item) {
        // console.log(item + " = " + bairrox[item].label)
        if (todosositensrecebidos[item] !== "") {
          todosositens[item] = {
            id: todosositensrecebidos[item],
            label: todosositensrecebidos[item]
          }
        }
      })
      return todosositens
    }
  },
  validations: {
    notification_form: {
      nome: { required },
      email: { email, required }
    }
  }
}
</script>

<style lang="postcss">
.home {
  &__hero {
    min-height: 480px;

    @media (max-width: 768px) {
      min-height: 540px;
    }
  }

  button {
    &:hover {
      @apply text-primary-100 border-primary-100;
    }
    &:focus {
      background: none;
    }
    &.active {
      @apply border-b-2;
    }
  }
  &__notifications {
    @media (min-width: 768px) {
      .notifications__form {
        background: linear-gradient(to right, #fff, 90%, transparent);
        .c_input + .c_input {
          padding-right: 12px;
        }
      }
      background: url("../assets/img/home__notifications.jpg") no-repeat right
        center;
    }
  }
}
.tooltip {
  position: relative;
  &__item {
    position: absolute;
    min-width: 100px;
    padding: 10px;
    visibility: hidden;
    opacity: 0;
    background: white;
    transition: all .250s cubic-bezier(0, 0, 0.2, 1);
    color: #484848;
    border: 1px solid #cecece;
    border-radius: 3px;
    font-weight: 500;
    box-shadow: 0 2px 1px #bcbcbc;
    z-index: 4;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }
  }
  &__initiator {
    cursor: pointer;
    z-index: 5;
  }
  &[data-direction="left"] {
    .tooltip__initiator:hover ~ .tooltip__item {
      transform: translate3d(0, -50%, 0);
      visibility: visible;
      opacity: 1;
    }
    .tooltip__item {
      top: 50%;
      right: calc(100% + 1em);
      transform: translate3d(15px, -50%, 0);
      &:after {
        top: 50%;
        right: -0.5em;
        transform: translate3d(0, -50%, 0);
        border-width: 0.5em 0 0.5em 0.5em;
        border-color: transparent transparent transparent white;
        -webkit-filter: drop-shadow(1px 2px 1px #bcbcbc);
        filter: drop-shadow(1px 2px 1px #bcbcbc);
      }
    }
  }
  &[data-direction="bottom"] {
    .tooltip__initiator:hover ~ .tooltip__item {
      transform: translate3d(-50%, 0, 0);
      visibility: visible;
      opacity: 1;
    }
    .tooltip__item {
      top: calc(100% + 1em);
      left: 50%;
      transform: translate3d(-50%, -15px, 0);
      &:after {
        top: -0.5em;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        border-width: 0 0.5em 0.5em 0.5em;
        border-color: transparent transparent white transparent;
        -webkit-filter: drop-shadow(1px 2px 1px #bcbcbc);
        filter: drop-shadow(1px -1px 1px #bcbcbc);
      }
    }
  }
  &[data-direction="top"] {
    .tooltip__initiator:hover ~ .tooltip__item {
      transform: translate3d(-50%, 0, 0);
      visibility: visible;
      opacity: 1;
    }
    .tooltip__item {
      bottom: calc(100% + 1em);
      left: 50%;
      transform: translate3d(-50%, -15px, 0);
      &:after {
        bottom: -0.5em;
        left: 50%;
        transform: translate3d(-50%, 0, 0) rotate(180deg);
        border-width: 0 0.5em 0.5em 0.5em;
        border-color: transparent transparent white transparent;
        -webkit-filter: drop-shadow(1px 2px 1px #bcbcbc);
        filter: drop-shadow(1px -1px 1px #bcbcbc);
      }
    }
  }
}
.fa.fa-info-circle {
  font-size: 38px;
  color: #21606b;
}
#comod-home .c-checkbox {
  padding-left: 1.3rem !important;
  padding-right: 1.3rem !important;
}
.home__notifications .notifications__form {
  background: none !important;
}
.img-campanha {
  transition: transform .2s;
}
.img-campanha:hover {
  transform: scale(1.1);
}
.link-campanha img {
  border-radius: 10px;
  box-shadow:0 2px 2px #666;
  object-fit: cover;
  position: relative;
}
.link-campanha img:hover {
  box-shadow:0 4px 4px #666;
  transform: scale(1.01);
  /* border: 1px solid white; */
}
</style>
