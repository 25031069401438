<template>
  <transition name="fade" appear>
    <div class="c-modal">
      <div class="c-modal__wrapper">
        <button
          type="button"
          class="interest-modal__close"
          title="Fechar"
          @click="$emit('close')"
        >
          <font-awesome-icon icon="times" />
        </button>
        <slot name="body" />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  components: {}
}
</script>

<style lang="postcss">
.c-modal {
  @apply fixed top-0 left-0 w-full h-full z-30 flex justify-center items-center overflow-auto;
  background: rgba(0, 0, 0, 0.5);

  p {
    @apply mb-2;
  }

  &__wrapper {
    @apply relative rounded bg-white flex p-8 bg-gray-200 flex flex-col items-center justify-center;
    width: 24em;
    max-width: 100vw;
  }

  &__close {
    @apply absolute top-0 right-0 p-4 px-5;

    &:focus {
      background: transparent;
      @apply text-primary-500;
    }
  }
}
</style>
