<template>
  <figure-card class="testimonial-card">
    <template #title>
      <div class="rounded-icon">
        <font-awesome-icon icon="quote-left"></font-awesome-icon>
      </div>
    </template>
    <template #content>
      <p class="mt-6">
        <slot name="content"></slot>
      </p>
      <div class="rounded-full overflow-hidden w-16 h-16 inline-flex mt-6">
        <img
          class="object-cover"
          :src="require(`@/assets/img/${img}.jpg`)"
          alt="Cliente"
        />
      </div>
      <p class="text-black">{{ name }}</p>
    </template>
  </figure-card>
</template>

<script>
import FigureCard from "./FigureCard.vue"
export default {
  components: { FigureCard },
  props: {
    name: {
      type: String,
      default: "Cliente"
    },
    img: {
      type: String,
      default: "imovel"
    }
  }
}
</script>

<style></style>
