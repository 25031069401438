<template>
  <login-form>
    <template #form="props">
      <h2 class="text-lg font-medium mb-2">É bom te ver por aqui!</h2>
      <p class="text-sm mb-6">
        Acesse sua conta para ter acesso às vantagens que a Café oferece!
      </p>
      <div class="v-group">
        <c-input
          v-model="form.email"
          label="E-mail"
          type="email"
          placeholder="Seu email"
          :class="{ invalid: formValidation && $v.form.email.$invalid }"
        />
        <c-input
          v-model="form.password"
          label="Senha"
          type="password"
          placeholder="Sua senha"
          :class="{ invalid: formValidation && $v.form.password.$invalid }"
        />
        <c-button class="primary w-full" @click="doLogin(props)">
          Entrar em sua conta
        </c-button>
        <router-link
          to="cadastrar"
          class="c-link font-medium block text-center text-sm"
        >
          Quero me cadastrar
        </router-link>
      </div>
    </template>
  </login-form>
</template>

<script>
import { post } from "axios"
import CInput from "@/components/UI/CInput.vue"
import CButton from "@/components/UI/CButton.vue"
import LoginForm from "../components/LoginForm.vue"
import required from "vuelidate/lib/validators/required"

export default {
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Login",
    meta: [
      {
        name: "description",
        content: "Café Inteligência Imobiliária atua em Santa Maria/RS e região, imóveis santa maria, Imobiliarias santa maria, Login"
      }
    ]
  },
  components: {
    CInput,
    CButton,
    LoginForm
  },
  data() {
    return {
      formValidation: false,
      form: {
        email: "",
        password: ""
      }
    }
  },
  validations: {
    form: {
      email: { required },
      password: { required }
    }
  },
  beforeCreate() {
    /* Só para demonstrar o indicador de "logado" */
    localStorage.setItem("isLogged", 0)
  },
  methods: {
    doLogin({ form }) {
      if (this.$v.form.$invalid || !form.reportValidity()) {
        this.formValidation = true
        return false
      }

      /* Faz requisição para logar */
      post("login", this.form)
        .then(() => {
          /* Salva o token de acesso no localStorage//sessionStorage//cookie */
          this.$router.push("pagina-do-usuario")
        })
        .catch(response => {
          alert("Erro! Mas para fins de exemplo o usuário será exibido como logado!")
          /* Só para demonstrar o indicador de "logado" */
          localStorage.setItem("isLogged", 1)
          this.$router.push({ name: "home" })
        })
    }
  }
}
</script>
