<template>
  <div class="property__gallery" ref="propertyWrapper">
    <span class="text-center" v-if="permuta === true" style="position: absolute; z-index: 10;">
        <img :src="require(`@/assets/img/Selo-permuta-site.png`)" alt="" width="250">
    </span>
    <carousel
      class="property__carousel"
      :perPageCustom="perPageCustom"
      :perPage="perPage"
      :paginationEnabled="false"
      :navigationEnabled="true"
      :loop="true"
      navigationNextLabel="<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='angle-right' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' class='svg-inline--fa fa-angle-right fa-w-8'><path fill='currentColor' d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z' class=''></path></svg>"
      navigationPrevLabel="<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='angle-left' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' class='svg-inline--fa fa-angle-left fa-w-8'><path fill='currentColor' d='M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z' class=''></path></svg>"
    >
      <div class="indice-galeria c-button-2 primary">
        <label v-if="type === 'video'">{{ slides.length }} video(s)</label>
        <label v-else>{{ slides.length }} fotos</label>
      </div>
      <c-button
        v-if="type === 'video'"
        icon="camera"
        class="px-0 md:w-auto md:px-6"
        @click="localvideo('photos')"
        style="z-index: 10; position: absolute !important;bottom:20%;left:1%;padding: 10px 10px"
      >
        <span class="ml-2">Voltar às Fotos</span>
      </c-button>
      <c-button
        v-else-if="type !== 'video' && propertyVideos"
        icon="video"
        class="px-0 c-button md:w-auto md:px-6"
        @click="localvideo('videos')"
        style="position: absolute !important;left: 1%;top:4%;padding: 10px 10px"
      >
        <span class="ml-2">Confira os Vídeos</span>
      </c-button>
      <slide
        class="property__slide"
        v-for="(slide, index) in slides"
        :key="index"
        :style="isMobile ? 'height: 400px !important;' : 'height: 500px !important;'"
      >
        <!-- <center> -->
        <iframe
          class="property__video"
          v-if="type === 'video'"
          :src="slide"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          height="auto"
          allowfullscreen
        ></iframe>
        <!-- <div v-else class="contemimagem"> -->
        <img v-else :src="slide" aria-hidden="true" alt="Imovel" @click="showImage = index" style="cursor:zoom-in"/>
        <!-- </center> -->
        <!-- </div> -->
        <!-- <div v-if="index === 0" class="hidden contador-galeria c-button md:block" @click="showImage = index"> -->
          <!-- <label>{{ index + 1 }} / {{ slides.length }}</label> -->
          <!-- <label><font-awesome-icon icon="search" /> Ampliar</label> -->
        <!-- </div> -->
      </slide>
    </carousel>
    <!-- v-if="!isMobile" -->
    <CoolLightBox
      :items="slides"
      :index="showImage"
      :loop="true"
      @close="showImage = null">
    </CoolLightBox>
    <!-- <Lightbox
      :visible="showImage !== false"
      :imgs="slides"
      :index="Number(showImage)"
      @hide="showImage = false"
      :moveDisabled="true"
    /> -->
    <!-- moveDisabled -->
    <div class="contador-galeria c-button primary md:block pulse" style="pointer-events: none;cursor:pointer !important;" v-if="type !== 'video'">
      <!-- <label>{{ index + 1 }} / {{ slides.length }}</label> -->
      <label><font-awesome-icon icon="search" /> Ampliar</label>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel"
import CButton from "../components/UI/CButton.vue"
// import Lightbox from "vue-easy-lightbox"

export default {
  components: {
    Carousel,
    Slide,
    CButton
    // Lightbox
  },
  props: {
    slides: {
      type: Array,
      default() {
        return []
      }
    },
    type: {
      type: String,
      default: "img"
    },
    propertyVideos: {
      type: Boolean,
      default: false
    },
    permuta: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    perPage: {
      type: Number,
      default: 3
    }
  },
  computed: {
    perPageCustom() {
      return this.type === "img" ? [[ 0, 1 ], [ 640, 2 ], [ 768, 2 ], [1024, 3], [1280, 3]] : [0, 1]
    }
    // sizetela () {
    //   if (this.isMobile) {
    //     return "400px"
    //   } else {
    //     return "500px"
    //   }
    // }
  },
  data() {
    return {
      showImage: false,
      sizetela: "500px"
    }
  },
  created() {
    if (this.isMobile) {
      this.sizetela = "400px"
    } else {
      this.sizetela = "500px"
    }
  },
  methods: {
    localvideo(ativa) {
      this.$parent.handlePropertyView(ativa)
      // console.log("OI")
    }
  }
}
</script>

<style lang="postcss" scoped>
.property {
  &__slide {
    @apply flex justify-center items-center;
    height: 400px;
    /* max-width: fit-content; */
    width: 100%;
    & + & {
      @apply ml-1;
    }
    background-color: lightgray !important;
    border-left:1px solid white ;
    border-right:1px solid white ;
    /* background-color: #000 !important; */
      /* border-left: 25px solid #fff; */
    /* .contemimagem {
      background-color: #000;
      width: 100%;

    } */
    box-sizing: border-box;
    img {
      @apply object-cover;
      /* width: fit-content; */
      /* max-height: 100%; */
      width: 100%;
      height: 400px;
      /* height: 340px;
      max-height: 340px;
      min-height: 340px; */
      object-position: center;
      /* object-fit: contain; */
      /* padding: 1%; */
      /* height: 340px;
      min-height: 340px;
      height: 140; */
      /* width: auto;
      height: auto; */
    }
    iframe {
      @apply object-cover;
      width: 100% !important;
      position: absolute;
      max-height: 100%;
      left: 0px;
      background-color: #000;
      height: 500px;
    }
  }
  .property__slide + .property__slide {
    margin-left: 0 !important;
  }
  .toolbar-btn__rotate {
    display: none;
  }
  .btn__prev, .btn__next, .btn__close {
    background-color:#4062d1 !important;
    border-color: #4062d1;
    color: #fff;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    white-space: nowrap;
    border-radius: 20px;
  }
}
.contador-galeria {
  z-index: 10 !important;
  /* background-color: white !important; #4062d1 !important; */
  /* color: gray !important; */
  font-size: 12px;
  position: absolute !important;
  margin-top: -43px;
  /* margin-left: 0px; */
  left: 40%;
  object-position: center;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 12px;
  width: auto;
  /* text-align: center; */
  /* text-weigth: bolder; */
  cursor: ne-resize !important;
  width: 100px;
    /* text-shadow: 2px 0 0 #000, -2px 0 0 #000fff, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000; */
}
.contador-galeria label {
  cursor: ne-resize !important;
}
.indice-galeria {
  z-index: 10 !important;
  position: absolute !important;
  padding: 5px 12px;
  /* background-color: #4776bf; */
  /* color: white; */
  margin-top: 340px;
  margin-left: 15px;
  width: 100px;
}
/* .c-button + * {
    margin-left: 0rem;
} */
.c-button-2 {
  text-align: center;
    border-radius: 9999px;
    background-color: #fff;
    border-width: 2px;
    border-style: solid;
    border-color: #e2e8f0;
    color: #718096;
    font-size: 0.875rem;
    font-weight: 500;
    white-space: nowrap;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    -webkit-transition-property: background-color, color, border-color;
    transition-property: background-color, color, border-color;
    -webkit-transition-duration: 125ms;
    transition-duration: 125ms;
}
.c-button-2.primary {
  background-color: #1F362E;
    border-color: #1F362E;
    color: #fff;
}
.cool-lightbox .cool-lightbox__slide img {
    /* width: 100% !important;
    height: 100% !important; */
    max-width: 100% !important;
    max-height: 100% !important;
}
.cool-lightbox.cool-lightbox--is-zooming .cool-lightbox__slide img {
    width: 100% !important;
    height: auto !important;
    max-width: none !important;
    max-height: none !important;
    cursor: all-scroll !important;
}
.pulse {
    position: absolute;
    /* top: 35%;
    left: 40%;
    width: 100px;
    transform: translate(-505, -50%);
    height: 100px; */
    background: #1F362E;
    border: 2px solid #1F362E;
    /* border-radius: 50%; */
    box-sizing: border-box;
    /* box-shadow: 0 0 0 36px #45237a, 0 0 0 40px #56ff00; */
}
.pulse:before, .pulse:after {
    content: '';
    position: absolute;
    left: -20px;
    top: -10px;
    right: -20px;
    bottom: -10px;
    border: 1px solid goldenrod;
    border-radius: 9999px;
    animation: animate 8s linear infinite;
    animation-delay: 4s;
}
.pulse:after {
    animation-delay: 8s;
}
@keyframes animate {
    0% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(0.7);
    }
}
</style>
