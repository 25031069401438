<template>
  <div class="form-slider">
    <div class="form-slider__title">
      <img
        v-if="!disableImg.includes(normalizedStep)"
        class="form-slider__img"
        :src="require(`@/assets/img/${img}.svg`)"
        :alt="title"
        aria-hidden="true"
      />
      <p class="form-slider__small">{{ title }}</p>
      <h2 class="form-slider__big">{{ steps[currentStep] }}</h2>
    </div>
    <transition name="fade-slide" mode="out-in">
      <template v-for="(step, index) in steps">
        <div
          class="form-slider__inputs"
          v-if="currentStep === index"
          :key="index"
        >
          <slot
            :name="`step-${index + 1}`"
            :advanceStep="advanceStep"
            :retreatStep="retreatStep"
          ></slot>
        </div>
      </template>
    </transition>
    <div class="form-slider__nav flex">
      <c-button
        icon="caret-left"
        :disabled="currentStep === 0"
        @click="retreatStep"
        v-if="!disableNav.includes(normalizedStep)"
      >
        <span class="hidden md:inline">Voltar</span>
      </c-button>
      <div class="nav__pagination m-auto p-3">
        <strong>{{ normalizedStep }}</strong>
        <span>/ {{ steps.length }}</span>
      </div>
      <c-button
        class="primary"
        @click="emitAdvanceStep"
        :disabled="currentStep === steps.length - 1"
        v-if="!disableNav.includes(normalizedStep)"
      >
        <span class="hidden md:inline">Próximo</span>
        <font-awesome-icon
          icon="caret-right"
          class="ml-1 mt-1"
        ></font-awesome-icon>
      </c-button>
    </div>
  </div>
</template>

<script>
import CButton from "./UI/CButton.vue"
import EventBus from "../helpers/EventBus"

export default {
  components: { CButton },
  props: {
    steps: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ""
    },
    img: {
      type: String,
      default: ""
    },
    disableImg: {
      type: Array,
      default() {
        return []
      }
    },
    disableNav: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      currentStep: 0
    }
  },
  created() {
    EventBus.$on("globalAdvanceStep", () => this.advanceStep())
  },
  destroyed() {
    EventBus.$off("globalAdvanceStep", () => this.advanceStep())
  },
  methods: {
    /* Ao clicar no botão Proximo avança o passo */
    emitAdvanceStep() {
      this.$emit("advanceStep", {
        currentStep: this.normalizedStep,
        advanceStep: this.advanceStep
      })
    },
    /* Avança o passo */
    advanceStep() {
      this.currentStep++
    },
    /* Volta o passo */
    retreatStep() {
      this.currentStep--
    }
  },
  computed: {
    /* Step como é exibido pro usuário */
    normalizedStep() {
      return this.currentStep + 1
    }
  }
}
</script>

<style lang="postcss">
.form-slider {
  @apply m-auto;
  max-width: 640px;

  &__title {
    @apply m-auto text-center;
  }

  &__img {
    max-width: 250px;
    @apply inline-block;
  }

  &__small {
    @apply mt-4 mb-2 text-gray-600 uppercase;
  }

  &__big {
    @apply text-2xl;
  }

  &__inputs {
    @apply mt-8;
  }

  &__nav {
    @apply items-center justify-between mt-8;

    .nav__pagination {
      @apply text-gray-500;
      strong {
        @apply text-gray-800 text-xl mr-1;
      }
    }
  }
}
</style>
