<template>
  <c-card-wrapper
    class="figure-card px-4 py-12 text-center"
    :class="{ 'no-card': noCard }"
  >
    <div class="h-24 mx-auto" v-if="img">
      <img
        class="h-full inline-block"
        :alt="`${$slots.title} ilustração`"
        :src="require(`@/assets/img/${img}.svg`)"
        aria-hidden="true"
      />
    </div>
    <h3 class="text-xl m-5 font-medium"><slot name="title" /></h3>
    <p class="text-sm text-gray-600" :class="`text-${textAlign}`">
      <slot name="content" />
    </p>
  </c-card-wrapper>
</template>

<script>
import CCardWrapper from "./UI/CCardWrapper.vue"
export default {
  components: { CCardWrapper },
  props: {
    img: {
      type: String,
      default: null
    },
    noCard: {
      type: Boolean,
      default: false
    },
    textAlign: {
      type: String,
      default: "center"
    }
  }
}
</script>

<style lang="postcss">
.figure-card {
  &.no-card {
    box-shadow: none;
  }
}
</style>
/* :src="`@/assets/img/${img}.svg`" */
