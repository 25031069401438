<template>
  <aside class="property-values">
    <div class="fixed bottom-0 left-0 z-20 w-full mb-4 text-center md:hidden">
      <!-- fixed z-20 bottom-0 left-0 mb-4 w-full text-center md:hidden -->
      <!-- <c-button class="w-64 primary" @click="$emit('toggleModal', 'interest')">
        Tenho interesse
      </c-button> -->
    </div>
    <c-card-wrapper class="relative p-6 mt-4">
      <h3 class="mb-2 text-lg font-medium" v-if="contrato === 'Compra'">Venda
        <div class="" style="float: right;">
          <small class="bg-green-pv" v-if="mobiliado === 1">Mobiliado</small>
          <small class="bg-green-pv" v-if="mobiliado === 2">Semimobiliado</small>
        </div>
        <div class="" style="float: right" v-if="status === 'Em construção'">
          <small class="bg-blue-pv">Em construção</small>
        </div>
        <div class="" style="float: right" v-if="exclusividade">
          <small class="bg-blue-pv">Exclusivo</small>
        </div>
      </h3>
      <h3 class="mb-2 text-lg font-medium" v-if="contrato === 'Locação'">Locação
        <div class="" style="float: right">
          <small class="bg-green-pv" v-if="mobiliado === 1">Mobiliado</small>
          <small class="bg-green-pv" v-if="mobiliado === 2">Semimobiliado</small>
        </div>
        <div class="" style="float: right" v-if="status === 'Em construção'">
          <small class="bg-blue-pv">Em construção</small>
        </div>
        <div class="" style="float: right" v-if="exclusividade">
          <small class="bg-blue-pv">Exclusivo</small>
        </div>
      </h3>
      <h3 class="mb-2 text-lg font-medium" v-if="contrato === 'Compra,Locação'">Venda/Locação
        <div class="" style="float: right">
          <small class="bg-green-pv" v-if="mobiliado === 1">Mobiliado</small>
          <small class="bg-green-pv" v-if="mobiliado === 2">Semimobiliado</small>
        </div>
        <div class="" style="float: right" v-if="status === 'Em construção'">
          <small class="bg-blue-pv">Em construção</small>
        </div>
        <div class="" style="float: right" v-if="exclusividade">
          <small class="bg-blue-pv">Exclusivo</small>
        </div>
      </h3>
      <div class="clearfix"></div>
      <ul class="text-xs property__price-table lg:text-sm">
        <li class="flex flex-wrap divider text-primary-500">
          <span v-if="contrato === 'Locação'">Aluguel</span>
          <span v-if="contrato === 'Compra'">Venda</span>
          <span v-if="contrato === 'Compra,Locação'">Venda</span>
          <!-- <strong v-if="valor_venda > 0">R$ {{ valor_venda }}</strong>
          <strong v-if="valor_locacao > 0">R$ {{ valor_locacao }}</strong> -->
          <strong>{{ propertyPrice }}</strong>
        </li>
        <li class="flex flex-wrap divider text-primary-500" v-if="contrato === 'Compra,Locação'">
          <span>Locação</span>
          <strong>{{ formatavalor(valor_locacao) }}</strong>
        </li>
        <li class="flex flex-wrap divider" v-if="valor_iptu > 0">
          <span>IPTU</span>
          <strong>{{ formatavalor(valor_iptu) }}</strong>
        </li>
        <li v-if="valor_condominio > 0 && contrato === 'Compra'"  class="flex flex-wrap divider">
          <span>Condomínio</span>
          <strong>{{ formatavalor(valor_condominio) }}</strong>
        </li>
        <li v-if="valor_condominio > 0 && contrato !== 'Compra'" class="flex flex-wrap divider">
          <span>Condomínio</span>
          <strong>{{ formatavalor(valor_condominio) }}</strong>
        </li>
        <li class="flex flex-wrap divider" v-if="contrato === 'Locação'" style="border-bottom: 1px dotted black">
          <span>
            Seguro Incêndio
            <c-info
              title="Seguro Incêndio"
              content="Este seguro é obrigatório e resguarda o inquilino no caso de um acidente com fogo, conforme art. 22, VIII, da Lei do Inquilinato.
              * O valor projetado é variável, conforme as características do imóvel."
            />
          </span>
          <strong>{{ formatavalor(((valor_locacao * 0.11) / 12).toFixed(2)) }}</strong>
        </li>
        <li class="flex flex-wrap divider" v-if="contrato === 'Locação'" style="border-bottom: 1px dotted black">
          <strong>
            Total ao mês
            <!-- <c-info
              title="Adicional"
              content="Valor total do Aluguel"
            /> -->
          </strong>
          <strong>{{ formatavalor(Number(valortotal + ((Number(valor_locacao) * 0.11) / 12)).toFixed(2)) }}</strong>
        </li>
        <li class="flex flex-wrap divider" v-if="contrato === 'Locação'">
          <strong>
            Garantias Locatícias
          </strong>
        </li>
        <li class="flex flex-wrap divider" v-if="contrato === 'Locação'">
          <span>
            Seguro-Fiança
            <c-info
              title="Adicional z-30"
              icon=" &#x1F609;"
              content="Chega de constrangimento na hora de locar seu imóvel, essa é a melhor opção para você alugar com ZERO de burocracia, e ainda, pagando muito pouco por isso. O resultado da avaliação é na hora, em casos de reprovação, é possível uma reanálise com prazo máximo de 24h. Já imaginou? Alugue agora!"
            />
          </span>
          <strong v-if="tipo === 'Comercial'">
            <label class="text-primary-500">+</label> {{ formatavalor((valor_locacao * 0.099).toFixed(2)) }} /mês
          </strong>
          <strong v-else>
            <label class="text-primary-500">+</label> {{ formatavalor((valor_locacao * 0.09).toFixed(2)) }} /mês
          </strong>
        </li>
        <li class="flex flex-wrap divider" v-if="contrato === 'Locação'">
          <span>
            Credpago
            <c-info
              title="Adicional"
              link="http://www.credpago.com.br"
              content="Nesta modalidade você paga 8% do valor do aluguel + encargos, para contratar o Seguro da Credpago. Análise simplificada, sendo uma boa alternativa. Quer saber sobre essa modalidade? Acesse"
            />
          </span>
          <strong><label class="text-primary-500">+</label> {{ formatavalor((valortotal * 0.08).toFixed(2)) }} /mês</strong>
        </li>
        <!-- <li class="flex flex-wrap divider" v-if="contrato === 'Locação'">
          <span>Total</span>
          <strong>{{ formatavalor(valortotal) }}</strong>
        </li> -->
        <li class="flex flex-wrap divider" v-if="contrato !== 'Locação'">
          <span>Financiável</span>
          <strong v-if="financiavel == 1">Sim</strong>
          <strong v-else>Consulte</strong>
        </li>
      </ul>
      <br>
      <!-- <h3 class="mb-2 text-lg" v-if="contrato === 'Locação'" style="font-size: 16px;font-weight: 500;">Alugue SEM FIADOR e SEM BUROCRACIA
        <button @click="mostrataxas = !mostrataxas">
          <span v-if="mostrataxas"> <font-awesome-icon icon="chevron-up" /></span>
          <span v-else> <font-awesome-icon icon="chevron-down" /></span>
        </button>
        <br />
        <br />
      </h3> -->
      <!-- <h3 class="mb-2 text-lg font-medium" v-else>Impostos e taxas -->
      <h3 class="mb-2 text-lg font-medium" v-if="contrato !== 'Locação'">Impostos e taxas
        <button @click="mostrataxas = !mostrataxas">
          <span v-if="mostrataxas"> <font-awesome-icon icon="chevron-up" /></span>
          <span v-else> <font-awesome-icon icon="chevron-down" /></span>
        </button>
        <br />
        <br />
      </h3>
      <ul v-if="mostrataxas" class="text-xs property__price-table lg:text-sm">
        <li class="flex flex-wrap divider" v-if="contrato !== 'Locação'">
          <span>
            ITBI
            <c-info title="ITBI" content="ITBI = O Imposto de Transmissão de Bens Imóveis é pago pelo adquirente no momento da compra do imóvel. A alíquota e cobrança é gerada pela prefeitura municipal. O valor projetado é aproximado e varia de acordo com a sua condição de pagamento , sendo 2,15% à vista e 1,0% para financiamento." />
          </span>
          <strong v-if="valor_venda > 0">{{ formatavalor((valor_venda * 0.0215).toFixed(2)) }}</strong>
          <strong v-else>Consulte-nos</strong>
        </li>
        <li class="flex flex-wrap divider" v-if="contrato !== 'Locação'">
          <span>
            Escritura e Registro
            <c-info
              title="Escritura e Registro"
              content="Este valor é composto pelas taxas de Escritura e Cartório de Imóveis. Servem para oficializar a transferência e propriedade do imóvel objeto da compra. O valor projetado é aproximado e varia de acordo com as particularidades do negócio e se é sua primeira compra."
            />
          </span>
          <strong v-if="valor_venda > 0">{{ formatavalor((valor_venda * 0.02).toFixed(2)) }}</strong>
          <strong v-else>Consulte-nos</strong>
        </li>
      </ul>
      <!-- <div
        class="flex justify-around mt-3 mb-3 text-xs font-medium text-center text-gray-600"
      >
        <span>
          <a href="tel:5533078072" target="blanck">
            <font-awesome-icon icon="phone" />
            (55) 3307-8072
          </a>
        </span>
        <a
          href="http://api.whatsapp.com/send?phone=5599128466"
          class="text-primary-400"
          target="blanck"
        >
          <font-awesome-icon :icon="['fab', 'whatsapp']" />
          Whatsapp
        </a>
      </div> -->
      <div class="items-center w-full mt-3 lg:flex lg:group" v-if="corretor.length != 0 && contrato !== 'Locação'" style="display: inline-block;">
        <div class="w-full mb-3 lg:flex lg:group">
          <div class="text-center lg:w-1/2" style="">
            <img :src="corretor.avatar" alt="" style="width: 150px; height: 150px !important; object-fit: cover; border-radius: 50%;display: inline-flex !important;">
          </div>
          <div class="text-center lg:w-1/2" style="">
            <ul class="mt-8 mb-5" style="">
              <li><h4 style="font-weight: bolder">{{ nomesobre(corretor.nome) }}</h4></li>
              <li>{{ corretor.cargo }}</li>
              <!-- <li>{{ corretor.email }}</li> -->
              <li v-if="!donos.includes(corretor.nome)"><a style="color: #1F362E" :href="'tel:' + corretor.telefone" target="blanck" @click="mandaprosleds(corretor.nome, 'Contato')">
                <font-awesome-icon :icon="['fa', 'phone']" /> {{ acceptNumber(corretor.telefone) }}
              </a></li>
              <!-- <li v-if="!administradores.includes(corretor.cargo)" class="inline-block">
                Outros
              </li> -->
            </ul>
          </div>
        </div>
        <div class="w-full">
          <!--
            + tiraonove(corretor.telefone)
            v-if="administradores.includes(corretor.cargo)"
            :href="'http://api.whatsapp.com/send?phone=555599128466&text=Gostaria de mais informações sobre o imóvel Cód. CE' + codigo" target="blanck"
          -->
          <button
            class="block w-full mt-0 mb-3 c-button success"
            @click="mandaprosleds(corretor.nome, 'Whats')"
            style="border-radius: 20px !important;"
          >
            <font-awesome-icon :icon="['fab', 'whatsapp']" /> Contate o Especialista
          </button>
        </div>
      </div>
      <div class="items-center w-full mt-3 lg:flex lg:group" v-if="contrato === 'Locação'" style="display: inline-block;">
        <div class="w-full">
          <button
            class="block mt-0 mb-3 c-button success"
            @click="mandaprosleds(corretor.nome, 'Whats')"
            style="border-radius: 20px !important;"
          >
            <font-awesome-icon :icon="['fab', 'whatsapp']" /> Contate o Especialista
          </button>
        </div>
      </div>
      <div class="w-full lg:flex lg:group">
        <c-button class="w-full mb-2 primary lg:w-1/2" @click="$emit('toggleModal', 'interest')" style="border-radius: 20px !important;margin: 2px;">
          Tenho interesse
        </c-button>
        <c-button class="w-full mb-2 primary lg:w-1/2" icon="calendar" @click="$emit('toggleModal', 'calendar')" style="border-radius: 20px !important;margin: 2px;">
          Agendar visita
        </c-button>
      </div>
    </c-card-wrapper>
    <!-- <emoji
      set="messenger"
      emoji="shrug"
      :size="24"
      :fallback="emojiFallback"
    /> -->
    <!-- <picker :custom="customEmojis" /> -->
  </aside>
</template>

<script>
import CButton from "./UI/CButton.vue"
import CInfo from "./UI/CInfo.vue"
import CCardWrapper from "./UI/CCardWrapper.vue"
// import axios from "axios"
// import { Picker } from "emoji-mart-vue"
export default {
  components: { CCardWrapper, CInfo, CButton },
  data () {
    return {
      mostrataxas: false,
      administradores: [
        "Diretor Financeiro (CFO)",
        "Gestor de expansão e Novos Projetos",
        "Gestor de expansão e Novos Projetos.",
        "Gerente de locações",
        "Auxiliar Administrativo - Locações (Café)",
        "Agenciador",
        "Agente imobiliario",
        "Agente Imobiliario",
        null
      ],
      donos: [
        "Fábio Cipolatto",
        "Eduardo Souto de Andrade",
        "Fabiano Comoretto",
        "Jonatas Almeida da Silva"
      ],
      imagemdecomprar: require("../assets/img/home.svg"),
      imagemdealugar: require("../assets/img/icon-whatsapp-png-27.jpg"),
      mostraform: false,
      whatscontato: "Contato",
      whatsfone: "(55) 99912-8466",
      whatssend: "55999128466",
      whatsfoto: require("../assets/img/icon-whatsapp-png-27.jpg"),
      whatsfotopermanente: require("../assets/img/icon-whatsapp-png-27.jpg"),
      wtvenda: {
        whatsnome: "Vendas",
        whatsfone: "(55) 99912-8466",
        whatsfoto: require("../assets/img/cafeimobiliariafundobranco.png"),
        whatssend: "5599128466"
      },
      wtloca1: {
        whatsnome: "Rafael Castro",
        whatsfone: "(55) 98125-0745",
        whatsfoto: require("../assets/img/rafael.png"),
        whatssend: "5581250745"
      },
      wtloca2: {
        whatsnome: "Marciane Pereira",
        whatsfone: "(55) 98127-4958",
        whatsfoto: require("../assets/img/marciane.png"),
        whatssend: "5581274958"
      },
      wtloca3: {
        whatsnome: "Carol",
        whatsfone: "(55) 98127-4958",
        whatsfoto: require("../assets/img/wh_carol.png"),
        whatssend: "5581274958"
      },
      wtloca4: {
        whatsnome: "Gabriella",
        whatsfone: "(55) 98125-7650",
        whatsfoto: require("../assets/img/wh_gabriela.png"),
        whatssend: "5581257650"
      },
      ativowhats: true,
      isTouched: false,
      whatsform: {
        nome: "",
        phone: "",
        // mail: "",
        comentarios: "Gostaria de ser atendido(a)."
      },
      logonovo: require("../assets/img/logos/1.png")
    }
  },
  props: {
    valor_venda: { type: [String, Number] },
    valor_locacao: { type: [String, Number] },
    valor_iptu: { type: [String, Number] },
    valor_condominio: { type: [String, Number] },
    financiavel: { type: Number },
    contrato: { type: String },
    exclusividade: { type: Boolean },
    mobiliado: { type: Number },
    status: { type: String },
    tipo: { type: String },
    codigo: { type: String },
    valortotal: { type: Number },
    corretor: { type: [] }
  },
  computed: {
    isMobile() {
      return this.windowWidth < 1024
    },
    propertyPrice() {
      var valor
      var retorno
      const formatter = Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      })
      if (this.valor_venda && this.valor_venda > 0 && this.valor_venda !== null) {
        valor = this.valor_venda
      } else {
        if (this.valor_locacao && this.valor_locacao > 0 && this.valor_locacao !== null) {
          valor = this.valor_locacao
        } else {
          valor = 0
        }
      }
      if (valor > 0) {
        retorno = formatter.format(valor)
      } else {
        retorno = "Consulte-nos"
      }
      return retorno
    }
  },
  methods: {
    formatavalor(valor) {
      const formatter = Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      })
      return formatter.format(valor)
    },
    acceptNumber(valorrecebido) {
      var x = valorrecebido.replace(/\D/g, "").match(/(\d{0,2})(\d{0,2})(\d{0,1})(\d{0,4})(\d{0,4})/)
      valorrecebido = "(" + x[2] + ") " + x[3] + x[4] + "-" + x[5]
      return valorrecebido
    },
    // Retira o nono dígito dos telefones, para a API do whatsapp
    tiraonove (numero) {
      var x = numero.replace(/\D/g, "").match(/(\d{0,2})(\d{0,2})(\d{0,1})(\d{0,4})(\d{0,4})/)
      numero = x[1] + x[2] + x[4] + x[5]
      return numero
    },
    nomesobre(nome) {
      var arr = nome.split(" ")
      nome = arr[0] + " " + (arr[1].length > 2 ? arr[1] : arr[2])
      return nome
    },
    currentDateTime() {
      const current = new Date()
      const date = current.getFullYear() + "-" + (current.getMonth() + 1) + "-" + current.getDate()
      const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds()
      const dateTime = date + " " + time
      return dateTime
    },
    mandaprosleds(corretor, form) {
      this.$modal.show("modalwhats")
      /**
      let formData = new FormData()
      formData.append("fonte", window.location.href)
      formData.append("formulario", "Form " + form + ": " + this.nomesobre(corretor))
      formData.append("utm_medium", "site")
      formData.append("utm_source", "site")
      formData.append("utm_campaign", "site")
      // formData.append("data", Date(Date.now()).toLocaleString("nl-NL"))
      formData.append("data", this.currentDateTime())
      formData.append("obs", "Registro de Atividade em " + this.codigo)
      // axios.post("http://localhost/sistema/web/api/contatos/create", formData)
      axios.post("https://www.cafeimobiliaria.com.br/sistema/api/contatos/create", formData)
      // Anteriormente era por aqui (8 de Nov de 2023)
      if (this.$route.query.utm_medium) {
         let formData = new FormData()
         formData.append("fonte", window.location.href)
         formData.append("formulario", "Form. " + form + ": Imóvel " + this.codigo)
         formData.append("utm_medium", this.$route.query.utm_medium)
         formData.append("utm_source", this.$route.query.utm_source)
         formData.append("utm_campaign", this.$route.query.utm_campaign)
         formData.append("utm_id", this.$route.query.utm_id)
         formData.append("utm_term", this.$route.query.utm_term)
         formData.append("utm_content", this.$route.query.utm_content)
         formData.append("data", this.currentDateTime())
         formData.append("obs", "NC")
         axios.post("https://www.cafeimobiliaria.com.br/sistema/api/contatos/create", formData)
        }
      */
    }
  }
}
</script>

<style lang="postcss">
.property-values {
  .c-button {
      & + .c-button {
        @apply mx-0;
      }
    }

  .divider:last-of-type {
    border: 0;
  }
}
.bg-blue-pv {
  background-color: #4062d1;
  color: white;
  padding: 5px 10px;
  align-content: center;
  border-radius: 5px;
  /* font-weight: bolder; */
  margin-left: 5px;
  font-weight: normal !important;
}
.bg-green-pv {
  background-color: green;
  color: white;
  padding: 5px 10px;
  align-content: center;
  border-radius: 5px;
  /* font-weight: bolder; */
  margin-left: 5px;
  font-weight: normal !important;
}
</style>
