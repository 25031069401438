<template>
  <div class="absolute top-0 z-50 w-full text-xs bg-white property-search-filter md:relative md:top-auto md:z-10">
    <div class="max-w-xl px-4 mx-auto">
      <h3 class="my-4 text-xl text-primary-500">Filtros</h3>
      <div class="flex group">
        <!-- <c-select
          label="Tipo de imóvel"
          :options="formOptions.tipoDeImoveis"
          v-model="filter.tipo_de_imovel"
          class="w-1/2"
          multiple
        ></c-select> -->
        <c-input label="Código" placeholder="Código do imóvel" v-model="codigo">
          <c-button class="rounded-none primary" icon="search" @click="buscaporcodigo" />
        </c-input>
      </div>
      <c-checkbox-group-encadeado
        label="Tipo de Imóvel"
        type="checkbox"
        :model="[filter, 'tipo_de_imovel']"
        :options="formOptions.tipoDeImoveisArr"
        class="flex flex-wrap"
        checkboxClass="w-full ml-0"
        name="tipo_de_imovel"
      ></c-checkbox-group-encadeado>
      <!-- @click.native="alteratiposimoveis()" -->
      <c-select
        label="Cidade"
        :options="cidadesFromAPI"
        v-model="filter.cidade"
        class="w-full"
        @input="filtrarBairrosporcidade"
      ></c-select>
      <c-select
        label="Bairros"
        v-model="filter.bairros"
        :options="bairrosalistar"
        class="w-full"
        :limit="5"
        multiple
      ></c-select>
      <!-- <c-checkbox-group
        :label="printlabelcomodo"
        name="dormitorios"
        type="radio"
        :model="[filter, 'dormitorios']"
        :options="['1+', '2+', '3+', '4+']"
        class="flex flex-wrap justify-center"
        checkboxClass="c-button"
      ></c-checkbox-group> -->
      <!-- <c-select
        :label="printlabelcomodo"
        v-model="filter.dormitorios"
        :options="formOptions.dormitorios2"
        class="w-full"
        multiple
        :limit="5"
      ></c-select> -->
      <c-checkbox-group
        :label="printlabelcomodo"
        name="dormitorios"
        type="checkbox"
        :model="[filter, 'dormitorios']"
        :options="formOptions.dormitorios3"
        class="flex flex-wrap align-center"
        checkboxClass="w-1/2 md:w-1/6 ml-0 c-ckeck"
        :composicao="true"
      ></c-checkbox-group>
      <c-checkbox-group
        label="Garagens"
        name="garagens"
        type="radio"
        :model="[filter, 'garagens']"
        :options="arrgaragens"
        class="flex flex-wrap justify-center"
        checkboxClass="w-1/2 md:w-1/6 ml-0 c-ckeck"
        :composicao="true"
      ></c-checkbox-group>
        <!-- :checked="['3+']" -->
      <c-checkbox-group
        label="Banheiros"
        name="banheiros"
        type="radio"
        :model="[filter, 'banheiros']"
        :options="['1+', '2+', '3+', '4+']"
        class="flex flex-wrap justify-center"
        checkboxClass="w-1/2 md:w-1/6 ml-0 c-ckeck"
        :composicao="true"
      ></c-checkbox-group>
      <c-range-slider
        label="Valor de Locação"
        v-model="filter.valor_locacao"
        :step="10"
        :max="10000"
        money
        v-if="contrato === 'locacao'"
      />
      <c-range-slider :sufixo="sufixando" label="Valor" v-model="filter.valor" :step="1000" money v-else />
      <c-range-slider
        label="Área privativa mínima"
        v-model.number="filter.area_util"
        :min="0"
        :max="200"
        :step="100"
        :formatter="value => `${value} m²`"
        :placeholder="'Indiferente'"
      />
      <!-- <c-checkbox-group
        label="Orientação solar"
        name="orientacaoSolar"
        type="checkbox"
        :model="[filter, 'orientacao_solar']"
        :options="formOptions.orientacaoSolar"
        class="flex flex-wrap"
        checkboxClass="w-1/4 ml-0"
      ></c-checkbox-group> -->
      <c-checkbox-group
        label="Características"
        name="caracteres"
        type="checkbox"
        :model="[filter, 'caracteres']"
        :options="['Exclusivo', 'Mobiliado', 'Semimobiliado', 'Em construção']"
        class="flex flex-wrap"
        checkboxClass="w-1/2 md:w-1/4 ml-0"
      ></c-checkbox-group>
      <c-checkbox-group
        label="Comodidades"
        name="comodidades"
        type="checkbox"
        :model="[filter, 'comodidades']"
        :options="formOptions.comodidades"
        class="flex flex-wrap"
        checkboxClass="w-1/2 md:w-1/4 ml-0"
      ></c-checkbox-group>
      <c-checkbox-group
        label="Proximidades"
        name="proximidades"
        type="radio"
        :model="[ filter, 'local' ]"
        :options="['UFN', 'Calçadão', 'UFSM']"
        class="flex flex-wrap justify-center"
        checkboxClass="c-button"
      ></c-checkbox-group>
    </div>
    <br />
    <br />
    <br />
    <br />
    <footer
      class="sticky bottom-0 z-10 px-2 py-3 property-search-filter__footer bg-primary-100"
    >
      <div class="flex justify-between max-w-xl mx-auto">
        <c-button
          class="w-full mr-2 primary md:w-64"
          @click="passafiltros"
          v-bind:cond="cond"
        >
            Aplicar
        </c-button>
        <c-button class="w-24 ml-auto" @click="limpar">Limpar</c-button>
        <c-button class="w-24" @click="$emit('close')">
          Fechar
        </c-button>
      </div>
    </footer>
  </div>
</template>

<script>
import CRangeSlider from "./UI/CRangeSlider.vue"
import CCheckboxGroup from "./UI/CCheckboxGroup.vue"
import CCheckboxGroupEncadeado from "./UI/CCheckboxGroupEncadeado.vue"
import CButton from "./UI/CButton.vue"
import CSelect from "./UI/CSelect.vue"
import CInput from "./UI/CInput.vue"
import EstadoCidadeMixin from "../mixins/EstadoCidadeMixin"
import FormOptionsMixin from "../mixins/FormOptionsMixin"
import axios from "axios"

export default {
  components: { CInput, CSelect, CButton, CCheckboxGroup, CRangeSlider, CCheckboxGroupEncadeado },
  props: {
    filtracidade: {},
    imoveis_destaque: {},
    contrato: {
      type: String
    },
    filtros: {}
  },
  mixins: [EstadoCidadeMixin, FormOptionsMixin],
  data() {
    return {
      codigo: null,
      sufixo: "",
      labelcomodos: "Dormitórios",
      arrgaragens: ["0+", "1+", "2+", "3+"],
      nomeCidade: "",
      filtracidade_mutado: [],
      bairros_recebidos: [],
      tipospesquisados: "",
      filter: {
        cidade: "",
        bairros: [],
        tipo_de_imovel: [],
        valor: [0, 1000000],
        valor_locacao: [0, 10000],
        area_util: 0,
        comodidades: [],
        // dormitorios: 0,
        dormitorios: [],
        garagens: 0,
        banheiros: 0,
        orientacao_solar: [],
        caracteres: [],
        tags: "",
        local: ""
      },
      cidadesFromAPI: [],
      bairrosFromAPI: [],
      bairrosalistar: [],
      // filtracidade_mutado: t,
      cond: this.imoveis_destaque,
      exxx: this.filtracidade,
      fullPage: true,
      proximidadesroute: "",
      proxCoord: "",
      proxRaio: "",
      proximidades: [],
      proximidadesSM: {
        camobi: {
          lat: -29.702158,
          lng: -53.721131
        },
        ufsm: {
          lat: -29.720701450498595,
          lng: -53.71441177724734
        },
        ufn: {
          lat: -29.685457561260765,
          lng: -53.814266573881255
        },
        calcadao: {
          lat: -29.686466453568745,
          lng: -53.807623448370215
        }
      },
      center: {
        lat: -29.686466453568745,
        lng: -53.807623448370215
      }
      // cidades_filtradas: this.cond.filter(d => d.endereco_cidade === this.filter.cidade).slice(0, 8)
    }
  },
  computed: {
    sufixando: function() {
      if (Number(this.filter.valor[1]) > 999999) {
        // console.log("dentro do if: " + this.filter.valor[1])
        return " e acima"
      } else {
        // console.log("fora do if: " + this.filter.valor[1])
        return ""
      }
    },
    // testevento() {
    //   this.$emit("sufixando")
    //   console.log(this.filter.valor)
    //   return true
    // },
    printlabelcomodo() {
      var tipos = this.selectostring(this.filter.tipo_de_imovel)
      if (
        tipos.indexOf("Apartamento") !== -1 ||
        (tipos.indexOf("Casa") !== -1 && tipos.indexOf("Comercial") === -1) ||
        tipos.indexOf("Kitnet") !== -1 ||
        tipos.indexOf("Duplex") !== -1 ||
        tipos.indexOf("Triplex") !== -1 ||
        tipos.indexOf("Cobertura") !== -1
      ) {
        return "Dormitórios"
      } else if (
        tipos.indexOf("Sala Comercial") !== -1 ||
        tipos.indexOf("Casa Comercial") !== -1 ||
        tipos.indexOf("Loja") !== -1
      ) {
        return "Salas"
      } else {
        return "Dormitórios"
      }
    },
    urlcomplemento() {
      var retorno = ""
      if (this.$route.query.utm_source) {
        retorno += "&utm_source=" + this.$route.query.utm_source
        if (this.$route.query.utm_medium) {
          retorno += "&utm_medium=" + this.$route.query.utm_medium
        }
        if (this.$route.query.utm_campaign) {
          retorno += "&utm_campaign=" + this.$route.query.utm_campaign
        }
        if (this.$route.query.utm_id) {
          retorno += "&utm_id=" + this.$route.query.utm_id
        }
        if (this.$route.query.utm_term) {
          retorno += "&utm_term=" + this.$route.query.utm_term
        }
        if (this.$route.query.utm_content) {
          retorno += "&utm_content=" + this.$route.query.utm_content
        }
        return retorno
      } else {
        return ""
      }
    }
  },
  mounted() {
    window.addEventListener("keyup", event => {
      if (event.keyCode === 13 && this.codigo !== null && this.codigo !== "" && this.codigo !== " ") {
        var codigox = this.codigo
        window.location.href = "imovel/" + codigox
      }
    })
  },
  created () {
    if (this.$route.query.cidade) {
      this.filter.cidade = this.$route.query.cidade
    }
    if (this.filtros.cidade) {
      this.filter.cidade = this.filtros.cidade
    }
    if (this.$route.query.bairros) {
      this.filter.bairros = this.requestarrays(this.$route.query.bairros)
    }
    if (this.$route.query.tags) {
      this.filter.tags = this.$route.query.tags
    }
    if (this.filtros.bairros) {
      this.filter.bairros = this.requestarrays(this.filtros.bairros)
    }
    // if (this.$route.query.tipo_de_imovel) {
    //   this.filter.tipo_de_imovel = this.requestarrays(this.$route.query.tipo_de_imovel)
    // }
    // if (this.filtros.tipo_de_imovel) {
    //   this.filter.tipo_de_imovel = this.requestarrays(this.filtros.tipo_de_imovel)
    // }
    // if (this.$route.query.dormitorios && this.$route.query.dormitorios !== "null") {
    //   // this.filter.dormitorios = String(this.$route.query.dormitorios + "+").replace(" ", "")
    //   this.filter.dormitorios = this.requestarrays(String(this.$route.query.dormitorios))
    // }
    // if (this.filtros.dormitorios) {
    //   this.filter.dormitorios = this.requestarrays(this.filtros.dormitorios)
    // }
    if (this.$route.query.dormitorios) {
      this.filter.dormitorios = this.requestarrayspuros(this.$route.query.dormitorios)
    }
    if (this.filtros.dormitorios) {
      this.filter.dormitorios = this.requestarrayspuros(this.filtros.dormitorios)
    }
    if (this.$route.query.garagens && this.$route.query.garagens !== "null") {
      this.filter.garagens = String(this.$route.query.garagens + "+").replace(" ", "")
    }
    if (this.filtros.garagens) {
      this.filter.garagens = this.filtros.garagens
    }
    if (this.$route.query.banheiros && this.$route.query.banheiros !== "null") {
      this.filter.banheiros = String(this.$route.query.banheiros + "+").replace(" ", "")
    }
    if (this.filtros.banheiros) {
      this.filter.banheiros = this.filtros.banheiros
    }
    if (this.$route.query.valor) {
      var intervalovalores = this.$route.query.valor.split(",")
      var minimo = Number(intervalovalores[0])
      var maximo = Number(intervalovalores[1])
      this.filter.valor = [minimo, maximo]
      if (this.contrato === "locacao") {
        this.filter.valor_locacao = [minimo, maximo]
      }
    }
    if (this.filtros.valor) {
      var intervalovalores2 = this.filtros.valor.split(",")
      var minimo2 = Number(intervalovalores2[0])
      var maximo2 = Number(intervalovalores2[1])
      this.filter.valor = [minimo2, maximo2]
      if (this.contrato === "locacao") {
        this.filter.valor_locacao = [minimo2, maximo2]
      }
    }
    // console.log("valor: " + this.filtros.valor)
    // else if (this.filtros.valor) {
    //   var intervalovalores2 = this.filtros.valor.split(",")
    //   var minimo2 = Number(intervalovalores2[0])
    //   var maximo2 = Number(intervalovalores2[1])
    //   this.filter.valor = [minimo2, maximo2]
    // }
    if (this.$route.query.area_util) {
      this.filter.area_util = this.$route.query.area_util
    }
    if (this.$route.query.proximidades) {
      this.filter.local = this.$route.query.proximidades
      // console.log(this.proximidadesmodel)
    }
    if (this.filtros.proximidades) {
      this.filter.local = this.filtros.proximidades
    }
    if (this.filtros.area_util) {
      this.filter.area_util = this.filtros.area_util
    }
    if (this.$route.query.comodidades) {
      this.filter.comodidades = this.requestarrayspuros(this.$route.query.comodidades)
    }
    if (this.filtros.comodidades) {
      this.filter.comodidades = this.requestarrayspuros(this.filtros.comodidades)
    }
    if (this.filtros.tipo_de_imovel) {
      this.filter.tipo_de_imovel = this.requestarrayspuros(this.filtros.tipo_de_imovel)
    }
    if (this.$route.query.tipo_de_imovel) {
      this.filter.tipo_de_imovel = this.requestarrayspuros(this.$route.query.tipo_de_imovel)
    }
    if (this.$route.query.caracteres) {
      this.filter.caracteres = this.requestarrayspuros(this.$route.query.caracteres)
    }
    if (this.filtros.caracteres) {
      this.filter.caracteres = this.requestarrayspuros(this.filtros.caracteres)
    }
    if (this.filtros.tags) {
      this.filter.tags = this.filtros.tags
    }
    if (this.filtros.proximidades) {
      this.filter.local = this.filtros.proximidades
    }
    if (this.$route.query.proximidades && this.$route.query.proximidades !== "") {
      this.proximidadesroute = this.$route.query.proximidades
      switch (this.proximidadesroute) {
        case "UFN":
          this.proxCoord = this.proximidadesSM.ufn
          this.proxRaio = 1
          this.center = this.proxCoord
          break
        case "UFSM":
          this.proxCoord = this.proximidadesSM.ufsm
          this.proxRaio = 2
          this.center = this.proxCoord
          break
        case "Calçadão":
          this.proxCoord = this.proximidadesSM.calcadao
          this.center = this.proxCoord
          this.proxRaio = 1
          break
      }
    }
    // Usar: this.$route.query.bairros !!!!! Jonatas, pra ti lembrar, bonitão, é nóis - EU do passado
    axios.get("jsoncidades.json")
      .then((response) => {
        this.cidadesFromAPI = response.data.map(cidade => {
          /* id = valor que é atribuído ao select */
          /* label = valor que é exibido para o usuário */
          return {
            id: cidade.nome,
            label: cidade.nome,
            ident: cidade.id
          }
        })
      })
    axios.get("jsonbairros.json")
      .then((response) => {
        this.bairrosFromAPI = response.data.map(bairro => {
          /* id = valor que é atribuído ao select */
          /* label = valor que é exibido para o usuário */
          return {
            id: bairro.nome,
            label: bairro.nome,
            // label: [this.replaceSpecialChars(bairro.nome), bairro.nome],
            cidade: bairro.cidade
          }
        })
        var cidadeescolhida = "Santa Maria"
        if (this.filter.cidade !== "") {
          cidadeescolhida = this.filter.cidade
        }
        this.bairrosalistar = this.bairrosFromAPI.filter(d => d.cidade === cidadeescolhida)
      })
    // var tipos = this.selectostring(this.filter.tipo_de_imovel)
    // console.log("ddd")
    // if (this.filter.tipo_de_imovel.includes("Apartamento")) {
    //   console.log("Apartamentos listar")
    //   this.filter.tipo_de_imovel = ["Apartamento", "Kitnet/Loft/Studio", "Cobertura", "Duplex", "Triplex"]
    // }
    // console.log(this.filter.tipo_de_imovel)
  },
  methods: {
    alteratiposimoveis (ativar) {
      // console.log(this.filter.tipo_de_imovel)
      if (ativar === "Residenciais") {
        this.filter.tipo_de_imovel = ["Casa", "Apartamento", "Kitnet/Loft/Studio", "Cobertura", "Duplex", "Triplex"]
      }
      if (ativar === "Apartamento") {
        this.filter.tipo_de_imovel = ["Apartamento", "Kitnet/Loft/Studio", "Cobertura", "Duplex", "Triplex"]
      }
      if (ativar === "Comerciais") {
        this.filter.tipo_de_imovel = ["Terreno", "Pavilhão", "Prédio", "Sala Comercial", "Casa Comercial", "Loja"]
      }
      if (ativar === "Rurais") {
        this.filter.tipo_de_imovel = ["Chácara/Sítio", "Campo/Fazenda"]
      }
    },
    requestarrays(requestitens) {
      var itensrecebidos = requestitens.split(",")
      var todosositens = []
      var todosositensrecebidos = itensrecebidos
      Object.keys(todosositensrecebidos).forEach(function(item) {
        // console.log(item + " = " + bairrox[item].label)
        if (todosositensrecebidos[item] !== "") {
          todosositens[item] = { "label": todosositensrecebidos[item] }
        }
      })
      return todosositens
    },
    requestarrayspuros(requestitens) {
      var itensrecebidos = requestitens.split(",")
      var todosositens = []
      var todosositensrecebidos = itensrecebidos
      Object.keys(todosositensrecebidos).forEach(function(item) {
        // console.log(item + " = " + bairrox[item].label)
        if (todosositensrecebidos[item] !== "") {
          todosositens[item] = todosositensrecebidos[item]
        }
      })
      return todosositens
    },
    filtrarBairrosporcidade() {
      // console.log("chamo" + this.filter.cidade)
      this.bairrosalistar = this.bairrosFromAPI.filter(d => d.cidade === this.filter.cidade)
    },
    limpar() {
      this.filter.cidade = ""
      this.filter.bairros = []
      this.filter.tipo_de_imovel = []
      this.filter.valor = [0, 1000000]
      this.filter.valor_locacao = [0, 10000]
      this.filter.area_util = 0
      this.filter.comodidades = []
      // this.filter.dormitorios = 0
      this.filter.dormitorios = []
      this.filter.garagens = 0
      this.filter.banheiros = 0
      this.filter.orientacao_solar = []
      this.filter.caracteres = []
      var valorlimpo = [0, 1000000]
      if (this.contrato === "locacao") {
        valorlimpo = [0, 10000]
      }
      var filtrospesquisa = {
        tipo_de_imovel: [],
        cidade: "",
        bairros: [],
        // dormitorios: 0,
        dormitorios: [],
        garagens: "",
        banheiros: 0,
        valor: valorlimpo,
        area: 0,
        comodidades: [],
        caracteres: [],
        area_util: 0,
        tags: ""
      }
      this.$emit("filtros", filtrospesquisa)
      var urlatualizada = "venda"
      if (this.contrato === "locacao") {
        urlatualizada = "aluguel"
      }
      history.pushState("Object", "Title", urlatualizada + this.urlcomplemento)
    },
    /*
    atualizar() {
      // Tipos de Imóvel Pesquisados
      var thistipos = this.filter.tipo_de_imovel
      Object.keys(thistipos).forEach(function(item) {
        this.tipospesquisados += "" + thistipos[item].label + ","
      })
      // Bairros Pesquisados
      var bairrospesquisados = ""
      var thisbairros = this.filter.bairros
      Object.keys(thisbairros).forEach(function(item) {
        bairrospesquisados += "" + thisbairros[item].label + ","
      })
      // Intervalo de Valores Pesquisados
      var valorespesquisados = ""
      var thisvalores = this.filter.valor
      if (this.contrato === "locacao") {
        thisvalores = this.filter.valor_locacao
      } else {
        thisvalores = this.filter.valor
      }
      Object.keys(thisvalores).forEach(function(item) {
        valorespesquisados += "" + thisvalores[item] + ","
      })
      // Orientação Solar
      var orientacaosolar = ""
      var thisorientacao = this.filter.orientacao_solar
      Object.keys(thisorientacao).forEach(function(item) {
        orientacaosolar += "" + thisorientacao[item] + ","
      })
      // Comodidades
      var varcomodidades = ""
      var thiscomodidades = this.filter.comodidades
      Object.keys(thiscomodidades).forEach(function(item) {
        varcomodidades += "" + thiscomodidades[item] + ","
      })
      var linkcontrato = "venda?"
      if (this.contrato === "locacao") {
        linkcontrato = "aluguel?"
      } else {
        linkcontrato = "venda?"
      }
      window.location.href = linkcontrato + "tipo_de_imovel=" + this.tipospesquisados +
      "&cidade=" + this.filter.cidade +
      "&bairros=" + bairrospesquisados +
      "&valor=" + valorespesquisados +
      "&dormitorios=" + this.filter.dormitorios + "&garagens=" + this.filter.garagens + "&banheiros=" + this.filter.banheiros +
      "&area_util=" + this.filter.area_util +
      "&orientacao_solar=" + orientacaosolar +
      "&comodidades=" + varcomodidades
    },
    */
    buscaporcodigo() {
      if (this.codigo !== null && this.codigo !== "" && this.codigo !== " ") {
        var codigox = this.codigo
        window.location.href = "imovel/" + codigox
      }
    },
    passafiltros(event) {
      var thisvalores = this.filter.valor
      if (this.contrato === "locacao") {
        thisvalores = this.filter.valor_locacao
      } else {
        thisvalores = this.filter.valor
      }
      switch (this.filter.local) {
        case "UFN":
          this.proxCoord = this.proximidadesSM.ufn
          this.proxRaio = 1
          this.center = this.proxCoord
          break
        case "UFSM":
          this.proxCoord = this.proximidadesSM.ufsm
          this.proxRaio = 2
          this.center = this.proxCoord
          break
        case "Calçadão":
          this.proxCoord = this.proximidadesSM.calcadao
          this.center = this.proxCoord
          this.proxRaio = 1
          break
      }
      var filtrospesquisa = {
        // tipo_de_imovel: this.selectostring(this.filter.tipo_de_imovel),
        tipo_de_imovel: this.selectintervalo(this.filter.tipo_de_imovel),
        cidade: this.filter.cidade,
        bairros: this.selectostring(this.filter.bairros),
        // dormitorios: this.selectostring(this.filter.dormitorios),
        dormitorios: this.selectintervalo(this.filter.dormitorios),
        garagens: this.filter.garagens,
        banheiros: this.filter.banheiros,
        valor: this.selectintervalo(thisvalores),
        area_util: this.filter.area_util,
        comodidades: this.selectintervalo(this.filter.comodidades),
        caracteres: this.selectintervalo(this.filter.caracteres),
        tags: this.filter.tags,
        proxCoord: this.proxCoord,
        center: this.center,
        proxRaio: this.proxRaio,
        proximidades: this.filter.local
      }
      // console.log(filtrospesquisa)
      this.$emit("filtros", filtrospesquisa)
      this.filter.dormitorios = filtrospesquisa.dormitorios
      this.$emit("pesquisar")
      this.$emit("close")
    },
    selectostring(arrayrecebido) {
      var itens = ""
      Object.keys(arrayrecebido).forEach(function(item) {
        itens += "" + arrayrecebido[item].label + ","
      })
      return itens
    },
    selectintervalo(arrayrecebido) {
      var itens = ""
      Object.keys(arrayrecebido).forEach(function(item) {
        itens += "" + arrayrecebido[item] + ","
      })
      return itens
    },
    replaceSpecialChars(str) {
      str = str.replace(/[ÀÁÂÃÄÅ]/, "A")
      str = str.replace(/[àáâãäå]/, "a")
      str = str.replace(/[ÈÉÊË]/, "E")
      str = str.replace(/[Ç]/, "C")
      str = str.replace(/[ç]/, "c")
      return str.replace(/[^a-z0-9]/gi, " ")
    }
  }
}
</script>

<style lang="postcss">
.property-search-filter {
  label {
    @apply text-xs mb-2;
  }

  .white-label {
    label {
      @apply text-white;
    }
  }

  &__footer {
    box-shadow: 0 -1px 8px rgba(0, 0, 0, 0.1);
  }
}
</style>
