<template>
  <div class="nav-links" ref="navLinks">
    <nav class="nav-links__menu hidden md:flex">
      <link-list :default="true" />
    </nav>
    <transition name="fade-slide">
      <div class="nav-links__menu--mobile flex flex-col" v-show="showMenu">
        <div class="flex justify-between items-center pb-2 mb-2 border-b-2">
          <router-link
            class="inline-flex items-center w-32 h-12 rounded font-medium text-primary-500"
            to="/"
          >
            <span class="bg-primary-500 text-white rounded-full w-8 h-8 inline-flex items-center justify-center mr-2">
              <font-awesome-icon icon="user" />
            </span>
            Entrar
          </router-link>
          <c-button @click="toggleMenu()" class="bg-transparent w-12 h-12 items-center p-0 border-0">
            <font-awesome-icon icon="times" />
          </c-button>
        </div>
        <link-list :isMobile="isMobile" />
        <div class="mt-auto">
          <h3 class="font-medium mb-4 text-primary-500">Fale com a gente</h3>
          <p class="text-xs">
            <font-awesome-icon icon="phone" class="mr-2" />
            <a href="tel:(55) 3307 8072" class="font-medium">(55) 3307 8072</a>
          </p>
          <p class="text-xs mt-2">
            <font-awesome-icon :icon="['fab', 'whatsapp']" class="mr-2" />
            <a href="tel:(55) 99912 8466" class="font-medium">(55) 99912 8466</a>
          </p>
          <p class="text-xs mt-2">
            <font-awesome-icon icon="envelope" class="mr-2" />
            <a class="font-medium" href="mailto:contato@cafeimobiliaria.com.br">contato@cafeimobiliaria.com.br</a>
          </p>
        </div>
      </div>
    </transition>
    <c-button circle @click="toggleMenu()" class="block md:hidden">
      <font-awesome-icon icon="bars" />
    </c-button>
  </div>
</template>

<script>
import LinkList from "./LinkList.vue"
import CButton from "./UI/CButton.vue"
import EventBus from "@/helpers/EventBus"

export default {
  components: {
    CButton,
    LinkList
  },
  data() {
    return {
      showMenu: false,
      isMobile: false
    }
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu
    }
  },
  created() {

  },
  destroyed() {
    EventBus.$off("closeMenu")
  },
  mounted() {
    EventBus.$on("closeMenu", () => {
      this.showMenu = false
    })
    this.isMobile = window.innerWidth < 768
  }
}
</script>

<style lang="postcss">
.nav-links {
  @apply ml-auto my-auto;

  &__menu--mobile {
    @apply fixed top-0 left-0 w-full h-full z-50 text-gray-800 px-6 py-6;

    background: url("../assets/img/blob_bg.png") no-repeat 100%,
      url("../assets/img/bubble_bg.png") no-repeat 5% 95%, #fff;
  }

  &__menu {
    ^&__item {
      & + a {
        @apply ml-8;
      }
    }
  }
}
</style>
