<template>
  <div class="c-section-title text-center md:text-left" :class="`mb-${mb}`">
    <h2 class="text-xl md:text-4xl font-medium md:font-normal">
      <slot />
      <span class="text-primary-500"><slot name="strong" /></span>
    </h2>
    <slot name="sub" />
  </div>
</template>

<script>
export default {
  props: {
    mb: {
      type: String,
      default: "10"
    }
  }
}
</script>

<style></style>
