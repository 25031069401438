<template>
  <div class="cashback-social">
    <c-modal v-if="currentEntity" @close="$emit(handleShowEntityInfo(null))">
      <template #body>
        <h2 class="text-xl font-medium">
          {{ currentEntityText["title"] }}
        </h2>
        <p class="text-sm mt-4">
          <img
            :src="require(`@/assets/img/${currentEntityText['img']}`)"
            alt="currentEntityText['title']"
            class="w-auto h-20 rounded float-left mr-2"
          />
          {{ currentEntityText["text"] }}
        </p>
        <div class="text-xs mt-4 text-center">
          <strong v-if="currentEntityText['address']" class="block mb-2">
            <font-awesome-icon icon="map-marker-alt" />
            {{ currentEntityText["address"] }}
          </strong>
          <strong
            v-if="currentEntityText['phone']"
            class="inline-block mb-2 mr-4"
          >
            <font-awesome-icon icon="phone" />
            {{ currentEntityText["phone"] }}
          </strong>
          <strong
            v-if="currentEntityText['site']"
            class="underline text-primary-500 whitespace-no-wrap block"
          >
            <a
              :href="'https://' + currentEntityText['site']"
              target="_blank"
              rel="no-opener"
            >
              <font-awesome-icon icon="external-link-alt" />
              {{ currentEntityText["site"] }}
            </a>
          </strong>
        </div>
      </template>
    </c-modal>
    <main-header bg="imovel.jpg">
      <c-section class="cashback-social__header">
        <h2>Cashback social</h2>
        <p>
          Para cada compra realizada, parte do dinheiro investido é destinado à
          uma entidade social.
        </p>
        <a href="#" class="c-button primary inline-block">
          Quero participar
        </a>
      </c-section>
    </main-header>
    <c-section class="cashback-social__about">
      <h3 class="title">
        O que é o
        <span class="text-primary-500">Cashback social?</span>
      </h3>
      <p class="subtitle">
        A premissa do projeto é devolver ao consumidor uma parte do valor gasto
        na compra de produtos.
        <br />
        Em nosso caso, revertermos uma parte da comissão imobiliária gerada em
        benefícios a projetos sociais da cidade.
      </p>
      <div class="flex mt-8 -mx-4 flex-wrap">
        <div class="w-full md:w-1/3 px-4">
          <figure-card noCard img="agreement" class="w-full">
            <template #title>Venda efetivada</template>
            <template #content>
              O cliente fecha negócio e a comissão é gerada.
            </template>
          </figure-card>
        </div>
        <div class="w-full md:w-1/3 px-4">
          <figure-card noCard img="launch" class="w-full">
            <template #title>Seleção do projeto social</template>
            <template #content>
              O cliente seleciona o projeto para a doação.
            </template>
          </figure-card>
        </div>
        <div class="w-full md:w-1/3 px-4">
          <figure-card noCard img="celebration" class="w-full">
            <template #title>Doação realizada!</template>
            <template #content>
              Dividimos parte da nossa comissão com aqueles que mais precisam.
            </template>
          </figure-card>
        </div>
      </div>
    </c-section>
    <c-section class="cashback-social__entities">
      <h3 class="title">Entidades beneficiadas</h3>
      <c-image-grid
        :grid="socialEntities"
        :gridSize="windowWidth < 768 ? 2 : 4"
        clickable
        @gridItemClick="handleShowEntityInfo"
      />
    </c-section>
    <!-- <c-section class="cashback-social__clients">
      <h3 class="title">Clientes que já participaram</h3>
      <div class="flex flex-wrap -mx-4">
        <div class="w-full md:w-1/3 px-4 mb-4 md:mb-0">
          <testimonial-card>
            <template #content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean id
              finibus magna. Nulla luctus posuere ipsum at dapibus. Curabitur
              tincidunt magna et dui ultricies rhoncus. Morbi tincidunt dui ac
              risus pretium, a fringilla nunc varius. Nunc mattis enim ac
              lacinia interdum. Ut finibus lacus ac luctus semper.
            </template>
          </testimonial-card>
        </div>
        <div class="w-full md:w-1/3 px-4 mb-4 md:mb-0">
          <testimonial-card>
            <template #content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean id
              finibus magna. Nulla luctus posuere ipsum at dapibus. Curabitur
              tincidunt magna et dui ultricies rhoncus. Morbi tincidunt dui ac
              risus pretium, a fringilla nunc varius. Nunc mattis enim ac
              lacinia interdum. Ut finibus lacus ac luctus semper.
            </template>
          </testimonial-card>
        </div>
        <div class="w-full md:w-1/3 px-4 mb-4 md:mb-0">
          <testimonial-card>
            <template #content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean id
              finibus magna. Nulla luctus posuere ipsum at dapibus. Curabitur
              tincidunt magna et dui ultricies rhoncus. Morbi tincidunt dui ac
              risus pretium, a fringilla nunc varius. Nunc mattis enim ac
              lacinia interdum. Ut finibus lacus ac luctus semper.
            </template>
          </testimonial-card>
        </div>
      </div>
    </c-section>
    <c-section class="cashback-social__video">
      <c-video-frame></c-video-frame>
    </c-section> -->
    <c-section>
      <h3 class="title mb-24">
        Gostou desta ideia? Faça parte deste movimento de solidariedade, compre
        seu imóvel na Café Inteligência Imobiliária.
      </h3>
      <property-CTA></property-CTA>
    </c-section>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import CModal from "../components/UI/CModal.vue"
import CVideoFrame from "../components/UI/CVideoFrame.vue"
import PropertyCTA from "../components/PropertyCTA.vue"
import TestimonialCard from "../components/TestimonialCard.vue"
import CImageGrid from "../components/UI/CImageGrid.vue"
import FigureCard from "../components/FigureCard.vue"
import CSection from "../components/UI/CSection.vue"
import MainHeader from "../components/MainHeader.vue"
export default {
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Cashback Social",
    meta: [
      {
        name: "description",
        content: "Café Inteligência Imobiliária atua em Santa Maria/RS e região, imóveis santa maria, Imobiliarias santa maria, Cashback Social"
      }
    ]
  },
  components: {
    MainHeader,
    CSection,
    FigureCard,
    CImageGrid,
    TestimonialCard,
    PropertyCTA,
    CVideoFrame,
    CModal
  },
  data() {
    return {
      windowWidth: 0,
      currentEntity: null
    }
  },
  computed: {
    socialEntities() {
      return [
        "social-logos/ACDV.jpg",
        "social-logos/Associação Colibri.jpg",
        "social-logos/Casa Maria.jpg",
        "social-logos/Centro de Ação à Criança com Câncer.jpg",
        "social-logos/Lar Vila das Flores.jpg",
        "social-logos/AAPECAN.jpg",
        "social-logos/APAE Santa Maria.jpg",
        "social-logos/Abrigo Espírita Oscar José Pithan.jpg"
      ]
    },
    socialEntitiesText() {
      return [
        {
          title: "A.C.D.V",
          text:
            "A associação dos Cegos e Deficientes Visuais de Santa Maria foi fundada em 09 de maio de 2003. É uma associação sem fins lucrativos preocupada em promover a acessibilidade do Deficiente Visual, resgatando-lhe o direito à cidadania e oferecendo-lhe condições para qualificação educacional e profissional, procurando inseri-lo no mercado de trabalho. E para isso, oferece aos seus associados: Oficina de Informática direcionada ao Deficiente Visual, Oficina Musical, Oficina de Leitura, Grupos de Apoio Psicológico, Ensino de Braille e Sorobã, Estimulação e Reeducação Visual (para crianças de zero a oito anos) e Treinamento Visual.",
          address: "Rua Manoel Ribas, 1926, Vila Belga, Santa Maria/RS",
          phone: "(55) 3307-8804",
          site: "www.acdvsm.blogspot.com"
        },
        {
          title: "Associação Colibri",
          text:
            "A Associação Colibri foi fundada em 7 de dezembro de 1988. Tem como finalidade prestar atendimento á pessoa com deficiência nas áreas da educação e assistência social. Atende mais de 60 alunos, com objetivo de contribuir para a realização dos plenos direitos da pessoa com NEs e desenvolvimento das suas potencialidades como fator de auto-realização, vida independente, exercício da cidadania e inclusão social.",
          address:
            "Rua Ernesto Becker, 478, Bairro Passo da Areia, Santa Maria/RS",
          phone: "(55) 3223-3486",
          site: "www.colibrism.wordpress.com"
        },
        {
          title: "Casa Maria",
          text:
            "A Casa Maria é uma entidade beneficente de amparo assistencial a pessoas em tratamento oncológico, assistencial e cultural, de direito privado, sem fins lucrativos, alheia a atividades de caráter político e religioso. Tivemos início do nosso trabalho no dia 28 de março de 2014, sendo resultado do engajamento de cidadãos que tiveram pessoas muito próximas que passaram pelas dificuldades de se realizar um tratamento médico em centros distantes sem o suporte financeiro necessário, surgindo então a ideia e a força necessária para implementar a criação de uma casa que pudesse acolher pacientes e familiares, proporcionando acesso durante o processo de tratamento oncológico, bem como oferecer apoio psicossocial à pessoas com neoplasia maligna em situação de vulnerabilidade social, com o fim de propiciar-lhes um tratamento digno e de qualidade.",
          address: "Rua Erly de Almeida Lima, 447, Camobi, Santa Maria/RS",
          phone: "(55) 3311-7077",
          site: "www.casamariaong.org"
        },
        {
          title: "Centro de Apoio à Criança com Câncer (CACC)",
          text:
            "A associação dos Cegos e Deficientes Visuais de Santa Maria foi fundada em 09 de maio de 2003. É uma associação sem fins lucrativos preocupada em promover a acessibilidade do Deficiente Visual, resgatando-lhe o direito à cidadania e oferecendo-lhe condições para qualificação educacional e profissional, procurando inseri-lo no mercado de trabalho. E para isso, oferece aos seus associados: Oficina de Informática direcionada ao Deficiente Visual, Oficina Musical, Oficina de Leitura, Grupos de Apoio Psicológico, Ensino de Braille e Sorobã, Estimulação e Reeducação Visual (para crianças de zero a oito anos) e Treinamento Visual.",
          address: "Rua Manoel Ribas, 1926, Vila Belga, Santa Maria/RS",
          phone: "(55) 3307-8804",
          site: "www.acdvsm.blogspot.com"
        },
        {
          title: "Lar Vila das Flores",
          text:
            "A associação “Lar Vila das Flores” é uma entidade de caráter assistencial, educacional e cultural, que se propõe a atender as necessidades básicas das crianças em situação de risco social ou abandono familiar, garantindo seus direitos, inclusive o direito a uma família sadia; Proporcionar condições de educação, saúde e lazer às crianças, através da escolarização, reforço de aprendizagem e atividades lúdicas, adequadas às suas necessidades.",
          address:
            "Rua Reverendo Adolfo Ungaretti, 50, Chácara das Flores, Santa Maria/RS",
          phone: " (55) 3222-5443 / 3217-1853"
        },
        {
          title: "AAPECAN",
          text:
            "A Associação de Apoio a Pessoas com Câncer (AAPECAN) é uma entidade sem fins lucrativos e iniciou suas atividades em Santa Maria no dia 26 de junho de 2006. A AAPECAN oferece apoio e auxílio a pessoas com câncer que não tem condições de manter seu tratamento, por isto oferecem medicamentos, suplementos, dietas alimentares, alimentos, tudo gratuitamente, graças a colaboração de seus contribuintes. A instituição também disponibiliza de atendimento psicológico, jurídico, nutricional, além de atividades para os usuários, como: Reiki, oficinas de artesanato, oficinas de inclusão produtiva, projetos para aterceira idade, entre outros.",
          address: "Av. Borges de Medeiros, 1897, Noal, Santa Maria/RS",
          phone: "(55) 3033-7900 / 3025-9400",
          site: "www.aapecan.com.br"
        },
        {
          title: "APAE",
          text:
            "A APAE de Santa Maria foi fundada em 30 de abril de 1966, estruturada por um movimento de pais que lutavam pela inclusão de seus filhos, e que identificaram a necessidade da criação de um espaço que promovesse a melhoria da qualidade de vida das pessoas com deficiência (PCD) , através de ações que gerassem o desenvolvimentos de habilidades e potencialidades, assim como um local de luta pela cidadania destas pessoas. A APAE Santa Maria, atende atualmente, 260 pessoas com deficiência, entre crianças, adolescentes e adultos, juntamente com suas famílias, e conta com uma equipe multiprofissional. Atualmente a APAE desenvolve vários projetos de extrema importância para o atendimento de pessoas com deficiência, e está sempre buscando ofertar serviços e atendimentos que atendam a necessidades de seus usuários.",
          address:
            "Rua Coronel Benjamim D'Avila Prado, 400, Cohab Santa Marta, Santa Maria/RS",
          phone: "(55) 3212-2111",
          site: " http://santamaria.apaers.org.br/"
        },
        {
          title: "ABRIGO ESPÍRITA OSCAR JOSÉ PITHAN",
          text:
            "O Abrigo Oscar José Pithan é uma sociedade Civil, fundada em 28 de agosto de 1949. Tem por finalidade oferecer assistência integral a idosos carentes e/ou em estado de abandono social. Isso é feito há mais de 52 anos ininterruptos e com uma capacidade atual para atender cerca de 65 pessoas nessas condições. É uma entidade filantrópica, atende gratuitamente, na forma de moradia, alimentação, vestuário, saúde, entre outros.",
          phone: "(55) 3221-6460 / 3221-6480",
          site: "https://oscarpithan.wordpress.com"
        }
      ]
    },
    currentEntityText() {
      return {
        ...this.socialEntitiesText[this.currentEntity],
        img: this.socialEntities[this.currentEntity]
      }
    }
  },
  mounted() {
    this.windowWidth = window.innerWidth
    this.$nextTick(() => window.addEventListener("resize", this.updateWidth))
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth)
  },
  methods: {
    handleShowEntityInfo(index) {
      this.currentEntity = index
    }
  }
}
</script>

<style lang="postcss">
.cashback-social {
  &__header {
    @apply text-center;
    h2 {
      @apply text-5xl;
    }

    p {
      @apply mb-8;
    }

    .c-button {
      @apply w-64;
    }
  }
}
</style>
