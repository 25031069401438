<template>
  <div class="flex flex-wrap items-center -mx-2 link-list">
    <router-link class="nav-links__item" v-if="
        $route.path == '/homeSc' ||
        $route.query.cidade == 'Balneário Camboriú' ||
        $route.path.indexOf('-sc') != -1
      " :class="linkClasses" :to="'/venda?cidade=Balneário Camboriú' + urlcomplemento">
      <p :class="spanClasses">
        <font-awesome-icon icon="home" class="block mb-2 text-xl" v-if="isMobile"/>
        <span>Comprar</span>
      </p>
    </router-link>
    <router-link class="nav-links__item" v-else :class="linkClasses" :to="'/venda' + urlcomplemento">
      <p :class="spanClasses">
        <font-awesome-icon icon="home" class="block mb-2 text-xl" v-if="isMobile"/>
        <span>Comprar</span>
      </p>
    </router-link>
    <!-- {{ $route.path }} -->
    <!-- Aqui ocultamos o Link Alugar das páginas de Santa Catarina -->
    <router-link class="nav-links__item" v-if="
        $route.path != '/homeSc' &&
        $route.query.cidade != 'Balneário Camboriú' &&
        $route.path.indexOf('-sc') == -1
      " :class="linkClasses" :to="'/aluguel' + urlcomplemento">
      <p :class="spanClasses">
        <!-- {{ $route.path }} -->
        <font-awesome-icon icon="home" class="block mb-2 text-xl" v-if="isMobile"/>
        <span>Alugar</span>
      </p>
    </router-link>
    <router-link class="nav-links__item" :class="linkClasses" :to="'/sobre-nos' + urlcomplemento">
      <p :class="spanClasses">
        <font-awesome-icon icon="house-user" class="block mb-2 text-xl" v-if="isMobile"/>
        <span>Sobre nós</span>
      </p>
    </router-link>
    <router-link class="nav-links__item" :class="linkClasses" :to="'/empreendimentos' + urlcomplemento">
      <p :class="spanClasses">
        <font-awesome-icon icon="building" class="block mb-2 text-xl" v-if="isMobile"/>
        <span>Empreendimentos</span>
      </p>
    </router-link>
    <router-link class="nav-links__item" :class="linkClasses" :to="'/anunciar-imovel' + urlcomplemento">
      <p :class="spanClasses">
        <font-awesome-icon icon="handshake" class="block mb-2 text-xl" v-if="isMobile"/>
        <span>Anunciar</span>
      </p>
    </router-link>
    <!-- <router-link class="nav-links__item" :class="linkClasses" :to="'/campanha">
      <p :class="spanClasses">
        <font-awesome-icon icon="handshake" class="block mb-2 text-xl" v-if="isMobile"/>
        <span>Feirão MCMV</span>
      </p>
    </router-link> -->
    <!-- <a class="nav-links__item" :class="linkClasses" target="_blank" href="">
      <p :class="spanClasses">
        <font-awesome-icon icon="handshake" class="block mb-2 text-xl" v-if="isMobile"/>
        <span>Área do Locatário</span>
      </p>
    </a> -->
    <!-- <a class="nav-links__item" :class="linkClasses" target="_blank" href="https://www.cafeinteligencia.com.br/documentos-locacao.php">
      <p :class="spanClasses">
        <font-awesome-icon icon="handshake" class="block mb-2 text-xl" v-if="isMobile"/>
        <span>Doc. p/ Locação</span>
      </p>
    </a> -->
    <!-- <router-link class="nav-links__item" :class="linkClasses" :to="'/indique-e-ganhe">
      <p :class="spanClasses">
        <font-awesome-icon icon="people-arrows" class="block mb-2 text-xl" v-if="isMobile"/>
        <span>Indicar</span>
      </p>
    </router-link> -->
    <router-link
      v-if="isLogged == 1"
      class="nav-links__item"
      :to="'/entrar' + urlcomplemento"
    >
      <p :class="spanClasses">
        <font-awesome-icon icon="user-circle" />
        <span class="ml-1 mr-1 font-medium ">Vitor</span>
        <font-awesome-icon icon="angle-down" />
      </p>
    </router-link>
    <!-- <router-link
      v-else
      class="hidden nav-links__item md:block"
      :class="{
        'c-button secondary text-primary-500 inline-block w-32': $attrs.default
      }"
      :to="'/entrar"
    >
      <span>Entrar</span>
    </router-link> -->
  </div>
</template>

<script>
export default {
  props: {
    isMobile: Boolean
  },
  data() {
    return {
      isLogged: false
    }
  },
  computed: {
    linkClasses() {
      return !this.isMobile || "w-1/2 p-2"
    },
    spanClasses() {
      return !this.isMobile || "bg-primary-500 text-white block h-20 rounded flex flex-col justify-center items-center text-sm font-medium "
    },
    urlcomplemento() {
      var retorno = ""
      if (this.$route.query.utm_source) {
        retorno += "?utm_source=" + this.$route.query.utm_source
        if (this.$route.query.utm_medium) {
          retorno += "&utm_medium=" + this.$route.query.utm_medium
        }
        if (this.$route.query.utm_campaign) {
          retorno += "&utm_campaign=" + this.$route.query.utm_campaign
        }
        if (this.$route.query.utm_id) {
          retorno += "&utm_id=" + this.$route.query.utm_id
        }
        if (this.$route.query.utm_term) {
          retorno += "&utm_term=" + this.$route.query.utm_term
        }
        if (this.$route.query.utm_content) {
          retorno += "&utm_content=" + this.$route.query.utm_content
        }
        return retorno
      } else {
        return ""
      }
    }
  },
  watch: {
    "$route"() {
      this.isLogged = localStorage.getItem("isLogged")
    }
  }
}
</script>
