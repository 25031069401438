<template>
  <div class="c-card-wrapper">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="postcss">
.c-card-wrapper {
  @apply rounded overflow-hidden bg-white h-full w-full;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 4px 6px 2px rgba(0, 0, 0, 0.05);
  transition: max-height 300ms linear;
}

button.c-card-wrapper {
  transition: background 150ms, box-shadow 150ms;
  &:hover {
    @apply bg-primary-100 shadow-xl;
  }
}
</style>
