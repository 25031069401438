<template>
  <span class="c-info">
    <font-awesome-icon
      icon="info-circle"
      class="text-primary-500"
      :title="title || content"
      @click="show = true"
    />
    <c-modal v-if="show" @close="show = false" class="z-100-cinfo">
      <template #body>
        <div class="text-left w-full">
          <h3 class="text-xl font-medium mb-4 text-primary-500">{{ title }}</h3>
          <p>
            {{ content }}
            <a v-if="link" :href="link" target="blanck" style="color: blue; text-decoration: underline">{{ link }}</a>
            <span style='font-size:15px;' v-if="icon">&#128521;</span>
            </p>
        </div>
      </template>
    </c-modal>
  </span>
</template>

<script>
import CModal from "./CModal.vue"
export default {
  components: { CModal },
  props: ["title", "content", "link", "icon"],
  data() {
    return {
      show: false
    }
  },
  created() {
    // aqui um dia
  }
}
</script>

<style>
.c-info svg {
  cursor: pointer;
  opacity: 0.5;
}
</style>
