<template>
  <router-link
    :to="to"
    class="benefit-card rounded bg-gray-500 p-8 text-white w-full h-full flex"
    :bg="bg"
  >
    <div class="benefit-card__content">
      <font-awesome-icon class="text-2xl" v-if="icon" :icon="icon" />
      <h3 class="text-2xl">
        <slot />
      </h3>
      <p class="max-w-md">
        <slot name="content" />
      </p>
    </div>
    <div class="benefit-card__bg" icon="heart" v-if="bg" >
      <img
        :src="require('@/assets/img/' + bg)"
        :alt="$slots.default[0].text"
      />
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: undefined,
      default() {
        return {}
      }
    },
    icon: {
      type: String,
      default: null
    },
    bg: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      imagem: ""
    }
  },
  created () {
    this.imagem = require("@/assets/img/" + this.bg)
  }
}

</script>

<style lang="postcss">
.benefit-card {
  height: 240px;
  @apply relative overflow-hidden;

  &__content {
    @apply relative z-10;

    * + * {
      @apply mt-2;
      /* background: rgba(0, 0, 0, 0.6) !important; */
      /* text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 rgb(161, 150, 150), 1px -1px 0 #000, -1px 1px 0 #000; */
    }
  }

  &__bg {
    transform: translateY(-50%);
    top: 50%;
    @apply absolute left-0 flex;

    &:before {
      content: "";
      /* background: rgba(0, 0, 0, 0.25); */
      @apply absolute top-0 left-0 block h-full w-full;
      background: rgba(0, 0, 0, 0.6) !important;
    }

    img {
      @apply object-cover;
      @media (max-width: 800px) {
        height: 240px;
      }
    }
  }
}
</style>
