<template>
  <ul class="c-image-grid" aria-hidden="true">
    <li
      @click="$emit('gridItemClick', index)"
      class="c-image-grid__item"
      v-for="(image, index) in grid"
      :key="index"
      :class="[
        { 'border-l-0': index % gridSize === 0 },
        { 'border-b-0': index > grid.length - 1 - gridSize },
        { clickable: clickable },
        'w-1/' + gridSize
      ]"
    >
      <img
        class="c-image-grid__image"
        :src="require(`@/assets/img/${image}`)"
        :alt="image"
      />
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    grid: {
      type: Array,
      required: true
    },
    gridSize: {
      type: Number,
      default: 4
    },
    clickable: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="postcss">
.c-image-grid {
  @apply flex flex-wrap;

  &__item {
    @apply text-center p-8 border-gray-200 border-solid;

    &.clickable {
      cursor: pointer;
    }

    & {
      @apply border-l-2 border-b-2;
    }
  }

  &__image {
    @apply w-full;
  }
}
</style>
