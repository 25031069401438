var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-wrap items-center -mx-2 link-list"},[(
      _vm.$route.path == '/homeSc' ||
      _vm.$route.query.cidade == 'Balneário Camboriú' ||
      _vm.$route.path.indexOf('-sc') != -1
    )?_c('router-link',{staticClass:"nav-links__item",class:_vm.linkClasses,attrs:{"to":'/venda?cidade=Balneário Camboriú' + _vm.urlcomplemento}},[_c('p',{class:_vm.spanClasses},[(_vm.isMobile)?_c('font-awesome-icon',{staticClass:"block mb-2 text-xl",attrs:{"icon":"home"}}):_vm._e(),_c('span',[_vm._v("Comprar")])],1)]):_c('router-link',{staticClass:"nav-links__item",class:_vm.linkClasses,attrs:{"to":'/venda' + _vm.urlcomplemento}},[_c('p',{class:_vm.spanClasses},[(_vm.isMobile)?_c('font-awesome-icon',{staticClass:"block mb-2 text-xl",attrs:{"icon":"home"}}):_vm._e(),_c('span',[_vm._v("Comprar")])],1)]),(
      _vm.$route.path != '/homeSc' &&
      _vm.$route.query.cidade != 'Balneário Camboriú' &&
      _vm.$route.path.indexOf('-sc') == -1
    )?_c('router-link',{staticClass:"nav-links__item",class:_vm.linkClasses,attrs:{"to":'/aluguel' + _vm.urlcomplemento}},[_c('p',{class:_vm.spanClasses},[(_vm.isMobile)?_c('font-awesome-icon',{staticClass:"block mb-2 text-xl",attrs:{"icon":"home"}}):_vm._e(),_c('span',[_vm._v("Alugar")])],1)]):_vm._e(),_c('router-link',{staticClass:"nav-links__item",class:_vm.linkClasses,attrs:{"to":'/sobre-nos' + _vm.urlcomplemento}},[_c('p',{class:_vm.spanClasses},[(_vm.isMobile)?_c('font-awesome-icon',{staticClass:"block mb-2 text-xl",attrs:{"icon":"house-user"}}):_vm._e(),_c('span',[_vm._v("Sobre nós")])],1)]),_c('router-link',{staticClass:"nav-links__item",class:_vm.linkClasses,attrs:{"to":'/empreendimentos' + _vm.urlcomplemento}},[_c('p',{class:_vm.spanClasses},[(_vm.isMobile)?_c('font-awesome-icon',{staticClass:"block mb-2 text-xl",attrs:{"icon":"building"}}):_vm._e(),_c('span',[_vm._v("Empreendimentos")])],1)]),_c('router-link',{staticClass:"nav-links__item",class:_vm.linkClasses,attrs:{"to":'/anunciar-imovel' + _vm.urlcomplemento}},[_c('p',{class:_vm.spanClasses},[(_vm.isMobile)?_c('font-awesome-icon',{staticClass:"block mb-2 text-xl",attrs:{"icon":"handshake"}}):_vm._e(),_c('span',[_vm._v("Anunciar")])],1)]),(_vm.isLogged == 1)?_c('router-link',{staticClass:"nav-links__item",attrs:{"to":'/entrar' + _vm.urlcomplemento}},[_c('p',{class:_vm.spanClasses},[_c('font-awesome-icon',{attrs:{"icon":"user-circle"}}),_c('span',{staticClass:"ml-1 mr-1 font-medium"},[_vm._v("Vitor")]),_c('font-awesome-icon',{attrs:{"icon":"angle-down"}})],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }