<template>
  <login-form>
    <template #form>
      <h2 class="text-lg font-medium mb-2">{{titles.title}}</h2>
      <p class="text-sm mb-6">
        {{titles.subtitle}}
      </p>
      <div class="v-group">
        <transition name="fade" mode="out-in">
          <!-- Passo 1 -->
          <form v-if="step === 1" key="step_1" ref="step_1" class="mb-8">
            <c-input
              v-model="step_1.nome"
              label="Nome completo"
              placeholder="Nome e sobrenome"
              required
              :class="{ invalid: formValidation && $v.step_1.nome.$invalid }"
            />
            <c-input
              v-model="step_1.email"
              label="E-mail"
              placeholder="Seu email"
              type="email"
              required
              :class="{ invalid: formValidation && $v.step_1.email.$invalid }"
            />
            <c-input
              v-model="step_1.telefone"
              label="Telefone"
              placeholder="Seu email"
              type="tel"
              mask="tel"
              required
              :class="{ invalid: formValidation && $v.step_1.telefone.$invalid }"
            />
          </form>
          <!-- Se houver erro -->
          <div v-else-if="error">
            <img src="@/assets/img/notify.svg" class="w-48 mx-auto my-12" alt="Houve um problema!">
          </div>
          <!-- Passo 2 -->
          <form v-else-if="step === 2" key="step_2" ref="step_2" class="mb-8">
            <c-input
              v-model="step_2.senha"
              label="Sua senha"
              placeholder="Digite uma senha segura"
              type="password"
              :class="{ invalid: formValidation && $v.step_2.senha.$invalid }"
            />
            <c-input
              v-model="step_2.senha_confirmacao"
              label="Repita sua senha"
              placeholder="Confirme sua senha"
              type="password"
              :class="{
                invalid: formValidation && $v.step_2.senha_confirmacao.$invalid
              }"
            />
          </form>
          <!-- Sucesso -->
          <div v-else-if="step === 3">
            <img src="@/assets/img/confirmation.svg" class="w-64 mx-auto my-12" alt="Tudo certo!">
          </div>
        </transition>
        <!-- Botoes -->
        <template v-if="error">
          <c-button class="primary w-full" @click="resetForm()">Tentar novamente</c-button>
          <router-link to="/" class="c-link font-medium block text-center text-sm">
            Ir para a página inicial
          </router-link>
        </template>
        <template v-else-if="step !== 3">
          <c-button class="primary w-full" type="button" @click="submitForm()">
            Próximo
          </c-button>
          <router-link to="/entrar" class="c-link font-medium block text-center text-sm">
            Já possuo uma conta
          </router-link>
        </template>
        <template v-else>
          <router-link to="/" class="c-button block primary mb-4">
            Ir para a página inicial
          </router-link>
          <router-link to="/indicar" class="c-link font-medium block text-center text-sm">
            Quero indicar amigos
          </router-link>
        </template>
      </div>
    </template>
    <template #steps>
      <c-wizard vertical class="h-96 lg:flex hidden" :steps="registerSteps" :currentStep="step - 1"></c-wizard>
    </template>
  </login-form>
</template>

<script>
import { post } from "axios"
import CWizard from "../components/UI/CWizard.vue"
import CInput from "@/components/UI/CInput.vue"
import CButton from "@/components/UI/CButton.vue"
import LoginForm from "../components/LoginForm.vue"
import { required, minLength, sameAs } from "vuelidate/lib/validators"

export default {
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Cadastrar-se",
    meta: [
      {
        name: "description",
        content: "Café Inteligência Imobiliária atua em Santa Maria/RS e região, imóveis santa maria, Imobiliarias santa maria, Cadastrar"
      }
    ]
  },
  components: {
    CInput,
    CButton,
    LoginForm,
    CWizard
  },
  data() {
    return {
      formValidation: false,
      step: 1,
      error: false,
      step_1: {
        nome: "",
        email: ""
      },
      step_2: {
        senha: "",
        senha_confirmacao: ""
      }
    }
  },
  validations: {
    step_1: {
      nome: { required },
      email: { required },
      telefone: { required }
    },
    step_2: {
      senha: { required, minLength: minLength(6) },
      senha_confirmacao: { sameAsSenha: sameAs("senha") }
    }
  },
  computed: {
    registerSteps() {
      return [
        {
          name: "Informe seus dados"
        },
        {
          name: "Escolha uma senha"
        },
        {
          name: "Tudo pronto! Agora é só aproveitar"
        }
      ]
    },
    titles() {
      if (this.error) {
        return {
          title: "Houve um problema!",
          subtitle: this.error
        }
      } else if (this.step === 3) {
        return {
          title: "Legal! Está tudo pronto!",
          subtitle: "A Café Imobiliária está sempre aqui para te ajudar."
        }
      }
      return {
        title: "Seja bem-vindo(a)!",
        subtitle: "Faça um cadastro para ter acesso à mais vantagens. É rapidinho!"
      }
    }
  },
  methods: {
    resetForm() {
      this.step = 1
      this.error = false
    },
    submitForm() {
      const step = `step_${this.step}`
      if (this.$v[step].$invalid || !this.$refs[step].reportValidity()) {
        this.formValidation = true
        return false
      }
      if (this.step === 1) {
        this.step = 2
      } else {
        post("/registrar", {
          /* Manda todos os dados */
          ...this.step_1,
          ...this.step_2
        }).then(() => {
          this.step = 3
        }).catch(error => {
          this.error = error
        })
      }
    }
  }
}
</script>
