<template>
  <button
    class="c-button"
    :class="{ circle: circle }"
    v-bind="{ ...$attrs }"
    @click="$emit('click')"
  >
    <font-awesome-icon v-if="icon" :icon="icon" />
    <span v-if="$slots.default"><slot /></span>
  </button>
</template>

<script>
export default {
  props: {
    circle: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="postcss">
.c-button {
  @apply text-center rounded-full bg-white border-2 border-solid border-gray-300 text-gray-600 px-6 py-3 text-sm font-medium whitespace-no-wrap;
  transition-timing-function: ease-in;
  transition-property: background-color, color, border-color;
  transition-duration: 125ms;

  &:hover {
    @apply bg-gray-100 border-gray-400;
  }

  & + * {
    margin-left: 0.5rem;
  }

  &.circle {
    @apply p-0 bg-gray-300 border-gray-300 text-gray-800;
    width: auto;
    height: 49px;
    width: 49px;

    &:hover {
      @apply bg-primary-100 border-primary-100 text-primary-500;
    }
  }

  &.primary {
    @apply bg-primary-500 border-primary-500 text-white;
    &:hover {
      @apply bg-primary-400 border-primary-400 text-white;
    }
  }
  &.secondary {
    @apply bg-white;
    &:hover {
      @apply bg-primary-100 border-primary-400 text-primary-400;
    }
  }

  &.red {
    &:hover {
      svg {
        @apply text-red-500;
      }
    }
    &:focus {
      svg {
        @apply text-red-500;
      }
    }
  }

  &:disabled {
    @apply border-gray-300 bg-gray-300 text-gray-500;
    cursor: not-allowed;

    &:hover {
      @apply bg-gray-300 border-gray-300;
    }
  }

  @media screen and (max-width: 768px) {
    span {
      @apply text-xs;
    }
  }

  @media screen and (min-width: 768px) {
    svg + span {
      @apply ml-1;
    }

  }
}
</style>
