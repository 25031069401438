<template>
  <div class="login-form flex justify-center items-center">
    <div class="login-form__bg w-1/2 h-full flex absolute top-0 left-0">
      <img
        src="@/assets/img/login-bg.jpg"
        alt="login-form background"
        class="object-cover"
      />
    </div>
    <div class="relative z-10 w-full flex">
      <div class="flex-1 p-12" />
      <form
        ref="form"
        class="login-form__holder w-96 flex-none bg-white rounded p-8 shadow-lg"
        @submit.prevent
      >
        <slot name="form" :form="$refs.form" />
      </form>
      <div class="flex-1 p-12 flex items-center">
        <slot name="steps" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="postcss">
.login-form {
  height: calc(100vh - 80px);

  &__bg {
    background: #333;

    img {
      opacity: 0.6;
    }
  }
}
</style>
