<template>
  <div class="c-wizard" :class="{ vertical: vertical }">
    <div class="c-wizard__wrapper">
      <template v-for="(step, index) in steps">
        <div
          class="c-wizard__item"
          :class="{
            active: currentStep === index,
            done: currentStep > index
          }"
          :key="`item-${index}`"
        >
          <span class="c-wizard__counter">
            {{ index + 1 }}
          </span>
          <span class="c-wizard__step hidden md:inline-block">
            {{ step.name }}
            <p class="block w-2/3 font-normal" v-if="step.subtext">{{ step.subtext }}</p>
          </span>
        </div>
        <div class="c-wizard__line" :key="`item-line-${index}`"></div>
      </template>
    </div>
    <div
      class="text-xs font-medium text-primary-500 text-center mt-2 md:hidden block"
    >
      {{ steps[currentStep].name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      default() {
        return []
      }
    },
    currentStep: {
      type: Number,
      default: 0
    },
    vertical: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="postcss">
.c-wizard {
  &__wrapper {
    @apply flex items-center;
  }

  &__line {
    height: 2px;
    @apply flex-1 bg-gray-300 mx-2;

    &:last-child {
      display: none;
    }
  }
  &__item {
    @apply flex flex-none justify-center items-center text-xs font-medium;

    ^&__counter {
      @apply w-8 h-8 rounded-full flex-none flex items-center justify-center font-medium text-xs text-white border-4 border-solid bg-gray-500 border-gray-300 mr-2;
    }

    &.done {
      ^^&__counter {
        @apply bg-green-500 border-primary-100;
      }
    }

    &.active {
      ^^&__counter {
        @apply bg-primary-500 border-primary-100;
      }
    }
  }

  &.vertical {
    @apply flex my-auto;
    ^&__wrapper {
      @apply flex-col items-start justify-center h-auto;
    }

    ^&__line {
      width: 2px;
      margin: 0.2rem 0.95rem;
    }
  }
}
</style>
