<template>
  <div class="property__gallery" ref="propertyWrapper">
    <carousel class="property__carousel"
      :perPage="perPage"
      :perPageCustom="perPageCustom"
      :paginationEnabled="true"
      :navigationEnabled="true"
      navigationNextLabel="<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='angle-right' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' class='svg-inline--fa fa-angle-right fa-w-8'><path fill='currentColor' d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z' class=''></path></svg>"
      navigationPrevLabel="<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='angle-left' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' class='svg-inline--fa fa-angle-left fa-w-8'><path fill='currentColor' d='M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z' class=''></path></svg>">
      <!-- <div class="indice-galeria c-button-2 primary">
        <label>{{ slides.length }} fotos</label>
      </div> -->
      <slide class="property__slide" v-for="(slide, index) in slides" :key="index">
        <router-link :to="linkregistro" :title="title" target="_blank" style="width: 100% !important">
          <c-button v-if="index === 3" aria-hidden="true" alt="Imovel" class="btn btn-primary" style="
            cursor: pointer;
            position: absolute;
            border-radius: 8px !important;
            /* top: 200px; */
            left: 0 !important;
            top: 54%;
            opacity: .9;
            -webkit-filter: drop-shadow(5px 5px 5px #222);
            filter: drop-shadow(5px 5px 5px #222);
            margin-left: 68% !important;
            /* margin-bottom: 5%; */
            /* width: 50% !important; */
          "> Ver mais <br>fotos</c-button>
          <img :src="slide" aria-hidden="true" alt="Imovel" style="cursor: pointer;" />
        </router-link>
        <!-- <img v-else :src="slide" aria-hidden="true" alt="Imovel" @click="showImage = index" style="cursor: default;" /> -->
        <!-- <label v-if="index === slides.length - 1">vai </label> -->
      </slide>
    </carousel>
    <!-- <CoolLightBox
      :items="slides"
      :index="showImage"
      :loop="true"
      @close="showImage = null"
    >
    </CoolLightBox> -->
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel"
import CButton from "../components/UI/CButton.vue"
// import Lightbox from "vue-easy-lightbox"

export default {
  components: {
    Carousel,
    Slide,
    CButton
    // Lightbox
  },
  props: {
    slides: {
      type: Array,
      default() {
        return []
      }
    },
    type: {
      type: String,
      default: "img"
    },
    perPage: {
      type: Number,
      default: 1
    },
    linkregistro: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: "Ver Imóvel"
    }
  },
  computed: {
    perPageCustom() {
      return [0, 1]
    }
  },
  data() {
    return {
      showImage: false
    }
  }
}
</script>

<style lang="postcss" scoped>
.property {
  &__slide {
    @apply flex justify-center items-center;
    height: 330px;
    /* max-width: fit-content; */
    width: 100%;

    &+& {
      @apply ml-1;
    }

    background-color: lightgray !important;
    border-left:1px solid white;
    border-right:1px solid white;
    /* background-color: #000 !important; */
    /* border-left: 25px solid #fff; */
    /* .contemimagem {
    background-color: #000;
    width: 100%;

    } */
    box-sizing: border-box;

    img {
      @apply object-cover;
      /* width: fit-content; */
      /* max-height: 100%; */
      width: 100% !important;
      height: 340px;
      /* height: 340px;
      max-height: 340px;
      min-height: 340px; */
      object-position: center;
      /* object-fit: contain; */
      /* padding: 1%; */
      /* height: 340px;
      min-height: 340px;
      height: 140; */
      /* width: auto;
      height: auto; */
    }

    iframe {
      @apply object-cover;
      width: 100% !important;
      position: absolute;
      max-height: 100%;
      left: 0px;
      background-color: #000;
      height: 330px;
    }
  }

  .property__slide +.property__slide {
    margin-left: 0 !important;
  }
  .galeria-card+.property__slide {
    margin-left: 0 !important;
  }

  .toolbar-btn__rotate {
    display: none;
  }

  .btn__prev,
  .btn__next,
  .btn__close {
    background-color: #4062d1 !important;
    border-color: #4062d1;
    color: #fff;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    white-space: nowrap;
    border-radius: 20px;
  }
}

/* .c-button + * {
    margin-left: 0rem;
} */
.c-button-2 {
  text-align: center;
  border-radius: 9999px;
  background-color: #fff;
  border-width: 2px;
  border-style: solid;
  border-color: #e2e8f0;
  color: #718096;
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  -webkit-transition-property: background-color, color, border-color;
  transition-property: background-color, color, border-color;
  -webkit-transition-duration: 125ms;
  transition-duration: 125ms;
}

.c-button-2.primary {
  background-color: #1F362E;
  border-color: #1F362E;
  color: #fff;
}

.cool-lightbox .cool-lightbox__slide img {
  /* width: 100% !important;
    height: 100% !important; */
  max-width: 100% !important;
  max-height: 100% !important;
}

.cool-lightbox.cool-lightbox--is-zooming .cool-lightbox__slide img {
  width: 100% !important;
  height: auto !important;
  max-width: none !important;
  max-height: none !important;
  cursor: all-scroll !important;
}
.property__slide + .property__slide {
  margin-left: 0 !important;
  width: 100% !important;
}
.property__slide {
  margin-left: 0 !important;
  width: 100% !important;
}
/* .VueCarousel-navigation-button {
    background-color: red !important;
    border: 3px solid white !important;
} */
</style>
