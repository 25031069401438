<template>
  <div class="property-features flex flex-wrap -mx-2 mt-10">
    <template v-for="(value, index) in featureValues">
      <div class="w-1/2 sm:w-1/5 px-2 py-3 pt-5" :key="index" v-if="value" style="background-color: white">
        <div v-if="value" class="property-features__card w-full text-xs lg:text-base">
          <font-awesome-icon
            :icon="featureTypes[index].icon"
            class="text-xl lg:text-2xl mt-auto mb-4 text-gray-700"
          />
          <span class="mb-2">
            <span class="block text-xs">{{ featureTypes[index].desc }}</span>
            <strong class="text-primary-500 text-sm">
              {{ featureTypes[index].output(value) }}
            </strong>
          </span>
        </div>
      </div>
    </template>
    <div v-if="featureFilter.length % 2 > 0" class="w-1/2 sm:w-1/5 px-2 py-3" style="background-color: white">
      <!-- {{ featureFilter.length  }} -->
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import CCardWrapper from "./UI/CCardWrapper.vue"

export default {
  components: { CCardWrapper },
  props: {
    tipo: {},
    tipoglobal: {},
    featureValues: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      iconesalaquartos: "bed",
      labelsalaquartos: "Dormitórios"
    }
  },
  computed: {
    retornacasose() {
      var retorno = {
        icon: "bed",
        label: "Dormitórios"
      }
      var arrtiposcomerciais = ["Sala Comercial", "Casa Comercial", "Loja"]
      if (arrtiposcomerciais.indexOf(this.tipo) !== -1) {
        retorno = {
          icon: "door-open",
          label: "Salas"
        }
      }
      return retorno
    },
    featureFilter() {
      var retorno = []
      this.featureValues.forEach(element => {
        if (element) {
          retorno.push(element)
        }
      })
      return retorno
    },
    featureTypes() {
      return [
        {
          icon: this.retornacasose.icon,
          output: d => {
            var cont = ""
            if (d[1] > 1) {
              cont = "s"
            }
            const suites = d[1] ? `(${d[1]} suíte${cont})` : ""
            return `${d[0]} ${suites}`
          },
          desc: this.retornacasose.label
        },
        {
          icon: "shower",
          output: v => v,
          desc: "Banheiros"
        },
        {
          icon: "car",
          output: v => `${v}`,
          desc: "Garagem"
        },
        {
          icon: "sun",
          output: name => name,
          desc: "Posição solar"
        },
        {
          icon: "chess-board",
          output: name => name,
          desc: "Tipo de piso"
        },
        {
          icon: "door-open",
          output: name => name,
          desc: "Situação"
        },
        {
          icon: "sort",
          output: name => name,
          desc: "Elevador"
        },
        {
          icon: "expand-alt", // ruler-combined
          output: d => {
            const comprimento = d[1] ? `x ${d[1]}` : ""
            return `${d[0]} ${comprimento} m`
          },
          desc: "Medidas do terreno"
        },
        {
          icon: "ruler-combined",
          // output: v => `${v} m²`,
          output: d => {
            const valore = Number(d) > 10000 ? Number(d / 10000) : d
            const medida = Number(d) > 10000 ? "ha" : "m²"
            return `${valore} ${medida}`
          },
          desc: this.tipoglobal
        },
        {
          icon: "cogs", // ruler-combined
          output: d => {
            const mesentrega = d[1] ? `${d[1]}` : ""
            const anoentrega = d[2] ? `${d[2]}` : ""
            // return `${mesentrega}${anoentrega}`
            if (Number(anoentrega) === 2022) {
              return `${anoentrega}`
            } else if (Number(anoentrega) > 2022) {
              return `${mesentrega}/${anoentrega}`
            } else if (Number(anoentrega) < 2022) {
              return null
            }
          },
          desc: "Em construção"
        }
      ]
    }
  }
}
</script>

<style lang="postcss">
.property-features {
  &__card {
    @apply text-center flex flex-col items-center;
  }
}
</style>
