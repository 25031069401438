import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Vuelidate from "vuelidate"
import Snotify from "vue-snotify"
import "vue-snotify/styles/material.css"
import "vue-snotify/styles/dark.css"
// import infiniteScroll from "vue-infinite-scroll"
// import Lightbox from "vue-easy-lightbox"
import Nl2br from "vue-nl2br"
import VueMeta from "vue-meta"
import ReadMore from "vue-read-more"
import VModal from "vue-js-modal"
import VueFab from "vue-float-action-button"
import * as VueGoogleMaps from "vue2-google-maps"
import GmapCluster from "vue2-google-maps/dist/components/cluster"
import ToggleButton from "vue-js-toggle-button"

import VueSocialSharing from "vue-social-sharing"
import Share from "vue-social-share"
import CoolLightBox from "vue-cool-lightbox"

import Vue2TouchEvents from "vue2-touch-events"

import "@/assets/styles/index.css"

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faCircle as farCircle } from "@fortawesome/free-regular-svg-icons"
import {
  faTimes,
  faShower,
  faBed,
  faCheckCircle,
  faCar,
  faBuilding,
  faPhone,
  faCalendar,
  faRulerCombined,
  faCamera,
  faMapMarkerAlt,
  faCircle,
  faVideo,
  faHeart,
  faThumbsUp,
  faSearch,
  faCheck,
  faCircleNotch,
  faCaretLeft,
  faCaretRight,
  faAngleDown,
  faQuoteLeft,
  faBars,
  faShare,
  faSort,
  faSun,
  faChessBoard,
  faDoorOpen,
  faSlidersH,
  faHandsHelping,
  faExternalLinkAlt,
  faInfoCircle,
  faUser,
  faHome,
  faHouseUser,
  faHandshake,
  faPeopleArrows,
  faChevronDown,
  faChevronUp,
  faEnvelope,
  faUserCircle,
  faExpandAlt,
  faPaperPlane,
  faComments,
  faCog,
  faCogs
} from "@fortawesome/free-solid-svg-icons"
import {
  faTwitter,
  faInstagram,
  faPinterestP,
  faFacebookF,
  faWhatsapp,
  faYoutube
} from "@fortawesome/free-brands-svg-icons"

import TawkMessengerVue from "@tawk.to/tawk-messenger-vue-2"

Vue.use(Vuelidate)

library.add(
  faTimes,
  faShower,
  faBed,
  faCheckCircle,
  faCar,
  faBuilding,
  faPhone,
  faCalendar,
  faRulerCombined,
  faCamera,
  faMapMarkerAlt,
  faCircle,
  farCircle,
  faVideo,
  faHeart,
  faThumbsUp,
  faSearch,
  faTwitter,
  faWhatsapp,
  faPinterestP,
  faFacebookF,
  faCheck,
  faCaretLeft,
  faCaretRight,
  faAngleDown,
  faCircleNotch,
  faQuoteLeft,
  faBars,
  faShare,
  faSort,
  faSun,
  faChessBoard,
  faDoorOpen,
  faSlidersH,
  faHandsHelping,
  faExternalLinkAlt,
  faInfoCircle,
  faUser,
  faHome,
  faHouseUser,
  faHandshake,
  faPeopleArrows,
  faEnvelope,
  faUserCircle,
  faInstagram,
  faYoutube,
  faExpandAlt,
  faPaperPlane,
  faComments,
  faCog,
  faCogs,
  faChevronDown,
  faChevronUp
)

Vue.component("font-awesome-icon", FontAwesomeIcon)
Vue.component("nl2br", Nl2br)
Vue.component("GmapCluster", GmapCluster)
Vue.component("gmap-cluster", VueGoogleMaps.Cluster)

Vue.config.productionTip = false

Vue.use(Snotify)
Vue.use(VueSocialSharing)
Vue.use(Share)
// Vue.use(infiniteScroll)
// Vue.use(Lightbox)
Vue.use(CoolLightBox)
Vue.use(Vue2TouchEvents)

Vue.use(VueMeta, {
  keyName: "metaInfo",
  refreshOnceOnNavigation: true
})

Vue.use(ReadMore)
Vue.use(VModal, { dialog: true })
Vue.use(VueFab)
Vue.use(ToggleButton)

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCR9FwyzDOW4fOSvD_iX9xDQ2tHKT4Gnzs",
    libraries: "places"
  }
})

Vue.use(TawkMessengerVue, {
  propertyId: "64b69bfbcc26a871b0292c34",
  widgetId: "1h5kl2k29"
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
