<template>
  <div class="property-search">
    <main-header />
    <c-section
      class="property-search__wrapper p-0 flex w-full"
      :container="false"
    >
      <div class="property-search__scrollable lg:w-5/5 sm:w-full">
        <transition name="fade-slide" mode="out-in">
          <!-- <aside
            class="property-search__drawer v-group"
            v-if="showFilters"
            :key="1"
          >
            <property-search-filters @close="showFilters = !showFilters" />
          </aside> -->

          <section
            class="property-search__drawer py-0"
            v-if="!showFilters"
            :key="2"
          >
            <!-- <div
              class="property-search__title sticky z-20 px-4 bg-white flex-col lg:flex-row text-center lg:text-left"
            > -->
            <div
              class="property-search__title px-4 bg-white sticky z-20 flex-col lg:flex-row text-center lg:text-left"
            >
              <div class="md:w-1/2 xl:mr-auto md:mb-0 mb-2">
                <h3 class="text-sm font-medium md:text-lg md:font-normal">
                  Empreendimentos em
                  <span class="text-primary-500">Santa Maria e Região</span>
                </h3>
                <small>{{ contador_empreendimentos.length }} encontrados</small>
              </div>
              <div class="md:w-1/4 w-full flex justify-center">
                <c-select
                placeholder="Ordenar por"
                :options="formOptions.ordenacao_cond"
                class="text-xs w-full mr-2 mb-0"
                  v-model="ordenador"
                  @input="ordenarresultados"
                ></c-select>
                <!-- <c-button
                  class="w-1/2 text-xs rounded-lg primary"
                  @click="showFilters = !showFilters"
                  icon="sliders-h"
                  >
                  Filtros
                </c-button> -->
              </div>
              <div class="md:w-1/4 w-full flex justify-center">
                <c-select
                  placeholder="Ver os imóveis do Empreendimento"
                  :options="todosempreendimentos"
                  class="text-xs w-full mr-2 mb-0"
                  v-model="nome_empreendimento"
                  @input="vaiproempreendimento"
                ></c-select>
              </div>
            </div>
            <div class="flex flex-wrap">
              <div :class="gridClasses" v-for="item in pesquisaempreendimentos" :key="item.codigo">
                <!-- v-bind:imagem="item.imagens[0].link" -->
                <property-card
                  horizontal
                  class=""
                  v-bind:pagina="'empreendimento'"
                  v-bind:codigo="item.codigo"
                  v-bind:observacoes="item.observacoes"
                  v-bind:imagem="''"
                  v-bind:tags="''"
                  v-bind:nome="item.nome"
                  v-bind:maior_valor_venda="item.extras.mais_caro_venda"
                  v-bind:menor_valor_venda="item.extras.mais_barato_venda"
                  v-bind:maior_valor_locacao="item.extras.mais_caro_locacao"
                  v-bind:menor_valor_locacao="item.extras.mais_barato_locacao"
                  v-bind:mais_dormitorios="item.extras.mais_dormitorios"
                  v-bind:menos_dormitorios="item.extras.menos_dormitorios"
                  v-bind:mais_garagens="item.extras.mais_garagens"
                  v-bind:menos_garagens="item.extras.menos_garagens"
                  v-bind:maior_area="item.extras.maior_area"
                  v-bind:menor_area="item.extras.menor_area"
                  v-bind:quantos_imoveis="item.extras.quantos_imoveis"
                />
              </div>
              <infinite-loading @infinite="paginando" spinner="spiral">
                <div slot="spinner">Loading...</div>
                <div slot="no-more">Fim da listagem</div>
                <div slot="no-results">Sem resultados</div>
              </infinite-loading>
            </div>
          </section>
        </transition>
      </div>
      <!-- <div class="w-2/5 hidden lg:block">
        <google-map :lat="-23.232" :lng="23.323"></google-map>
      </div> -->
    </c-section>
  </div>
</template>

<script>
// import PropertySearchFilters from "../components/PropertySearchFilters.vue"
// import GoogleMap from "../components/GoogleMap.vue"
import PropertyCard from "../components/PropertyCard.vue"
import CSection from "../components/UI/CSection.vue"
// import CButton from "../components/UI/CButton.vue"
import CSelect from "../components/UI/CSelect.vue"
import MainHeader from "../components/MainHeader.vue"
import EstadoCidadeMixin from "../mixins/EstadoCidadeMixin"
import FormOptionsMixin from "../mixins/FormOptionsMixin"
import InfiniteLoading from "vue-infinite-loading"
import axios from "axios"

export default {
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Empreendimentos",
    meta: [
      {
        name: "description",
        content: "Café Inteligência Imobiliária atua em Santa Maria/RS e região, imóveis santa maria, Imobiliarias santa maria, Empreendimentos em Santa Maria"
      }
    ]
  },
  components: {
    MainHeader,
    CSelect,
    // CButton,
    CSection,
    InfiniteLoading,
    PropertyCard
    // GoogleMap,
    // PropertySearchFilters
  },
  mixins: [EstadoCidadeMixin, FormOptionsMixin],
  data() {
    return {
      carregandoitens: true,
      showFilters: false,
      windowWidth: 0,
      gridClasses: "px-2 pb-4 xl:w-1/2 md:w-1/2 xs:w-1/1",
      empreedimento: {
        tipo: null,
        nome: "Condomínio Residencial Victória Park",
        quantidade_imoveis: 5,
        garagens: [2, 3],
        dormitorios: [2, 3],
        area: [200, 230],
        preco: [37500000, 48000000],
        descricao:
          "São 270m de área construída, distribuídos em 4 dormitórios, sendo 3 suítes amplas bem arejadas. Espaços com ótima distribuição, sala com rebaixo de gesso, com ótima iluminação natural.",
        tags: [{ nome: "Na planta", cor: "pink" }]
      },
      pesquisaempreendimentos: [],
      contador_empreendimentos: [],
      paginas: [],
      page: 1,
      initpage: 1,
      fimdpage: 10,
      busy: false,
      ordenador: [],
      nome_empreendimento: [],
      todosempreendimentos: []
    }
  },
  computed: {
    isMobile() {
      return this.windowWidth < 1024
    }
  },
  created() {
    var tipos = "Todos"
    if (this.tipo_imovel && this.tipo_imovel !== undefined && this.tipo_imovel !== "null") {
      tipos = this.tipo_imovel.split(",")
    }
    axios.get("condominios.json")
      .then((response) => {
        this.imoveis_destaque = response.data
        // console.log(this.imoveis_destaque)
        this.contador_empreendimentos = this.imoveis_destaque.filter(
          d => (tipos === "Todos" || tipos.includes(d.subtipo) || tipos.includes(d.tipo))
        )
        this.pesquisaempreendimentos = this.contador_empreendimentos.slice(0, this.fimdpage)
        this.contador_empreendimentos.forEach(pin => {
          // Só os que tem imóveis
          if (pin.extras.quantos_imoveis > 0) {
            this.todosempreendimentos.push({
              id: pin.nome,
              label: pin.nome
            })
          }
        })
        // console.log(this.pesquisaempreendimentos)
      })
  },
  methods: {
    updateWidth({ target }) {
      this.windowWidth = target.innerWidth
    },
    vaiproempreendimento() {
      window.location.href = "/empreendimento?nome=" + this.nome_empreendimento
    },
    ordenarresultados() {
      // console.log(this.ordenador)
      if (this.ordenador === "maior_preco") {
        this.contador_empreendimentos.sort(function(a, b) {
          return b.extras.mais_caro_venda - a.extras.mais_caro_venda
        })
      }
      if (this.ordenador === "menor_preco") {
        this.contador_empreendimentos.sort(function(a, b) {
          return a.extras.mais_caro_venda - b.extras.mais_caro_venda
        })
      }
      if (this.ordenador === "mais_dormitorios") {
        this.contador_empreendimentos.sort(function(a, b) {
          return b.extras.mais_dormitorios - a.extras.mais_dormitorios
        })
      }
      if (this.ordenador === "mais_garagens") {
        this.contador_empreendimentos.sort(function(a, b) {
          return b.extras.mais_garagens - a.extras.mais_garagens
        })
      }
      if (this.ordenador === "maior_areautil") {
        this.contador_empreendimentos.sort(function(a, b) {
          return b.extras.maior_area - a.extras.maior_area
        })
      }
      if (this.ordenador === "mais_recentes") {
        this.contador_empreendimentos.sort(function(a, b) {
          return b.codigo - a.codigo
        })
      }
      this.pesquisaempreendimentos = this.contador_empreendimentos.slice(0, this.fimdpage)
      // return null
      // if (this.ordenador === "maior_preco") {
      //   this.pesquisacidades.sort(function(a, b) {
      //     return b.valor_venda - a.valor_venda
      //   })
      // }
    },
    paginando($state) {
      if (!this.showFilters) {
        this.busy = false
        this.page = this.page + 1
        this.paginando_pagina(this.page)
        $state.loaded()
        if (this.pesquisaempreendimentos.length === this.contador_empreendimentos.length && this.contador_empreendimentos.length > 0) {
          $state.complete()
        }
      }
    },
    paginando_pagina(pagina) {
      this.page = pagina
      this.initpage = (this.page - 1) * 15
      this.fimdpage = this.initpage + 15
      this.pesquisaempreendimentos = this.contador_empreendimentos.slice(0, this.fimdpage)
    }
  },
  mounted() {
    this.windowWidth = window.innerWidth
    this.$nextTick(() => window.addEventListener("resize", this.updateWidth))
  },
  beforeDestroy() { window.onscroll = null },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth)
  }
}
</script>

<style lang="postcss">
.property-search {
  @apply flex flex-col;
  height: calc(100vh - 79px);

  @media (max-width: 1023px) {
    height: calc(100%);
  }

  &__filter {
    @apply flex text-xs justify-center px-2 py-4 bg-gray-200 items-center;

    .property-search-filter {
      @apply flex text-xs;

      > div + div,
      > div + button {
        @apply ml-2;
      }
    }

    .c-select label,
    .c-input label,
    .c-button {
      @apply text-xs;
    }

    > div + button,
    > button + button {
      @apply ml-2;
    }
  }

  &__title {
    @apply flex items-center py-5 my-5;
    top: 0;

    @media (max-width: 1023px) {
      top: 79px;
    }
  }

  &__wrapper {
    min-height: 0;
    height: 100%;
  }

  &__scrollable {
    @media (min-width: 1023px) {
      overflow-y: auto;
    }
  }
}
</style>
