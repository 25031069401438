<template>
  <div class="indique-e-ganhe c-section">
    <div class="flex container mx-auto">
      <div class="w-3/5">
        <transition name="fade-slide" mode="out-in">
          <section class="step-1" v-if="step === 1" key="step-1">
            <h2 class="title text-left mb-4">
              Agora é hora de
              <br />
              indicar seus amigos!
            </h2>
            <p class="mb-4 max-w-lg">
              Insira um ou mais endereços de e-mail abaixo e pressione enter
              para confirmar!
            </p>
            <form class="max-w-md" @submit.prevent="addEmail()">
              <c-input
                label="E-mail"
                type="email"
                v-model="currentEmail"
                placeholder="Digite o e-mail"
              />
              <section class="flex flex-wrap emails -mx-1">
                <div
                  v-for="(email, index) in emails"
                  :key="index"
                  class="px-1 pb-2 w-1/2"
                >
                  <div class="w-full rounded bg-primary-100 text-primary-500 text-sm flex justify-between">
                    <span class="px-4 py-2">{{ email }}</span>
                    <button
                      class="h-100 px-3 py-2 hover:bg-primary-400 hover:text-white"
                      @click="removeEmail(index)"
                      title="Remover este e-mail"
                      type="button"
                    >
                      <font-awesome-icon icon="times" />
                    </button>
                  </div>
                </div>
              </section>
            </form>
            <button
              class="c-button inline-block primary mt-8 w-64"
              @click="step = 2"
            >
              Indicar
            </button>
          </section>
          <div v-else class="step-2 py-24" key="step-2">
            <h2 class="title text-left mb-4">
              Muito obrigado!
            </h2>
            <p class="mb-4 max-w-lg">
              Ficamos felizes pela suas indicações!<br> Agora é só aguardar e torcer para uma excelente negociação!
            </p>
            <router-link
              class="c-button inline-block primary mt-8 w-64"
              to="/"
            >
              Voltar a pagina inicial
            </router-link>
          </div>
        </transition>
      </div>
      <div class="w-2/5 flex justify-center align-center py-24 h-full">
        <img src="@/assets/img/hang-out.svg" alt="Indique e ganhe" />
      </div>
    </div>
  </div>
</template>

<script>
import CInput from "../components/UI/CInput.vue"
export default {
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Indicar a Café Inteligência Imobiliária",
    meta: [
      {
        name: "description",
        content: "Café Inteligência Imobiliária atua em Santa Maria/RS e região, imóveis santa maria, Imobiliarias santa maria, Indicar"
      }
    ]
  },
  components: { CInput },
  data() {
    return {
      step: 1,
      emails: [],
      currentEmail: ""
    }
  },
  computed: {},
  methods: {
    addEmail() {
      // console.log(this.currentEmail)
      this.emails.push(this.currentEmail)
    },
    removeEmail(index) {
      this.$delete(this.emails, index)
    }
  }
}
</script>
