<template>
  <div class="mb-4 c-checkbox-group group">
    <label class="block w-full mb-1 text-sm font-medium text-gray-800">
      {{ label }}
    </label>
    <c-checkbox
      class="block"
      v-for="option in options"
      :key="option"
      :type="type"
      :name="name"
      :label="option"
      v-model="model[0][model[1]]"
      :class="checkboxClass"
      :destaque="composicao"
      ></c-checkbox>
      <!-- :label="composicao ? option.label : option" -->
    </div>
</template>

<script>
import CCheckbox from "./CCheckbox"

export default {
  components: { CCheckbox },
  // data() {
  //   return {
  //     modelarmodel: []
  //   }
  // },
  props: {
    options: {
      type: Array,
      default() {
        return []
      }
    },
    label: {
      type: String,
      default: ""
    },
    model: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "checkbox"
    },
    checkboxClass: {
      type: String,
      default: ""
    },
    composicao: {
      type: Boolean,
      default: false
    }
  },
  created() {
    // if (this.composicao) {
    //   // console.log(this.model[0][this.model[1]][0].label)
    //   this.modelarmodel = this.model[0][this.model[1]][0].label
    // } else {
    //   this.modelarmodel = this.model[0][this.model[1]]
    // }
    // console.log(this.model[0][this.model[1]])
    // if (this.option === "calcadao") {
    //   this.option = "Calçadão"
    // }
  }
}
</script>
