var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"c-checkbox",class:[
    {
      radio: _vm.type === 'radio',
      checked:
        typeof _vm.checked === 'object'
          ? _vm.checked.includes(_vm.label)
          : _vm.checked === _vm.value || _vm.value === _vm.label
    },
    ..._vm.checkboxClass
  ]},[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentValue),expression:"currentValue"}],staticClass:"c-checkbox__input",attrs:{"name":_vm.name,"type":"checkbox"},domProps:{"value":_vm.label,"checked":Array.isArray(_vm.currentValue)?_vm._i(_vm.currentValue,_vm.label)>-1:(_vm.currentValue)},on:{"change":function($event){var $$a=_vm.currentValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.label,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.currentValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.currentValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.currentValue=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentValue),expression:"currentValue"}],staticClass:"c-checkbox__input",attrs:{"name":_vm.name,"type":"radio"},domProps:{"value":_vm.label,"checked":_vm._q(_vm.currentValue,_vm.label)},on:{"change":function($event){_vm.currentValue=_vm.label}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentValue),expression:"currentValue"}],staticClass:"c-checkbox__input",attrs:{"name":_vm.name,"type":_vm.type},domProps:{"value":_vm.label,"value":(_vm.currentValue)},on:{"input":function($event){if($event.target.composing)return;_vm.currentValue=$event.target.value}}}),_c('span',{staticClass:"c-checkbox__box"},[_c('font-awesome-icon',{staticClass:"text-xs",attrs:{"icon":_vm.type === 'radio' ? 'circle' : 'check'}})],1),(_vm.destaque)?_c('span',{staticClass:"c-checkbox__label c-checkbox__destaque"},[_vm._v(_vm._s(_vm.aparecelabel))]):_c('span',{staticClass:"c-checkbox__label"},[_vm._v(_vm._s(_vm.aparecelabel))])])
}
var staticRenderFns = []

export { render, staticRenderFns }