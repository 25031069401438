<template>
  <div class="indique-e-ganhe c-section">
    <c-modal v-if="showRules" @close="toggleRules()">
      <template #body>
        <strong>Regulamento</strong>
      </template>
    </c-modal>
    <div class="flex container mx-auto">
      <div class="w-3/5">
        <h2 class="title text-left mb-4">Indique e Ganhe</h2>
        <p class="mb-4 max-w-lg">
          Acreditamos e apostamos no trabalho colaborativo! Ganhe dinheiro
          indicando imóveis para venda. Receba R$50,00 por imóvel publicado e
          mais 1% da comissão gerada se o imóvel for vendido!
        </p>
        <button
          class="c-link mb-8 underline font-medium text-sm"
          @click="toggleRules()"
        >
          Leia o regulamento completo
        </button>
        <c-wizard vertical :steps="referalSteps" class="h-64" />
        <router-link
          class="c-button inline-block primary mt-8 w-64"
          to="/indicar"
        >
          Quero indicar
        </router-link>
      </div>
      <div class="w-2/5 flex justify-center align-center  py-24 h-full">
        <img src="@/assets/img/hang-out.svg" alt="Indique e ganhe" />
      </div>
    </div>
  </div>
</template>

<script>
import CModal from "../components/UI/CModal.vue"
import CWizard from "../components/UI/CWizard.vue"
export default {
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Indique e Ganhe",
    meta: [
      {
        name: "description",
        content: "Café Inteligência Imobiliária atua em Santa Maria/RS e região, imóveis santa maria, Imobiliarias santa maria, Indique e ganhe"
      }
    ]
  },
  components: {
    CWizard,
    CModal
  },
  data() {
    return {
      showRules: false
    }
  },
  computed: {
    referalSteps() {
      return [
        {
          name: "Cadastro",
          subtext: "Faça seu cadastro ou logue com sua conta"
        },
        {
          name: "Indicação",
          subtext:
            "Indique quantos imóveis quiser. Dos seus amigos, vizinhos, familiares..."
        },
        {
          name: "Resultados",
          subtext:
            "Receba seus resultados, assim que o imóvel for anunciado e/ou vendido"
        }
      ]
    }
  },
  methods: {
    toggleRules() {
      this.showRules = !this.showRules
    }
  }
}
</script>

<style></style>
