<template>
  <div class="anunciar-imovel">
    <main-header></main-header>
    <c-section>
      <div style="" title="Desocupação do imóvel">
        <p align="center" style="margin-bottom: 0.14cm; text-align: center">
          <font color="#2f4c6b">
            <font face="Museo Slab 700, serif">
              <font size="3" style="font-size: 17pt">
                <b>DESOCUPAÇÃO DE IMÓVEL ALUGADO</b>
              </font>
            </font>
          </font>
        </p>
        <br />
        <p align="justify" style="margin-bottom: 0cm"><br /></p>
        <p align="justify" style="margin-bottom: 0cm">
          Prezado cliente, para assegurar a correta entrega do imóvel, favor se
          atentar aos procedimentos abaixo:
        </p>
        <p align="justify" style="margin-bottom: 0cm"><br /></p>
        <p align="justify" style="margin-bottom: 0cm">
          A partir do prazo de aviso, o inquilino deverá entregar a unidade em
          no máximo 30 (trinta) dias, caso esse prazo seja extrapolado sem que o
          imóvel seja entregue, deverá renovar a notificação, mantendo sempre a
          antecedência mínima de 30 (trinta) dias.
        </p>
        <p align="justify" style="margin-bottom: 0cm"><br /></p>
        <p align="justify" style="margin-bottom: 0cm">
          Procedimentos para entrega do imóvel:
        </p>
        <p align="justify" style="margin-bottom: 0cm"><br /></p>
        <p align="justify" style="margin-bottom: 0cm">
          <b>VISTORIA DE SAÍDA:</b> A vistoria de saída é agendada após a entrega de chaves, com prazo de até 72h após a devolução e conforme agenda disponível.
            <br>
            A partir do prazo de aviso, que deverá ser enviado para o e-mail
            <font color="#0563c1"><u><a href="mailto:vistorias@cafeimobiliaria.com.br">vistorias@cafeimobiliaria.com.br</a></u></font>, o inquilino
            deverá entregar a unidade em no máximo 30 (trinta) dias, caso esse prazo seja extrapolado sem que o imóvel seja entregue, deverá renovar a notificação,
            mantendo sempre a antecedência mínima de 30 (trinta) dias.

        </p>
        <p align="justify" style="margin-bottom: 0cm"><br /></p>
        <p align="justify" style="margin-bottom: 0cm">
          <b>NEGATIVAS:</b>
          Deverá o inquilino apresentar documento comprovando o cancelamento
          e/ou quitação de todas contas dos serviços utilizados.
          <b></b>
        </p>
        <p align="justify" style="margin-bottom: 0cm"><br /></p>
        <p align="justify" style="margin-left: 1.25cm; margin-bottom: 0cm">
          <b>LUZ e GÁS (se aplicável):</b>
        </p>
        <p align="justify" style="margin-left: 1.75cm; margin-bottom: 0cm">
          Contatar a RGE SUL pelo telefone 0800.7077272 ou
          <font color="#0563c1">
            <u><a href="http://www.rgesul.com.br/">www.rgesul.com.br</a></u>
          </font>
        </p>
        <p align="justify" style="margin-left: 1.75cm; margin-bottom: 0cm">
          Contatar a ULTRAGÁZ pelo telefone 0800.8861616 ou
          <font color="#0563c1">
            <u><a href="http://www.ultragaz.com.br/">www.ultragaz.com.br</a></u>
          </font>
          .
        </p>
        <p align="justify" style="margin-left: 1.75cm; margin-bottom: 0cm">
          Com código do cliente em mãos solicitar a rescisão do fornecimento.
          Após pagamento, encaminhar a imobiliária o “código do cliente” para
          conferência.
          <br />
          <br />
        </p>
        <p align="justify" style="margin-left: 1.25cm; margin-bottom: 0cm">
          <b>ÁGUA (se aplicável):</b>
        </p>
        <p align="justify" style="margin-left: 1.75cm; margin-bottom: 0cm">
          Solicitar a CORSAN pelo telefone 0800.6466444 ou
          <font color="#0563c1">
            <u>
              <a href="http://www.corsan.rs.gov.br/">www.corsan.rs.gov.br</a>
            </u>
          </font>
          a última fatura residual para pagamento. Após quitação, encaminhar a
          imobiliária a negativa de débitos.
          <br />
          <br />
        </p>
        <p align="justify" style="margin-left: 1.25cm; margin-bottom: 0cm">
          <b>CONDOMÍNIO:</b>
        </p>
        <p align="justify" style="margin-left: 1.75cm; margin-bottom: 0cm">
          Solicitar a administradora do condomínio e/ou síndico a negativa de
          débitos até a data efetiva de entrega do imóvel. Caso a administradora
          não gere boleto para pagamento proporcional, será realizado uma média
          dos últimos 3 meses do condomínio.
        </p>
        <p align="justify" style="margin-left: 1.25cm; margin-bottom: 0cm">
          <br />
        </p>
        <p align="justify" style="margin-bottom: 0cm">
          <b>OBSERVAÇÕES IMPORTANTES:</b>
        </p>
        <p align="justify" style="margin-bottom: 0cm"><br /></p>
        <ul>
          <li>
            <p align="justify" style="margin-bottom: 0cm">
              No dia da vistoria do imóvel o locatário deverá portar a quantia
              de chaves/controles que foram recebidas inicialmente.
            </p>
          </li>
        </ul>
        <p align="justify" style="margin-left: 1.27cm; margin-bottom: 0cm">
          <a name="_GoBack"></a>
          <br />
        </p>
        <ul>
          <li>
            <p align="justify" style="margin-bottom: 0cm">
              O imóvel deverá ser entregue livre e desimpedido na mesma forma
              que foi recebido, conforme disposições constantes no laudo de
              vistoria de entrada. Caso a vistoria não seja aprovada e/ou as
              negativas não forem apresentadas, o
              <b>aluguel e seus encargos continuarão vigente</b>
              até a data em que todas as pendências forem sanadas.
            </p>
          </li>
        </ul>
        <p align="justify" style="margin-left: 1.27cm; margin-bottom: 0cm">
          <br />
        </p>
        <p align="right" style="margin-bottom: 0cm">
          <br />
          <br />
          <br />
        </p>
      </div>
    </c-section>
  </div>
</template>
<script>
import MainHeader from "../components/MainHeader.vue"
import CSection from "../components/UI/CSection.vue"

export default {
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Desocupação do Imóvel",
    meta: [
      {
        name: "description",
        content:
          "Aluga Digital, imóveis santa maria, Imobiliarias santa maria, Descocupação do imóvel alugado"
      }
    ]
  },
  components: {
    MainHeader,
    CSection
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>
