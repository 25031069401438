<template>
  <div id="app">
    <google-map :marcadores="marcadores" />
  </div>
</template>

<script>
import GoogleMap from "../components/GMap"

export default {
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Mapa",
    meta: [
      {
        name: "description",
        content: "Café Inteligência Imobiliária atua em Santa Maria/RS e região, imóveis santa maria, Imobiliarias santa maria, Mapa"
      }
    ]
  },
  name: "App",
  components: {
    GoogleMap
  },
  created() {
    this.marcadores = [
      { lat: -29.6911, lng: -53.8009, descr: "content: <strong>com tag 1!</strong>" },
      { lat: -29.6915, lng: -53.8009, descr: "content: <strong>com tag 2!</strong>" },
      { lat: -29.6913, lng: -53.8010, descr: "content: <strong>com tag 3!</strong>" },
      { lat: -29.6912, lng: -53.8011, descr: "content: <strong>com tag 4!</strong>" }
    ]
  }
}
</script>
