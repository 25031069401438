<template>
  <div class="c-carousel -mx-4 overflow-hidden">
    <carousel
      class="c-carousel-widget"
      :perPageCustom="perPageCustomConfig"
      :paginationEnabled="false"
      :navigationEnabled="true"
      navigationNextLabel="<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='angle-right' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' class='svg-inline--fa fa-angle-right fa-w-8'><path fill='currentColor' d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z' class=''></path></svg>"
      navigationPrevLabel="<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='angle-left' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' class='svg-inline--fa fa-angle-left fa-w-8'><path fill='currentColor' d='M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z' class=''></path></svg>"
    >
      <slide
        class="c-carousel-widget__slide px-4 py-8"
        v-for="(slot, index) in $slots"
        :key="index"
      >
        <slot :name="index" />
      </slide>
    </carousel>
    <aside class="slider no-scrollbar">
      <div
        v-for="(slot, index) in $slots"
        :key="index"
        class="slide flex-shrink-0 flex-grow-0"
      >
        <slot :name="index" />
      </div>
    </aside>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel"
export default {
  components: {
    Carousel,
    Slide
  },
  props: {
    perPageCustom: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    perPageCustomConfig() {
      const breakpoints = [0, 640, 768, 1024, 1280]
      return breakpoints.map((bp, index) => [
        bp,
        this.perPageCustom[index] || 4
      ])
    }
  }
}
</script>

<style lang="postcss">
.c-carousel {
  .slider {
    @apply py-8 px-4 overflow-x-scroll hidden;

    .slide {
      width: calc(100vw - 6em);
      min-width: 250px;
      padding-right: 1em;
    }
  }

  .VueCarousel-slide {
    user-select: auto;
  }

  @media (max-width: 768px) {
    .slider {
      @apply flex;
    }

    .c-carousel-widget {
      display: none;
    }
  }
}

</style>
