<template>
  <div class="c-checkbox-group-encadeado mb-4">
    <label
      class="text-sm text-gray-900 font-medium block mb-1 w-full"
      style="padding: 3px; text-align: left;"
    >
      {{ label }}
    </label>
    <div
    v-for="grupo in options"
    :key="grupo.label"
    class="w-full lg:w-1/3"
    >
      <label
      class="text-sm text-gray-800 font-medium block mb-1"
      >
        <c-checkbox @input="alterarselecionados(grupo.label)" :label="grupo.label" type="radio" name="selecionatudo" class="block" :class="checkboxClass + ' ' + grupo.label" />
        <!-- {{ grupo.label }} -->
      </label>
      <c-checkbox
        class="block"
        v-for="option in grupo.opcoes"
        :key="option"
        :type="type"
        :name="name"
        :label="option"
        v-model="model[0][model[1]]"
        :class="checkboxClass + ' ' + grupo.label"
      ></c-checkbox>
      <!-- @input="alterarselecionados(option)" -->
      <!-- v-model="model[0][model[1]]" -->
      <br />
    </div>
  </div>
</template>

<script>
import CCheckbox from "./CCheckbox"

export default {
  components: { CCheckbox },
  data() {
    return {
      selected: this.model[0][this.model[1]]
    }
  },
  props: {
    options: {
      type: Array,
      default() {
        return []
      }
    },
    label: {
      type: String,
      default: ""
    },
    model: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "checkbox"
    },
    checkboxClass: {
      type: String,
      default: ""
    }
  },
  methods: {
    alterarselecionados(ativar) {
      // console.log("$parent", this.$parent)
      this.$parent.alteratiposimoveis(ativar)
    }
  }
  // computed: {
  //   selectAll: {
  //     get: function () {
  //       return this.options ? this.model.length === this.options.length : false
  //     },
  //     set: function (value) {
  //       var selected = []
  //       if (value) {
  //         this.options.forEach(function (user) {
  //           selected.push(user)
  //         })
  //       }
  //       this.model = selected
  //     }
  //   }
  // }
}
</script>
