<template>
  <div>
    <!-- <div>
      <h2>Search and add a pin</h2>
      <label>
        <gmap-autocomplete
          @place_changed="setPlace">
        </gmap-autocomplete>
        <button @click="addMarker">Add</button>
      </label>
      <br/>

    </div>
    <br> -->
    <gmap-map
      :center="center"
      :zoom="options.zoom"
      style="width:100%;  height: 550px;"
    >
      <gmap-cluster :zoomOnClick="true">
        <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
        </gmap-info-window>
        <gmap-marker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            @click="toggleInfoWindow(m)"
            :icon="'/icones/marcador.png'"
        ></gmap-marker>
        <!-- :position="m.position"
        @click="center=m.position" -->
      </gmap-cluster>
    </gmap-map>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.0/vue.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/markerclustererplus/2.1.4/markerclusterer.js"></script>
<script>
export default {
  name: "GoogleMap",
  props: {
    marcadores: {},
  },
  data() {
    return {
      // default to Montreal to keep it simple
      // change this to whatever makes sense
      center: { lat: -29.6914, lng: -53.8008 },
      places: [],
      markers: [
        { position: { lat: -29.6911, lng: -53.8009}, infoText: "trste" }
      ],
      currentPlace: null,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: "",
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      options: {
          zoom: 12
      }
    }
  },
  mounted() {
    this.geolocate(-29.6914, -53.8008)
    // this.marcadores.forEach(pin => {
    //   this.addMarker(pin.lat, pin.lng, pin.descr)
    // })
    // var obj = this.marcadores
    // for (var [key, value] of Object.entries(this.marcadores)) {
    //   console.log(key + " = " + value.lat)
    //   this.addMarker(value.lat, value.lng, value.descr)
    // }
    // console.log("recebido!!!!")
    // console.log(this.marcadores)
  },
  methods: {
    // receives a place object via the autocomplete component
    setPlace(place) {
      this.currentPlace = place
    },
    addMarker(lat, lng, content) {
      const marker = {
        lat: lat,
        lng: lng
      }
      this.markers.push({ position: marker, infoText: content })
      this.places.push(this.currentPlace)
      this.center = marker
      this.currentPlace = null
    },
    geolocate: function(lat, lng) {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: lat,
          lng: lng
        }
      })
    },
    toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = marker.position
      this.infoOptions.content = marker.infoText
      // check if its the same marker that was selected if yes toggle
      // if (this.currentMidx === idx) {
      //   this.infoWinOpen = !this.infoWinOpen
      // } else {
      //   this.infoWinOpen = true
      //   this.currentMidx = idx
      // }
      this.infoWinOpen = true
      this.currentMidx = idx
    }
  }
}
</script>
<style lang="postcss">
  .aviso-mapa {
    position: absolute;
    bottom: 25px;
    /* left: 15%; */
    /* padding: 10px; */
    /* background-color: rgba(0, 0, 0, 0.5) !important; */
    color: black;
    /* border-radius: 10px; */
    /* border: 1px solid lime; */
    /* position: absolute; */
    /* top: 50%;/ */
    left: 30%;
    transform: translate(-50%, -50%);
    /* box-shadow: rgb(0 0 0 / 80%) 0px 1px 4px -1px; */
    /* text-align: center; */
  }
  .aviso-mapa label {
    color: white !important;
    /* text-shadow: 5px 5px 5px #000; */
    font-size: large;
    font-weight: bolder;
    -webkit-text-stroke: 1.5px gray;
    position: relative;
    bottom: 15px !important;
  }
  .imagem-do-mapa {
    height: 134px !important;
    width: 100% !important;
    margin-bottom: 8px;
  }
  .card-do-mapa {
    /* background-color: lightgray; */
    color: white;
    position: relative;
    display: inline-block; /* Make the width of box same as image */
    width: 130px;
    /* padding: 3px; */
    margin-bottom: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .texto-do-mapa {
    background: rgba(0, 0, 0, 0.5) !important;
    width: 100%; /* Set the width of the positioned div */
    height: 100%;
    text-align: center;
  }
  .card-do-mapa .texto-do-mapa {
    /* position: absolute; */
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0; /* Adjust this value to move the positioned div up and down */
    text-align: center;
  }
  .mapa-card-codigo {
    position: absolute;
    font-weight: bolder;
    /* color: red; */
    /* top: 1%; */
    font-size: 10px;
    background-color: #fff;
    padding: 2px;
    left: 0px;
    top: 0px;
    color: #000;
  }
  .mapa-card-dormitorios {
    font-weight: bolder;
    color: gray;
    top: 50%;
    font-size: 11px;
    position: relative;
    margin-left: 5px;
    float: left;
  }
  .mapa-card-garagens {
    font-weight: bolder;
    color: gray;
    top: 50%;
    font-size: 11px;
    position: relative;
    margin-left: 5px;
    float: left;
  }
  .mapa-card-terrenolargura {
    font-weight: bolder;
    color: gray;
    top: 50%;
    font-size: 11px;
    position: relative;
    margin-left: 5px;
    float: left;
  }
  .mapa-card-tipo {
    font-weight: bolder;
    color: gray;
    font-size: 12px;
    position: relative;
    /* text-align: left; */
    float: left;
    /* left: 10%; */
    /* top:; */
  }
  .mapa-card-valor {
    color: black;
    font-weight: bolder;
    top: 50%;
    font-size: 12px;
    position: relative;
    /* margin-left: 5px; */
    float: left;
  }
  /* .gm-ui-hover-effect {
    background: white !important;
    opacity: 1 !important;
    color: white !important;
    border-radius: 0 !important;
    position: absolute !important;
    z-index: 100000;
    padding-top: 2% !important;
    padding-left: -2px !important;
  } */
  .gm-style-iw-d {
    overflow-x: hidden !important;
  }
  .gm-style .gm-style-iw-c {
    padding: 10px !important;
  }
  .btn-sobre-mapa {
    /* border: 1px solid black !important; */
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    color: rgb(86, 86, 86) !important;
  }
</style>
