<template>
  <div class="property-cta md:flex justify-center">
    <div class="px-4 md:px-4 w-full md:w-1/2 mb-4 md:mb-0">
      <figure-card class="w-full" img="freelancer">
        <template #title>Encontre um lar</template>
        <template #content>
          <p>
            Encontre um imóvel com a sua cara.
            <br />
            São centenas de opções. Experimente!
          </p>
          <router-link :to="'/venda' + prelink" class="c-button primary inline-block mt-4">
            Encontrar
          </router-link>
        </template>
      </figure-card>
    </div>
    <div class="px-4 md:px-4 w-full md:w-1/2">
      <figure-card class="w-full m-0" img="post_online">
        <template #title>Anunciar imóvel</template>
        <template #content>
          <p>
            Seu imóvel terá um tratamento especial.
            <br />
            Maior visibilidade, mais chances de venda.
          </p>
          <router-link :to="'/anunciar-imovel' + prelink" class="c-button primary inline-block mt-4">
            Anunciar
          </router-link>
        </template>
      </figure-card>
    </div>
  </div>
</template>

<script>
import FigureCard from "./FigureCard.vue"
export default {
  components: {
    FigureCard
  },
  props: {
    prelink: { type: String }
  }
}
</script>

<style lang="postcss">
@media (max-width: 768px) {
  .property-cta {
    .c-card-wrapper + .c-card-wrapper {
      @apply ml-0 mt-4;
    }
  }
}
</style>
