<template>
  <div class="property-feature-list__wrapper">
    <ul
      class="property-feature-list"
      :class="{ show: showMore }"
    >
      <li
        class="property-feature-list__item lg:w-1/3 sm:w-1/2 w-full"
        v-for="(feature, index) in featureList"
        :key="index"
      >
        <font-awesome-icon icon="check-circle" :class="`text-${color}`" />
        <span>{{ feature }}</span>
      </li>
    </ul>
    <span
      class="show-more mt-2 block md:hidden c-link"
      @click="showMore = !showMore" v-if="!showMore && featureList.length > 5"
    >
      Ver mais
    </span>
  </div>
</template>

<script>
export default {
  props: {
    featureList: {
      type: Array,
      default() {
        return []
      }
    },
    color: {
      type: String,
      default: "green"
    }
  },
  data() {
    return {
      showMore: false
    }
  }
}
</script>

<style lang="postcss">
.property-feature-list {
  @apply flex flex-wrap;
  transition: max-height 100ms linear;

  &__item {
    @apply mb-2;

    span {
      @apply ml-2;
    }
  }

  @media (max-width: 768px) {
    max-height: 120px;
    overflow: hidden;

    &.show {
      max-height: 9999px;
    }
  }
}
</style>
