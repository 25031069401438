<template>
  <div class="sobre-nos">
    <main-header bg="coffee404.jpg">
      <c-section class="cashback-social__header">
        <h2>Termo de Privacidade</h2>
      </c-section>
    </main-header>
    <c-section class="sobre-nos__story relative" :container="false">
      <div class="container m-auto ">
        <div class="flex">
          <div class="">
            <p class="ct-u-paddingBottom20">
            A Política de Privacidade da Café Negócios Imobiliários Ltda. foi formulada com o intuito de manter a privacidade e a segurança das informações coletadas de nossos clientes e usuários.
            <p>
            A política de privacidade estabelecida pela Café Negócios Imobiliários Ltda. compreende a forma como as informações capazes de identificar nossos clientes e usuários são armazenadas e utilizadas, sejam as coletadas diretamente em nosso site, consultas ao serviço de atendimento ao cliente ou cadastro para recebimento de promoções ou newsletter.
            </p>
            <p>
            As informações de contato dos clientes e usuários poderão ser utilizados para campanhas publicitárias da Café Negócios Imobiliários Ltda. que enviará informações sobre os serviços, através de mensagens publicitárias como promoções, dicas, divulgação de parceiros e clientes que possam agregar algum valor ou interesse aos nossos assinantes.
            </p>
            <p>
            A Café Negócios Imobiliários Ltda. trará, em seus comunicados, newsletters, ofertas e mensagens publicitárias enviadas por e-mail a opção de cancelamento do envio deste tipo de mensagem. A solicitação será atendida no tempo mínimo necessário para sua execução.
            </p>
            <p>
            Sob nenhuma circunstância a Café Negócios Imobiliários Ltda. divulga ou comercializa informações pessoais ou dados cadastrais de seus clientes e usuários a terceiros.
            </p>
            <p>
            Para maiores informações sobre nossas políticas de privacidade, contate-nos através do contato@cafeinteligencia.com.br
            </p>
          </div>
        </div>
      </div>
    </c-section>
  </div>
</template>

<script>
// import PropertyCTA from "../components/PropertyCTA.vue"
// import FigureCard from "../components/FigureCard.vue"
import CSection from "../components/UI/CSection.vue"
import MainHeader from "../components/MainHeader.vue"
export default {
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Termo de Privacidade",
    meta: [
      { name: "description", content: "A Café Inteligência Imobiliária atua em Santa Maria/RS e região, na compra e venda de imóveis com foco na  transparência, agilidade e inovação, imóveis santa maria, Imobiliarias santa maria" }
    ]
  },
  components: {
    MainHeader,
    CSection
    // FigureCard,
    // PropertyCTA
  }
}
</script>

<style lang="postcss">
.sobre-nos {
  p + p {
    @apply mt-2;
  }

  &__text-bg {
    z-index: -1;

    img {
      max-width: none;
      width: auto;
      height: auto;
    }
  }

  /* @media (min-width: 1024px) {
    &__story {
      background: url("../assets/img/sobre-nos__story.jpg") top right no-repeat;
      min-height: 650px;
    }
  } */
}
</style>
