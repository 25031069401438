<template>
  <div class="c-range-slider">
    <label class="c-input__label" v-if="label">{{ label }}</label>
    <div class="flex group" v-if="multipleValues">
      <c-input class="w-full" v-model.lazy="firstValue" :money="money" />
      <c-input class="w-full" v-model.lazy="secondValue" :money="money" :sufixo="sufixo" />
      <!-- esquerda-encaixa -->
      <!-- <div class="c-input w-full c-input__field-wrapper eacima insert-border-left" v-if="sufixo !== ''">{{ sufixo }}</div>
      <div class="c-input w-full c-input__field-wrapper eacima" v-else>{{ sufixo }}</div> -->
    </div>
    <c-input v-else class="w-full" v-model.lazy="innerValue" :money="money" :placeholder="placeholder"/>
    <div class="flex justify-between text-xs">
      <span>Min. </span>
      <span>Máx. ({{ formatter(max) }} e acima)</span>
       <!-- (R$1.000.000 e acima) -->
    </div>
    <vue-slider
      class="md:mx-0 mx-8"
      v-model="innerValue"
      :min="min"
      :max="max"
      ref="vue-range-slider"
      tooltip="hover"
      :dot-size="20"
      :enable-cross="false"
      :clickable="false"
      :step="step"
      :lazy="true"
      :tooltipFormatter="formatter"
    />
  </div>
</template>

<script>
import CInput from "./CInput.vue"
import VueSlider from "vue-slider-component"
import "vue-slider-component/theme/default.css"

export default {
  components: {
    VueSlider,
    CInput
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    sufixo: {
      type: String,
      default: ""
    },
    value: {
      type: undefined,
      default: 0
    },
    max: {
      type: Number,
      default: 1000000
    },
    min: {
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 1000
    },
    money: {
      type: Boolean,
      default: false
    },
    formatter: {
      type: Function,
      default: value =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL"
        }).format(value)
    }
  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    },
    multipleValues() {
      return typeof this.value === "object"
    },
    firstValue: {
      get() {
        return this.multipleValues ? this.innerValue[0] : this.innerValue
      },
      set(newValue) {
        newValue = this.validateMaxMin(newValue)
        const currentValue = this.multipleValues
          ? this.innerValue[0]
          : this.innerValue
        if (newValue !== currentValue) {
          this.innerValue = [newValue, this.innerValue[1]]
        }
      }
    },
    secondValue: {
      get() {
        return this.multipleValues ? this.innerValue[1] : this.innerValue
      },
      set(newValue) {
        newValue = this.validateMaxMin(newValue)
        const currentValue = this.multipleValues
          ? this.innerValue[1]
          : this.innerValue
        if (newValue !== currentValue) {
          this.innerValue = [this.innerValue[0], newValue]
        }
      }
    }
  },
  methods: {
    validateMaxMin(value) {
      if (value > this.max) {
        return 0
      } else if (value < this.min) {
        return 0
      }

      return value
    }
  },
  mounted() {
    /* this.$refs["vue-range-slider"].refresh() */
  }
}
</script>

<style lang="postcss">
.c-range-slider {
  @apply mb-4;

  .vue-slider-process {
    @apply bg-primary-500 h-1;
  }

  .vue-slider-dot-tooltip-inner {
    @apply bg-primary-500 border-primary-500 text-xs;
  }
}
.eacima {
  line-height: 1.5;
  border: 1px solid lightgray;
  background-color: #fff;
  border-width: 2px;
  border-style: solid;
  border-color: #e2e8f0;
  border-radius: 0.5rem;
  color: #2d3748;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  border-left: 0px !important;
  margin-left: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  height: auto;
  width: 50% !important;
  /* text-align: left; */
}
.insert-border-left {
  border-left: 2px solid #e2e8f0 !important;
}
.esquerda-encaixa .c-input__field-wrapper {
  border-right: 0px !important;
  margin-right: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.esquerda-encaixa .c-input__field-wrapper .v-money {
  border-right: 0px !important;
  margin-right: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style>
