<template>
  <div class="sobre-nos">
    <main-header bg="coffee404.jpg">
      <c-section class="cashback-social__header">
        <h2>Página não encontrada!</h2>
        <p>
          Esse código de imóvel ou página não está ativo. Tente uma das opções abaixo.
        </p>
        <p>
          Gratidão!
        </p>
      </c-section>
    </main-header>
    <c-section class="sobre-nos__cta">
      <h3 class="text-center text-xl mb-24">
        Convidamos você para prestigiar uma excelente negociação.
        <br />
        O Café é por nossa conta.
      </h3>
      <property-CTA></property-CTA>
    </c-section>
  </div>
</template>

<script>
import PropertyCTA from "../components/PropertyCTA.vue"
// import FigureCard from "../components/FigureCard.vue"
import CSection from "../components/UI/CSection.vue"
import MainHeader from "../components/MainHeader.vue"
export default {
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Página não encontrada!",
    meta: [
      { name: "description", content: "A Café Inteligência Imobiliária atua em Santa Maria/RS e região, na compra e venda de imóveis com foco na  transparência, agilidade e inovação, imóveis santa maria, Imobiliarias santa maria" }
    ]
  },
  components: {
    MainHeader,
    CSection,
    // FigureCard,
    PropertyCTA
  }
}
</script>

<style lang="postcss">
.sobre-nos {
  p + p {
    @apply mt-2;
  }

  &__text-bg {
    z-index: -1;

    img {
      max-width: none;
      width: auto;
      height: auto;
    }
  }

  /* @media (min-width: 1024px) {
    &__story {
      background: url("../assets/img/sobre-nos__story.jpg") top right no-repeat;
      min-height: 650px;
    }
  } */
}
</style>
