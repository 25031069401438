import EstadosCidades from "@/helpers/EstadosCidades.json"

export default {
  methods: {
    cities(sigla) {
      const currentState = this.json.find(state => state.sigla === sigla).cidades

      return currentState.map(city => ({
        id: city,
        label: city
      }))
    }
  },
  computed: {
    json() {
      return EstadosCidades.estados
    },
    states() {
      return this.json.map(estado => ({
        id: estado.sigla,
        label: estado.nome
      }))
    }
  }
}
