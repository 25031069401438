<template>
  <div class="c-select" :class="{ invalid: invalid }">
    <label class="c-select__label" v-if="label">{{ label }}</label>
    <div class="c-select__field-wrapper" :class="{ active: isOpen }">
      <div class="c-select__field-icon" v-if="icon">
        <font-awesome-icon :icon="icon" />
      </div>
      <multiselect
        class="c-select__field"
        deselect-label=""
        select-label=""
        selected-label=""
        :placeholder="placeholder"
        :allowEmpty="multiple || false"
        :trackBy="trackBy"
        :label="optionName"
        :options="compfiltrados"
        :multiple="multiple"
        :limit="limit"
        :limitText="limitText"
        :close-on-select="closeOnSelect"
        v-model="currentValue"
        @open="isOpen = true"
        @close="isOpen = false"
        @search-change="novaprime"
        :internal-search = "false"
      >
        <template #tag="{ option, remove }">
          <span class="multiselect__tag">
            <!-- <span v-if="option[optionName][1].length > 1">{{ option[optionName][1] }}</span>
            <span v-else>{{ option[optionName] }}</span> -->
            {{ option[optionName] }}
            <button @click="remove(option)">
              <font-awesome-icon icon="times" />
            </button>
          </span>
        </template>
        <template #option="{ option }">
          <!-- <span v-if="option[optionName][1].length > 1">{{ option[optionName][1] }}</span>
          <span v-else>{{ option[optionName] }}</span> -->
          {{ option[optionName] }}
        </template>
        <!-- <template v-else #option="{ option }"> {{ option[optionName] }}</template> -->
        <template #noResult>Não há opções disponíveis.</template>
        <template #noOptions>Nenhuma opção encontrada.</template>
      </multiselect>
    </div>
    <span class="text-xs text-red-500" v-if="invalid">
      Este campo é obrigatório.
    </span>
  </div>
</template>

<script>
import multiselect from "vue-multiselect"
export default {
  components: {
    multiselect
  },
  props: {
    options: {
      type: Array,
      default() {
        return []
      }
    },
    label: {
      type: String,
      default: ""
    },
    optionName: {
      type: String,
      default: "label"
    },
    labelfilter: {
      type: [String, Number, Array],
      default: null
    },
    trackBy: {
      type: String,
      default: "id"
    },
    value: {
      type: [String, Number, Array],
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: "Selecione"
    },
    invalid: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 15
    },
    limitText: {
      type: Function,
      default: count => `e ${count} mais`
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpen: false,
      iniciou: false,
      filtrados: []
    }
  },
  computed: {
    currentValue: {
      get() {
        if (this.multiple) {
          return this.value
        }
        return this.options.find(opt => this.value === opt[this.trackBy])
      },
      set(value) {
        let newValue = value[this.trackBy]
        if (this.multiple) {
          newValue = value
        }
        this.$emit("input", newValue)
      }
    },
    compfiltrados: {
      get() {
        if (this.iniciou) {
          return this.filtrados
        } else {
          return this.options
        }
      }
    }
  },
  methods: {
    replaceSpecialChars(str) {
      str = str.replace(/[ÀÁÂÃÄÅ]/, "A")
      str = str.replace(/[àáâãäå]/, "a")
      str = str.replace(/[ÒÓÔÕÖ]/, "O")
      str = str.replace(/[òóôõö]/, "o")
      str = str.replace(/[ÈÉÊË]/, "E")
      str = str.replace(/[èéêë]/, "e")
      str = str.replace(/[ÙÚÜ]/, "U")
      str = str.replace(/[ùúü]/, "u")
      str = str.replace(/[ÌÍÏ]/, "I")
      str = str.replace(/[ìíï]/, "i")
      str = str.replace(/[Ç]/, "C")
      str = str.replace(/[ç]/, "c")
      return str.replace(/[^a-z0-9]/gi, "")
    },
    novaprime(query) {
      // console.log("procura: " + query)
      // console.log("onde")
      // console.log("onde")
      // this.filtrados = this.options.filter(d => d.label === query)
      this.filtrados = this.options.filter(
        d => this.replaceSpecialChars(d.label.toLowerCase()).indexOf(this.replaceSpecialChars(query).toLowerCase()) > -1
      )
      this.iniciou = true
    }
  }
}
</script>

<style lang="postcss">
.c-select {
  @apply mb-2;

  &__label {
    @apply text-sm text-gray-800 font-medium block mb-1;
  }

  &__field {
    @apply text-gray-800 w-full text-sm;

    &-wrapper {
      @apply border-2 border-solid border-gray-300 rounded-lg text-gray-800 flex items-center relative bg-white;

      &.active {
        @apply rounded-b-none bg-gray-100;
      }
    }

    &-icon {
      @apply text-sm px-4 text-gray-500;
      width: 20px;
    }

    &.multiselect {
      box-sizing: border-box;

      &--active {
        .multiselect__select {
          transform: rotate(180deg);
        }
        .multiselect__content-wrapper {
          z-index: 50;
        }
        .multiselect__tags-wrap {
          @apply mb-2;
        }
      }
    }
    .multiselect {
      * {
        box-sizing: border-box;
      }

      &__tags {
        @apply pl-5 py-3 border-0 w-full;
        padding-right: 40px;
      }

      &__tags-wrap {
        @apply inline-block relative;
        /*  height: 18px; */
      }

      &__tag {
        @apply bg-gray-300 mr-2 mb-1 rounded inline-flex items-center  overflow-hidden;

        span {
          @apply inline-block pl-2 pr-1;
        }

        button {
          @apply h-full inline-block px-2 text-gray-500;
          transition: background 50ms linear;

          svg {
            @apply text-xs;
          }

          &:hover {
            @apply bg-gray-400;
          }

          &:focus {
            @apply bg-gray-400;
          }
        }
      }

      &__input,
      &__single {
        &:focus {
          box-shadow: none;
        }
      }

      &__content-wrapper {
        @apply border-gray-300 border-2 border-solid border-gray-300 absolute w-full rounded-b-lg;
        left: -2px;
        overflow-y: auto;
        box-sizing: content-box;
      }

      &__option {
        @apply py-4 px-5 bg-white text-gray-600;
        &--selected {
          @apply font-medium text-gray-800;
        }
        &--highlight {
          @apply bg-gray-100 text-gray-800;
        }
      }

      &__content {
        @apply w-full;

        li + li {
          @apply border-t-2 border-solid border-gray-300;
        }

        li {
          &:first-of-type {
            @apply border-t-0;
          }
          span {
            cursor: pointer;
            display: block;
          }
        }
      }

      &__placeholder {
        max-width: calc(100% - 40px);
      }

      &__select {
        position: absolute;
        width: 40px;
        height: 45px;
        right: 0;
        top: 0;
        text-align: center;
        transition: transform 0.2s ease;
        cursor: pointer;

        &:before {
          position: relative;
          right: 0;
          top: calc(50% + 1px);
          transform: translateY(-50%);
          color: #999;
          margin-top: 4px;
          border-style: solid;
          border-width: 5px 5px 0;
          border-color: #999 transparent transparent;
          content: "";
        }
      }
    }
  }

  &.xs > * {
    @apply text-xs;
  }

  @media screen and (max-width: 768px) {
    & > .multiselect, &__field {
      @apply text-xs;
    }
  }

  &.invalid {
    ^&__label {
      @apply text-red-500;
    }
    ^&__field-wrapper {
      @apply border-red-100;
    }
  }
}
</style>
